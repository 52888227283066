import React from 'react'
import { createContext, useState } from 'react'

interface ContextProps {
  readonly candidateDataList: any | null | undefined
  readonly addCandidateData: (candidate: any) => void
  readonly addNewCandidateData: (candidate: any) => void
}

export const HiringCandidateContext = createContext<ContextProps>({
  candidateDataList: null,
  addCandidateData: () => null,
  addNewCandidateData: () => null,
})

const CandidateListDataProvider = ({ children }: any) => {
  const [candidateList, setCandidateList] = useState<any[]>([])

  const addCandidateData = (data: any) => {
    setCandidateList(data)
  }

  const addNewCandidateData = (data: any) => {
    const uniqueTransactions = [...candidateList, data].reduce(
      (acc, transaction) => {
        const existingTransaction = acc.find(
          (t: any) => t.id === transaction.id
        )
        if (!existingTransaction) {
          acc.push(transaction)
        }

        return acc
      },
      []
    )
    setCandidateList(uniqueTransactions)
  }

  return (
    <HiringCandidateContext.Provider
      value={{
        candidateDataList: candidateList,
        addCandidateData: addCandidateData,
        addNewCandidateData: addNewCandidateData,
      }}
    >
      {children}
    </HiringCandidateContext.Provider>
  )
}

export default CandidateListDataProvider
