export enum HiringStage {
  Pending = 'Pending',
  Screening = 'Screening',
  CultureCatchUp = 'Culture_Catch_Up',
  Interview = 'Interview',
  ReferenceCheck = 'Reference_Check',
  Negotiation = 'Negotiation',
  PaperWork = 'Paper_Work',
  Completed = 'Completed',
  Candidates = 'Candidates',
}

export enum HiringPage {
  Screening = 'Screening',
  Candidates = 'Candidates',
  CultureCatchUp = 'Culture_Catch_Up',
  Interview = 'Interview',
  ReferenceCheck = 'Reference_Check',
  Negotiation = 'Negotiation',
  PaperWork = 'Paper_Work',
}
