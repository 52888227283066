import React, { useMemo } from 'react'

import styles from './../../shared/components/filterModal/styles.module.scss'
import { Form, Formik } from 'formik'
import CustomButton from '../../shared/components/customButton/customButton'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import CustomInputField from '../../shared/components/customInputField/customInputField'
import { InterviewQuestion } from '../../shared/interfaces/assessment.interface'
import * as Yup from 'yup'

interface AddNewQuestionModalProps {
  question: InterviewQuestion | null
  onSubmit: (question: InterviewQuestion) => void
  closeModal: () => void
}

interface AddNewQuestionFormValues {
  text: string
}

function AddNewQuestionInterview({
  question,
  onSubmit,
  closeModal,
}: AddNewQuestionModalProps) {
  const validationSchema = Yup.object().shape({
    text: Yup.string().required('Question text is required'),
  })

  // Set initial values for the form
  // Note that question = null when adding a new question
  const initialVal: AddNewQuestionFormValues = useMemo(() => {
    return {
      text: question?.text ?? '',
    }
  }, [question])

  /**
   * Handles the submission of the form
   * @param values The form values
   * @returns void
   */
  const handleSubmit = (values: AddNewQuestionFormValues): void => {
    onSubmit({
      ...values,
      id: question?.id ?? '',
    })
  }

  return (
    <Formik
      initialValues={initialVal}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue }) => (
        <Form>
          <div>
            <CustomInputField
              name={'text'}
              placeholder={'Question *'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('text', e.target.value)
              }
            />
          </div>
          <div
            className={`d-flex justify-content-between align-items-center ${styles.borderTop}`}
          >
            <CustomButton
              text={'Discard'}
              icon={<RecycleBinIcon />}
              iconSide={'left'}
              className={styles.discardBtn}
              onClick={closeModal}
            />
            <div
              className={'d-flex justify-content-between align-items-center'}
            >
              <CustomButton
                text={question === null ? 'Add Question' : 'Update Question'}
                className={`ms-3 ${styles.searchBtn}`}
                type={'submit'}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddNewQuestionInterview
