import React, { ReactElement } from 'react'
import styles from './fileUpload.module.scss'
import UploadIcon from '../../../icons/upload.icon'

type Props = {
  id: string
  filedText?: string
  fileSelect?: (e: File | null) => void
  loading?: boolean
  disabled?: boolean
  icon?: ReactElement
}

const FileUpload = ({ id, filedText, fileSelect, disabled }: Props) => {
  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    if (e.dataTransfer.items && e.dataTransfer.items[0]) {
      const item = e.dataTransfer.items[0]
      if (item.kind === 'file') {
        const file = item.getAsFile()
        if (file && file.type === 'application/pdf' && fileSelect) {
          fileSelect(file)
        }
      }
    }
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
  }

  return (
    <div
      className={styles.uploadFiled}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <label htmlFor={id} className={styles.textFiled}>
        <input
          type="file"
          id={id}
          style={{ display: 'none' }}
          accept=".pdf,.docx"
          disabled={disabled}
          onChange={(e) => {
            if (e.target.files !== null) {
              fileSelect?.(e.target.files[0])
              e.target.value = ''
            }
          }}
        />
        <h6 className={'light-text text-normal'}>{filedText}</h6>
        <div className={styles.uploadIcon}>
          <UploadIcon />
        </div>
      </label>
    </div>
  )
}

export default FileUpload
