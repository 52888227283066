import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import { Form, Formik } from 'formik'
import Tag from '../tag/tag'
import CustomButton from '../customButton/customButton'
import UploadIcon from '../../../icons/upload.icon'
import colors from '../../../scss/variables.module.scss'
import RightLongArrowIcon from '../../../icons/rightLongArrow.icon'
import LoadingService from '../../services/loading.service'
import {
  answerInterviewQuestion,
  getAnswersForQuestions,
  getInterviewQuestions,
} from '../../services/interview.service'
import {
  InterviewQuestionAnswers,
  InterviewQuestions,
} from '../../interfaces/interview.interface'
import { CandidateDetails } from '../../interfaces/candidate.interface'
import { HiringStage } from '../../enums/hiring.enum'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import loadingStyles from '../customLoadingSpinner/loadingSpinner.module.scss'
import errorStyle from '../../components/filterModal/styles.module.scss'
import * as Yup from 'yup'
import {
  AddToSupplyListType,
  HiringProcess,
  HiringProcessSearchRequest,
} from '../../interfaces/hiring.interface'
import {
  addHiringTeamMembers,
  getHiringTeamMembers,
  getTeamMembers,
} from '../../services/teams.service'
import { TeamMember } from '../../interfaces/teams.interface'
import CustomDropdown from '../customDropdown/customDropdown'
import {
  addToSupplyList,
  getHiringProcess,
  updateHiringProcess,
  updateHiringStepDate,
} from '../../services/hiring.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import SuccessBannerModal from '../errorBannerModal/successBannerModal'

interface InterviewProps {
  tab: string
  candidate: CandidateDetails | null
  hiringData: HiringProcess | null
  supplyStatus: boolean
  onStageChanged?: (updated: boolean) => void
  isClosed?: boolean
  setPage?: (page: HiringStage) => void
  onUpdateHiringData?: (hiringData: HiringProcess) => void
  setSupplyStatus?: (status: boolean) => void
}

type InterviewQuestionAnswerType = {
  id: string
  user_id: string
  hiring_process_id: string
  question_id: string
  question: string
  answer: string
  created_at: Date
  updated_at: Date
}

interface QuestionSchema {
  [key: string]: Yup.StringSchema
}

function Interview({
  candidate,
  hiringData,
  supplyStatus,
  onStageChanged,
  isClosed,
  setPage,
  onUpdateHiringData,
  setSupplyStatus,
}: InterviewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [interviewQuestions, setInterviewQuestions] = useState<
    InterviewQuestions[]
  >([])
  const [, setInterviewQuestionsAnswers] = useState<{ [key: string]: string }>(
    {}
  )
  const [answeredQuestions, setAnsweredQuestions] = useState<
    InterviewQuestionAnswerType[]
  >([])
  const [teamInterviewMembers, setTeamInterviewMembers] = useState<
    TeamMember[]
  >([])
  const [selectedAdminUser, setSelectedAdminUser] = useState<any[]>([])
  const [selectedManagerUser, setSelectedManagerUser] = useState<any[]>([])
  const [selectedInterViewUser, setSelectedInterViewUser] = useState<any[]>([])
  const [selectedCultureCatchupUser, setSelectedCultureCatchupUser] = useState<
    any[]
  >([])
  const [savedDate, setSavedDate] = useState<string>('')
  const [responseModal, setResponseModal] = useState<boolean>(false)
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [responseModalMessage, setResponseModalMessage] = useState<string>('')
  const [changeInterviewUsers, setChangeInterviewUsers] =
    useState<boolean>(true)
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])
  const [initialValInterView, setInitialValInterView] = useState<any>({
    interviewUser: [],
  })
  const loadHiringTeamData = useCallback(async (userList: any) => {
    try {
      setIsLoading(true)
      const data: any = await getHiringTeamMembers(
        hiringData ? hiringData.job_id : ''
      )
      if (data) {
        const userData = userList.filter(
          (fd: any) =>
            fd.cognito_id ===
            data?.filter((fd: any) => fd.teamtype === 'admin_users')[0].user_id
        )[0]
        setSelectedAdminUser([
          {
            name: userData.full_name,
            id: userData.cognito_id,
            imageUrl: userData.profile_image_key,
          },
        ])

        const userDataT = userList.filter(
          (fd: any) =>
            fd.cognito_id ===
            data?.filter((fd: any) => fd.teamtype === 'team_manager')[0].user_id
        )[0]
        setSelectedManagerUser([
          {
            name: userDataT.full_name,
            id: userDataT.cognito_id,
            imageUrl: userDataT.profile_image_key,
          },
        ])
        const interviewArray = data?.filter(
          (fd: any) => fd.teamtype === 'interview'
        )
        const cultureCatchupArray = data?.filter(
          (fd: any) => fd.teamtype === 'culture_catchup'
        )

        const iArray: any[] = []
        const cArray: any[] = []
        for (const userI of interviewArray) {
          const userDataI = userList.filter(
            (fd: any) => fd.cognito_id === userI.user_id
          )[0]
          iArray.push({
            name: userDataI.full_name,
            id: userDataI.cognito_id,
            imageUrl: userDataI.profile_image_key,
            isprimaryuser: userI.isPrimaryUser,
          })
        }
        setSelectedInterViewUser(iArray)

        for (const userC of cultureCatchupArray) {
          const userDataC = userList.filter(
            (fd: any) => fd.cognito_id === userC.user_id
          )[0]
          cArray.push({
            name: userDataC?.full_name,
            id: userDataC?.cognito_id,
            imageUrl: userDataC?.profile_image_key,
          })
        }
        setSelectedCultureCatchupUser(cArray)
        setInitialValInterView({
          interviewUser: interviewArray.map((md: any) => md.user_id),
        })
        setIsLoading(false)
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorModal(true)
      setResponseModalMessage(error.message)
    }
    // eslint-disable-next-line
  }, [])
  const loadMemberData = useCallback(async () => {
    try {
      setIsLoading(true)
      const data = await getTeamMembers()
      if (data) {
        setTeamInterviewMembers(data)

        setIsLoading(false)
        await loadHiringTeamData(data)
      }
    } catch (error: any) {
      setErrorModal(true)
      setResponseModalMessage(error.message)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    loadMemberData().then()
  }, [loadMemberData, loadingService])

  const addInterViewUser = (user: string) => {
    if (user) {
      const userData = teamInterviewMembers.filter(
        (fd) => fd.cognito_id === user
      )[0]

      if (
        selectedInterViewUser.length < 2 &&
        selectedInterViewUser.findIndex((fd) => fd.id === user) === -1
      ) {
        setSelectedInterViewUser([
          ...selectedInterViewUser,
          {
            name: userData.full_name,
            id: userData.cognito_id,
            imageUrl: userData.profile_image_key,
          },
        ])
      }
    }
  }

  const removeInterViewUser = (user: string) => {
    const newList = selectedInterViewUser.filter((fd) => fd.id !== user)
    setSelectedInterViewUser(newList)
  }

  useEffect(() => {
    setAnsweredQuestions([])
    if (hiringData && candidate) {
      const params = {
        hiringProcessId: hiringData.id,
        candidateId: candidate.id,
      }
      loadingService
        .await(getAnswersForQuestions(params).then(setAnsweredQuestions))
        .catch((error: any) => {
          setErrorModal(true)
          setResponseModalMessage(error.message)
        })
      const data: HiringProcessSearchRequest = {
        candidate_id: candidate?.id ?? '',
        job_id: hiringData?.job_id ?? '',
      }
      loadingService
        .await(getHiringProcess(data))
        .then((res) => {
          if (res) {
            setSavedDate(res.interview_date ?? '')
          }
        })
        .catch(() => {
          setErrorModal(true)
          setResponseModalMessage(
            'Something went wrong when getting the interview date'
          )
        })
    }
  }, [hiringData, loadingService, candidate])

  const initialVal = useMemo(() => {
    // Create initial values object using questions
    const initialValues: { [key: string]: any } = {}
    interviewQuestions.forEach((question) => {
      const answeredQuestion = answeredQuestions.find(
        (ans) => ans.question_id === question.id
      )
      initialValues[question.id] = answeredQuestion
        ? answeredQuestion.answer
        : ''
    })
    initialValues.savedDate = hiringData?.interview_date ?? ''
    initialValues.interviewUser = []
    return initialValues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewQuestions, answeredQuestions])

  const validationSchema = Yup.object().shape({
    ...interviewQuestions.reduce(
      (schema: QuestionSchema, interviewQuestions) => {
        schema[interviewQuestions.id] =
          Yup.string().required('Answer is required')
        return schema
      },
      {}
    ),
    savedDate: Yup.date().required('Date & time is required.'),
  })
  const handleSubmit = async (values: { [key: string]: string }) => {
    setIsLoading(true)
    if (!candidate || !hiringData) return
    const data: InterviewQuestionAnswers[] = interviewQuestions.map(
      (question) => ({
        answer_text: values[question.id],
        question_id: question.id,
        hiring_process_id: hiringData.id ?? '',
      })
    )
    if (data.length) {
      const res = await answerInterviewQuestion(data)
      if (res) {
        loadingService
          .await(
            updateHiringProcess(
              {
                candidate_id: candidate?.id ?? '',
                job_id: hiringData?.job_id ?? '',
              },
              {
                stage: HiringStage.ReferenceCheck,
                hiringProcessId: hiringData.id,
              }
            )
          )
          .then((res) => {
            if (!res) {
              setErrorModal(true)
              setResponseModalMessage('Update Error')
            }
            if (setPage) {
              setPage(HiringStage.ReferenceCheck)
            }
            if (onUpdateHiringData) {
              onUpdateHiringData({
                ...hiringData,
                stage: HiringStage.ReferenceCheck,
              })
            }
          })
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadingService
      .await(getInterviewQuestions())
      .then(setInterviewQuestions)
      .catch((error: any) => {
        setErrorModal(true)
        setResponseModalMessage(error.message)
      })
  }, [loadingService])

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    questionId: string
  ) => {
    const { value } = event.target
    setInterviewQuestionsAnswers((prevValues) => ({
      ...prevValues,
      [questionId]: value,
    }))
  }

  const handleSubmitInterViewUser = async () => {
    setChangeInterviewUsers(true)
    setIsLoading(true)
    const data = {
      job_id: hiringData ? hiringData.job_id : '',
      admin_users: selectedAdminUser.map((md) => ({
        user_id: md.id,
      })),
      culture_catchup: selectedCultureCatchupUser.map((md, index) => ({
        user_id: md.id,
        isprimaryuser: index == 0,
      })),
      interview: selectedInterViewUser.map((md, index) => ({
        user_id: md.id,
        isprimaryuser: index == 0,
      })),
      team_manager: selectedManagerUser.map((md) => ({
        user_id: md.id,
      })),
    }
    try {
      const result: any = await addHiringTeamMembers(data)
      if (result) {
        setIsLoading(false)
      } else {
        setIsLoading(false)
        setErrorModal(true)
        setResponseModalMessage('Error...')
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorModal(true)
      setResponseModalMessage(error.message)
      setIsLoading(false)
    }
  }

  const updateRequestedTime = async () => {
    setIsLoading(true)
    try {
      if (savedDate && hiringData) {
        const params = {
          hiringProcessId: hiringData.id,
          date: savedDate,
        }
        const res = await updateHiringStepDate(params)
        if (res) {
          setIsLoading(false)
          if (onStageChanged) {
            onStageChanged(true)
          }
          if (onUpdateHiringData) {
            onUpdateHiringData({
              ...hiringData,
              interview_date: savedDate,
            })
          }
        } else {
          setErrorModal(true)
          setResponseModalMessage('Something went wrong when updating the date')
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
        setErrorModal(true)
        setResponseModalMessage('something went wrong when updating the date')
      }
    } catch (error) {
      setErrorModal(true)
      setResponseModalMessage('Something went wrong when updating the date')
    }
  }

  const handleAddToSupply = async (
    values: { [key: string]: string },
    setFieldError: any
  ) => {
    if (values) {
      setIsLoading(true)
      if (!candidate || !hiringData) {
        setIsLoading(false)
        setErrorModal(true)
        setResponseModalMessage('Candidate or hiring data id is missing')
        return
      }

      const data: any[] = interviewQuestions
        .map((question) => {
          const answerText = values[question.id]
          if (answerText !== '') {
            return {
              answer_text: answerText,
              question_id: question.id,
              hiring_process_id: hiringData.id ?? '',
              user_id: candidate.id ?? '',
            }
          }
          return null
        })
        .filter((item) => item !== null)

      // Perform the API call or any other operation with the data
      if (data.length) {
        try {
          await answerInterviewQuestion(data)
          setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
          setErrorModal(true)
          setResponseModalMessage(
            'Something went wrong when answering the questions'
          )
        }
      }
    }
    setIsLoading(true)
    if (!hiringData || !hiringData.id) return
    const data: AddToSupplyListType = {
      hiringProcessId: hiringData.id ?? '',
      stage: hiringData?.stage,
    }
    if (data) {
      const res = await addToSupplyList(data)
      if (res) {
        setIsLoading(false)
        setResponseModal(true)
        setResponseModalMessage(
          'This candidate has been marked as unsuccessful.'
        )
        if (setSupplyStatus) {
          setSupplyStatus(true)
        }
        // Clear the validation error
        interviewQuestions.forEach((question) => {
          setFieldError(question.id, undefined)
        })
      } else {
        setIsLoading(false)
        setErrorModal(true)
        setResponseModalMessage(
          'Something went wrong when making the candidate unsuccessful.'
        )
      }
    } else {
      setIsLoading(false)
      setErrorModal(true)
      setResponseModalMessage(
        'Something went wrong when making the candidate unsuccessful.'
      )
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading} `}>
        <>
          <Formik
            initialValues={initialValInterView}
            onSubmit={handleSubmitInterViewUser}
          >
            {({ setFieldValue, validateForm, values }) => (
              <Form className={'d-flex '}>
                <div className={`mt-4 pb-3 ${styles.borderBottom}`}>
                  <h6>Interview Team</h6>
                  <div className={'d-flex mt-3 flex-wrap'}>
                    {selectedInterViewUser.map((item, index) => (
                      <Tag
                        showUserType={true}
                        primaryUser={index === 0 ? true : item.isprimaryuser}
                        name={item.name}
                        id={item.id}
                        imageUrl={item.imageUrl}
                        remove={(id) => {
                          if (!isClosed) {
                            setChangeInterviewUsers(false)
                            removeInterViewUser(id)
                            setFieldValue(
                              'interviewUser',
                              values.interviewUser.filter(
                                (fd: any) => fd !== id
                              )
                            )
                            validateForm()
                          }
                        }}
                      />
                    ))}
                    {selectedManagerUser.map((item) => (
                      <Tag
                        name={item.name}
                        id={item.id}
                        imageUrl={item.imageUrl}
                        remove={() => {}}
                      />
                    ))}
                  </div>
                  <>
                    <div className={'mt-4'}>
                      <h6 className={'text-normal'}>Add another</h6>
                      <div className={styles.dropdownWidth}>
                        <CustomDropdown
                          disabled={changeInterviewUsers || isClosed}
                          selectedValue={{
                            value: '',
                            text: '',
                          }}
                          dataList={teamInterviewMembers.map((data) => ({
                            value: data.cognito_id,
                            text: data.full_name,
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          }))}
                          placeHolder={'Name'}
                          getSelectedItem={(data: any) => {
                            addInterViewUser(data.value)
                            if (
                              data.value &&
                              values.interviewUser.findIndex(
                                (fd: any) => fd === data.value
                              ) === -1
                            ) {
                              setFieldValue('interviewUser', [
                                ...values.interviewUser,
                                data.value,
                              ])
                            }
                            validateForm()
                          }}
                        />
                      </div>
                    </div>
                    <div className={'mt-3'}>
                      <CustomButton
                        type={'submit'}
                        disabled={isClosed || changeInterviewUsers}
                        text={'Save member'}
                        iconSide={'left'}
                        className={styles.outlinedBtn}
                      />
                    </div>
                  </>
                </div>
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={initialVal}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({
              values,
              handleChange,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <Form>
                <div>
                  <div className={`mt-4 pb-3 ${styles.borderBottom}`}>
                    <h6>Date & Time</h6>
                    <div className={'d-flex mt-3'}>
                      <input
                        name="savedDate"
                        disabled={isClosed}
                        type={'datetime-local'}
                        className={styles.dateTime}
                        onChange={(e) => {
                          setSavedDate(e.target.value)
                          setFieldValue('savedDate', e.target.value)
                        }}
                        value={savedDate || ''}
                      />
                    </div>
                    {errors.savedDate && (
                      <div className={errorStyle.yupError}>
                        {errors.savedDate}
                      </div>
                    )}
                    <div className={'mt-3'}>
                      <CustomButton
                        disabled={isClosed}
                        text={'Save'}
                        className={styles.outlinedBtn}
                        onClick={() => updateRequestedTime()}
                      />
                    </div>
                  </div>
                  <div className={'mt-4 w-100'}>
                    <h6>Interview Questions & Feedback</h6>
                    {interviewQuestions.map((value, index) => (
                      <div className={'mt-5'} key={index}>
                        <h6 className={'gray-color-text mb-2'}>
                          <span className={'me-2'}>{index + 1}.</span>
                          {value.text}
                        </h6>
                        <textarea
                          disabled={isClosed || supplyStatus}
                          className={styles.textAreaCatchUp}
                          name={value.id}
                          value={values[value.id] || ''}
                          onChange={(e) => {
                            handleTextareaChange(e, value.id)
                            handleChange(e)
                          }}
                        />
                        <p className={errorStyle.yupError}>
                          {errors[value.id]}
                        </p>
                      </div>
                    ))}
                    <div
                      className={`justify-content-between align-items-center mt-5 ${styles.responsive}`}
                    >
                      <CustomButton
                        disabled={isClosed}
                        className={styles.supplyBtn}
                        text={'Unsuccessful'}
                        onClick={() => handleAddToSupply(values, setFieldError)}
                        icon={<UploadIcon color={colors.blackColor} />}
                        iconSide={'left'}
                      />
                      <CustomButton
                        disabled={supplyStatus || isClosed}
                        className={`mt-2 mt-md-0 ${styles.widthBtn}`}
                        type={'submit'}
                        text={'Move to Reference Check'}
                        icon={<RightLongArrowIcon />}
                        iconSide={'right'}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <SuccessBannerModal
            onClose={() => setResponseModal(false)}
            open={responseModal}
            Message={responseModalMessage}
          />
          <ErrorBannerModal
            onClose={() => setErrorModal(false)}
            open={errorModal}
            errorMessage={responseModalMessage}
          />
        </>
      </div>
    </>
  )
}

export default Interview
