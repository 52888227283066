import React from 'react'

interface Props {
  size?: number
  color?: string
}

const HamburgerIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 18}
      height={size ? size - 3 : 15}
      fill="none"
      viewBox={'0 0 18 15'}
    >
      <path
        d="M1 1.5h16m-16 6h16m-16 6h16"
        stroke={color ?? '#636363'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HamburgerIcon
