import React, { useCallback, useEffect, useMemo, useState } from 'react'

import styles from './styles.module.scss'
import { Form, Formik, FormikValues } from 'formik'
import colors from '../../../scss/variables.module.scss'
import OfferCard from './offerCard'
import CustomButton from '../customButton/customButton'
import UploadIcon from '../../../icons/upload.icon'
import RightLongArrowIcon from '../../../icons/rightLongArrow.icon'
import {
  HiringNegotiationHistoryType,
  HiringProcess,
  NegotiationResType,
} from '../../interfaces/hiring.interface'
import { HiringStage } from '../../enums/hiring.enum'
import { CandidateDetails } from '../../interfaces/candidate.interface'
import LoadingService from '../../services/loading.service'
import {
  addNegotiationData,
  getNegotiationData,
  getNegotiationHistory,
  updateHiringProcess,
} from '../../services/hiring.service'
import loadingStyles from '../customLoadingSpinner/loadingSpinner.module.scss'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import CalenderIcon from '../../../icons/calender.icon'
import ClockIcon from '../../../icons/clock.icon'
import dayjs from 'dayjs'
import { getUserRole } from '../../services/auth.service'
import { UserRole } from '../../enums/auth.enum'

interface NegotiationProps {
  tab?: string
  candidate?: CandidateDetails | null
  hiringData?: HiringProcess | null
  changePage?: (page: string) => void
  onStageChanged?: (updated: boolean) => void
  onUpdateHiringData?: (hiringData: HiringProcess) => void
  changedProcessPage?: (page: HiringStage) => void
  setPage?: (page: HiringStage) => void
  handleAddToSupply: () => void
  supplyStatus: boolean
  isClosed?: boolean
  disableTabsAndButtons: boolean
}

export type NegotiationDataType = {
  candidateOfferValue: number
  candidateWorkType: string
  candidateOnsiteDays: number
  organisationOfferValue: number
  organisationWorkType: string
  organisationOnsiteDays: number
  negotiatedOfferValue: number
  negotiatedWorkType: string
  negotiatedOnsiteDays: number
  note?: string
}

export enum HiringNegotiationStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Negotiated = 'negotiated',
  Not_Started = 'not_started',
}

function Negotiation({
  supplyStatus,
  handleAddToSupply,
  hiringData,
  isClosed,
  setPage,
  candidate,
  onUpdateHiringData,
  disableTabsAndButtons,
}: NegotiationProps) {
  const [initialVal, setInitialValue] = useState<NegotiationDataType>({
    candidateOfferValue: 0,
    candidateWorkType: '',
    candidateOnsiteDays: 0,
    organisationOfferValue: 0,
    organisationWorkType: '',
    organisationOnsiteDays: 0,
    negotiatedOfferValue: 0,
    negotiatedWorkType: '',
    negotiatedOnsiteDays: 0,
    note: '',
  })
  const [showTab, setShowTab] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState(false)
  const [negotiationData, setNegotiationData] = useState<NegotiationResType>()
  const [historyData, setHistoryData] = useState<HiringNegotiationHistoryType>()
  const [reload, setReload] = useState(false)
  const [buttonText, setButtonText] = useState('Save')
  const [isError, setIsError] = useState(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const loadNegotiationData = useCallback(() => {
    setIsLoading(true)
    if (hiringData) {
      loadingService
        .await(
          getNegotiationData(hiringData.id).then((res) => {
            if (res) {
              setNegotiationData(res)
              setInitialValue({
                candidateOfferValue: res.candidate_pay_offer,
                candidateWorkType: res.candidate_work_type,
                candidateOnsiteDays: res.candidate_onsite_days,
                organisationOfferValue: res.organization_offer,
                organisationWorkType: res.organization_offer_work_type,
                organisationOnsiteDays: res.organization_onsite_days,
                negotiatedOfferValue: res.negotiation_pay_offer ?? 0,
                negotiatedWorkType: res.negotiation_offer_work_type,
                negotiatedOnsiteDays: res.negotiation_onsite_days ?? 0,
                note: res.notes,
              })
              if (res.negotiation_pay_offer) setButtonText('Update')
              setReload(false)
              setIsLoading(false)
            } else {
              setNegotiationData(undefined)
              setIsLoading(false)
              setReload(false)
            }
          })
        )
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    }
  }, [hiringData, loadingService])

  const historyDataFetch = useCallback(async () => {
    try {
      setIsLoading(true)
      if (hiringData) {
        const res = await getNegotiationHistory({
          hiring_process_id: hiringData.id,
        })
        if (res) {
          setIsLoading(false)
          setHistoryData(res)
          setReload(false)
        } else {
          setIsLoading(false)
          setHistoryData(undefined)
          setReload(false)
        }
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }, [hiringData])

  useEffect(() => {
    historyDataFetch().then((r) => r)
  }, [historyDataFetch, reload])

  useEffect(() => {
    loadNegotiationData()
  }, [loadNegotiationData, reload])

  const handleSubmit = (values: FormikValues) => {
    const excludedNegField = 'negotiatedOnsiteDays'
    const excludedOrgField = 'organisationOnsiteDays'
    const excludedCandidateField = 'candidateOnsiteDays'
    setIsLoading(true)
    if (
      Object.keys(values)
        .filter(
          (key) =>
            key !== excludedNegField &&
            key !== excludedOrgField &&
            key !== excludedCandidateField
        )
        .some((key) => values[key] === null || values[key] === '')
    ) {
      setIsError(true)
      setIsLoading(false)
      return
    }
    if (hiringData) {
      const data: any = {
        candidate_offer_type:
          negotiationData?.candidate_offer_type ?? 'per hour',
        hiring_process_id: hiringData.id,
        negotiation_salary:
          parseInt(values.negotiatedOfferValue) ??
          negotiationData?.negotiation_pay_offer ??
          0,
        negotiation_work_type: values.negotiatedWorkType,
        candidate_offer_work_type: values.candidateWorkType,
        organization_offer_type:
          negotiationData?.candidate_offer_type ?? 'per hour',
        organization_offer_work_type: values.organisationWorkType,
        candidate_onsite_days: parseInt(values.candidateOnsiteDays),
        organization_onsite_days: parseInt(values.organisationOnsiteDays),
        negotiation_onsite_days: parseInt(values.negotiatedOnsiteDays) ?? 0,
        organization_offer: parseInt(values.organisationOfferValue),
        candidate_offer: parseInt(values.candidateOfferValue),
        status: values.status || HiringNegotiationStatus.Negotiated,
        notes: values.note ?? '',
      }
      loadingService
        .await(addNegotiationData(data))
        .then((res) => {
          if (res) {
            setIsLoading(false)
            setReload(true)
          } else {
            setIsLoading(false)
          }
          setReload(true)
        })
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    }
  }

  const handleSubmitPage = () => {
    setIsLoading(true)
    if (hiringData) {
      loadingService.await(
        updateHiringProcess(
          {
            candidate_id: candidate?.id ?? '',
            job_id: hiringData?.job_id ?? '',
          },
          { stage: HiringStage.PaperWork, hiringProcessId: hiringData.id }
        )
          .then((res) => {
            if (!res) {
              setErrorModel(true)
              setErrorMessage('Update Error')
            }
            if (onUpdateHiringData) {
              onUpdateHiringData({
                ...hiringData,
                stage: HiringStage.PaperWork,
              })
            }
            if (setPage) {
              setPage(HiringStage.PaperWork)
            }
          })
          .catch((error) => {
            setErrorModel(true)
            setErrorMessage(error.message)
          })
      )
      setIsLoading(false)
      setReload(true)
    }
  }

  useEffect(() => {
    getUserRole()
      .then((role) => {
        if (role !== UserRole.StaffAdmin) {
          setShowTab(true)
        } else {
          setShowTab(false)
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {!showTab ? (
          <></>
        ) : (
          <>
            <Formik
              enableReinitialize
              initialValues={initialVal}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form className={'d-flex '}>
                  <div className={'d-flex w-100 justify-content-between'}>
                    <div className={'mt-5 w-100'}>
                      <h6>Negotiation</h6>
                      <div
                        className={
                          'mt-4 d-lg-flex justify-content-between align-items-center'
                        }
                      >
                        <OfferCard
                          cardType="candidate"
                          negotiationData={negotiationData}
                          updateFieldValue={setFieldValue}
                        />
                        <div className={'ms-0 ms-lg-1 me-0 me-lg-1'}>
                          <OfferCard
                            cardType="organisation"
                            negotiationData={negotiationData}
                            updateFieldValue={setFieldValue}
                          />
                        </div>
                        <OfferCard
                          cardType="negotiated"
                          negotiationData={negotiationData}
                          updateFieldValue={setFieldValue}
                        />
                      </div>

                      <div className="d-flex mt-5 justify-content-center">
                        <CustomButton
                          type="submit"
                          className={styles.widthBtn}
                          text={`${buttonText} Negotiation`}
                          disabled={supplyStatus || isClosed}
                        />
                      </div>
                      <div className={'mt-3'}>
                        <h6>Negotiation History</h6>
                        {historyData?.map((value, index) => (
                          <div
                            key={`negotiation-history-${index}`}
                            className={`mt-3 d-flex justify-content-between align-items-center mb-2 ${styles.borderBottom}`}
                          >
                            <div>
                              <h6 className={'gray-color-text mb-2'}>
                                {value.userName}
                              </h6>
                            </div>
                            <div className={'d-flex align-items-center'}>
                              <div className={'d-flex align-items-center mb-2'}>
                                <CalenderIcon />
                                <p className={'ms-2 gray-color-text'}>
                                  {dayjs(value.userDateTime).format(
                                    'D MMMM YYYY'
                                  )}
                                </p>
                              </div>
                              <div
                                className={
                                  'd-flex align-items-center ms-5 mb-2'
                                }
                              >
                                <ClockIcon />
                                <p className={'ms-2 gray-color-text'}>
                                  {dayjs(value.userDateTime).format('h.mm A')}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div
              className={
                'd-md-flex justify-content-between align-items-center mt-5'
              }
            >
              <CustomButton
                disabled={isClosed}
                className={styles.supplyBtn}
                text={'Unsuccessful'}
                onClick={() => handleAddToSupply()}
                icon={<UploadIcon color={colors.blackColor} />}
                iconSide={'left'}
              />

              <CustomButton
                type={'button'}
                onClick={() => {
                  handleSubmitPage()
                }}
                className={`mt-2 mt-md-0 ${styles.widthBtn}`}
                text={'Move to Documentation'}
                icon={<RightLongArrowIcon />}
                iconSide={'right'}
                disabled={supplyStatus || isClosed || disableTabsAndButtons}
              />
            </div>
          </>
        )}
      </div>
      {isError && (
        <ErrorBannerModal
          open={isError}
          onClose={() => setIsError(false)}
          errorMessage={'Negotiated values cannot be empty.'}
        />
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default Negotiation
