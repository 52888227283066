import React, { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import styles from './candidateFilter.module.scss'
import { UserWithCandidateInfo } from './types'
import { SearchIcon } from './SearchIcon'

interface Props {
  handleSelectCandidate: (candidate: UserWithCandidateInfo) => void
  searchUsers: (term: string) => Promise<UserWithCandidateInfo[]>
}

export const CandidateFilter: React.FC<Props> = ({
  handleSelectCandidate,
  searchUsers,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [users, setUsers] = useState<UserWithCandidateInfo[]>([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const debouncedSearch = debounce(async (term: string) => {
      if (term.length > 2) {
        setIsLoading(true)
        try {
          const results = await searchUsers(term)
          setUsers(results)
          setShowDropdown(true)
        } catch (error) {
          console.error('Error searching users:', error)
          setUsers([])
        } finally {
          setIsLoading(false)
        }
      } else {
        setUsers([])
        setShowDropdown(false)
      }
    }, 300)

    debouncedSearch(searchTerm)

    return () => {
      debouncedSearch.cancel()
    }
  }, [searchTerm, searchUsers])

  const handleClear = () => {
    setSearchTerm('')
    setUsers([])
    setShowDropdown(false)
  }

  return (
    <div className={styles.candidateFilter}>
      <div className={styles.searchInputWrapper}>
        <input
          type="text"
          placeholder="Search candidates..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
        <SearchIcon
          isLoading={isLoading}
          searchTerm={searchTerm}
          handleClear={handleClear}
        />
      </div>
      {showDropdown && (
        <ul className={styles.dropdownList}>
          {users.length === 0 ? (
            <li className={styles.dropdownItem}>No candidates found.</li>
          ) : (
            users.map((user) => (
              <li
                key={user.id}
                className={styles.dropdownItem}
                onClick={() => {
                  handleSelectCandidate(user)
                  setShowDropdown(false)
                  setSearchTerm('')
                }}
              >
                <div className={styles.dropdownItemName}>{user.name}</div>
                <div className={styles.dropdownItemDetails}>
                  {user.mobile_number}
                </div>
                <div className={styles.dropdownItemDetails}>{user.email}</div>
                <div className={styles.dropdownItemDetails}>
                  {user.location}
                </div>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  )
}
