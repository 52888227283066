import { useEffect, useRef } from 'react'

export const useClickOutside = (handlerFun: () => void) => {
  const domNode = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const element = domNode.current
      if (element && !element.contains(event.target as Node)) {
        handlerFun()
      }
    }

    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  return domNode
}
