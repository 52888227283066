import React, { useEffect, useState } from 'react'

import styles from './styles.module.scss'
import CustomDropdown, {
  DropdownDataList,
} from '../customDropdown/customDropdown'
import { Form, Formik } from 'formik'
import CustomButton from '../customButton/customButton'
import { JobStatus, WorkCommitment, WorkType } from '../../enums/job.enum'
import { getTeamMembers } from '../../services/teams.service'
import { getJobLocationDetails } from '../../services/job.service'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import RecycleBinIcon from '../../../icons/recycleBinIcon'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type JobFilters = {
  status?: JobStatus
  location?: string
  commitment?: WorkCommitment
  work_type?: WorkType
  team?: any
  team_member?: any
  id?: string
}

export type FilterModalProps = {
  closeModal: () => void
  onSubmit: (data: JobFilters) => void
  initialVal: JobFilters
}

const statusOptions: DropdownDataList[] = [
  { value: JobStatus.IncompleteDetails, text: 'Incomplete Details' },
  { value: JobStatus.NoCostGenerated, text: 'No Cost Generated' },
  { value: JobStatus.PendingApproval, text: 'Pending Approval' },
  { value: JobStatus.Open, text: 'Live' },
  { value: JobStatus.Closed, text: 'Closed' },
]

export const bbbTeamList = [
  { value: 'a811b0f4-6936-4c7a-a8b9-d1c7e892e2bc', text: 'B. Effective' },
  { value: 'e9d34b6e-82f7-4d9f-a830-9d448d12674f', text: 'B. Reliable' },
  { value: '7f8e2b43-1e99-4a93-8b5d-6d39b1dfe2fa', text: 'B. Supportive' },
]

function FilterModal({ closeModal, onSubmit, initialVal }: FilterModalProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [jobLocations, setJobLocation] = useState<any[]>([])
  const [teamMembers, setTeamMembers] = useState<any[]>([])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const handleSubmit = (values: any) => {
    closeModal()
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key]
      }
    })
    onSubmit(values)
  }

  const fetchLocations = async () => {
    try {
      setLoading(true)
      const response = await getJobLocationDetails()
      setJobLocation(response)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const fetchUsers = async () => {
    try {
      setLoading(true)
      const response = await getTeamMembers()
      setTeamMembers(
        response.map((md: any) => ({
          text: md.full_name,
          value: md.cognito_id,
        }))
      )
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const clearForm = (setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('status', undefined)
    setFieldValue('location', undefined)
    setFieldValue('commitment', undefined)
    setFieldValue('work_type', undefined)
    setFieldValue('team_member', undefined)
    setFieldValue('team', undefined)
  }

  useEffect(() => {
    fetchLocations()
    fetchUsers()
  }, [])

  return (
    <>
      <Formik
        initialValues={initialVal}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values)
          resetForm()
        }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form className={`align-items-center ${styles.responsive}`}>
            {loading && <LoadingSpinner />}

            <div style={{ opacity: loading ? 0.5 : 1 }} className={'w-100'}>
              <div
                className={'d-flex justify-content-between align-items-center'}
              >
                <div
                  className={styles.dropdownWidthFilter}
                  style={{ zIndex: 3 }}
                >
                  <CustomDropdown
                    placeHolder={'Status'}
                    dataList={statusOptions.map((item) => ({
                      text: item.text,
                      value: item.value,
                    }))}
                    getSelectedItem={(item: any) =>
                      setFieldValue('status', item.value)
                    }
                    selectedValue={statusOptions.find(
                      (item) => item.value === values.status
                    )}
                  />
                </div>
                <div
                  className={`ms-3 ${styles.dropdownWidthFilter}`}
                  style={{ zIndex: 3 }}
                >
                  <CustomDropdown
                    name={'location'}
                    placeHolder={'Locations'}
                    dataList={jobLocations.map((item) => ({
                      text: item.text ?? '',
                      value: item.value ?? '',
                    }))}
                    getSelectedItem={(item: any) =>
                      setFieldValue('location', item.value)
                    }
                    selectedValue={jobLocations.find(
                      (item) => item.value === values.location
                    )}
                  />
                </div>
              </div>
              <div
                className={`d-flex align-items-center mt-4 mb-4 ${styles.borderTop}`}
                style={{ position: 'relative' }}
              >
                <div className="form-check w-50">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="commitment"
                    id="commitment1"
                    value={WorkCommitment.PermanentFullTime}
                    onChange={(e) =>
                      setFieldValue('commitment', e.target.value)
                    }
                    checked={
                      values.commitment === WorkCommitment.PermanentFullTime
                    }
                  />
                  <label className="form-check-label" htmlFor="commitment1">
                    <h6 className={'gray-color-text text-normal'}>Full time</h6>
                  </label>
                </div>
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="commitment"
                    id="commitment2"
                    value={WorkCommitment.PermanentPartTime}
                    onChange={(e) =>
                      setFieldValue('commitment', e.target.value)
                    }
                    checked={
                      values.commitment === WorkCommitment.PermanentPartTime
                    }
                  />
                  <label className="form-check-label" htmlFor="commitment2">
                    <h6 className={'gray-color-text text-normal'}>Part time</h6>
                  </label>
                </div>
              </div>
              <div
                className={`d-flex justify-content-between mb-4 ${styles.borderTop}`}
                style={{ position: 'relative' }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="work_type"
                    id="work_type1"
                    value={WorkType.Hybrid}
                    onChange={(e) => setFieldValue('work_type', e.target.value)}
                    checked={values.work_type === WorkType.Hybrid}
                  />
                  <label className="form-check-label" htmlFor="work_type1">
                    <h6 className={'text-normal gray-color-text'}>Hybrid</h6>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="work_type"
                    id="work_type2"
                    value={WorkType.OnSite}
                    onChange={(e) => setFieldValue('work_type', e.target.value)}
                    checked={values.work_type === WorkType.OnSite}
                  />
                  <label className="form-check-label" htmlFor="work_type2">
                    <h6 className={'text-normal gray-color-text'}>Onsite</h6>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="work_type"
                    id="work_type3"
                    value={WorkType.OffSite}
                    onChange={(e) => setFieldValue('work_type', e.target.value)}
                    checked={values.work_type === WorkType.OffSite}
                  />
                  <label className="form-check-label" htmlFor="work_type3">
                    <h6 className={'text-normal gray-color-text'}>Off-Site</h6>
                  </label>
                </div>
              </div>
              <div
                className={`d-flex justify-content-between align-items-center mb-4 ${styles.borderTop}`}
              >
                <div
                  className={styles.dropdownWidthFilter}
                  style={{ zIndex: 2 }}
                >
                  <CustomDropdown
                    placeHolder={'Users'}
                    dataList={teamMembers}
                    getSelectedItem={(item: any) =>
                      setFieldValue('team_member', item.value)
                    }
                    selectedValue={teamMembers.find(
                      (item) => item.value === values.team_member
                    )}
                  />
                </div>
                <div
                  className={`ms-3 ${styles.dropdownWidthFilter}`}
                  style={{ zIndex: 2 }}
                >
                  <CustomDropdown
                    placeHolder={'BBB Team'}
                    selectedValue={bbbTeamList.find(
                      (item) => item.value === values.team
                    )}
                    dataList={bbbTeamList}
                    getSelectedItem={(item: any) =>
                      setFieldValue('team', item.value)
                    }
                  />
                </div>
              </div>
              <div
                className={`d-flex justify-content-between align-items-center ${styles.borderTop}`}
              >
                <CustomButton
                  onClick={() => {
                    resetForm()
                    closeModal()
                  }}
                  text={'Discard'}
                  icon={<RecycleBinIcon />}
                  className={styles.discardBtn}
                />
                <div className={'d-flex align-items-center'}>
                  <CustomButton
                    onClick={() => clearForm(setFieldValue)}
                    text={'Clear'}
                    className={styles.cancelBtn}
                  />
                  <CustomButton
                    type={'submit'}
                    text={'Search'}
                    className={`ms-2 ${styles.searchBtn}`}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default FilterModal
