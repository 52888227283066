export interface CandidateOverview {
  id: string
  name: string
  location: string
  match_percentage: number
  profile_url?: string
}

interface CandidateHiringHistory {
  job_title: string
  hiring_stage_reached: string
}

interface CandidateAssessmentCategoryResults {
  category: string
  last_score: number
  max_score: number
}

interface CandidateAssessmentResults {
  last_score: number
  max_score: number
  time: number
  start_time: string
  end_time: string
  category_results: Array<CandidateAssessmentCategoryResults>
}

interface CandidateSummary {
  id: string
  overview: string
  overview_last_updated_at: string
  overview_last_updated_by: string
  key_skills_summary: string
  key_skills_summary_last_updated_at: string
  key_skills_summary_last_updated_by: string
}

interface CandidateQualification {
  id: string
  qualification: string
  institution: string
  field_of_study: string
  start_date: string
  end_date: string
}

interface CandidateCertification {
  id: string
  certification: string
  organization: string
  issue_date: string
  reference_id: string
}

interface CandidateWorkExperience {
  id: string
  role: string
  years_of_experience: number
}

export interface CandidateDetails {
  id: string
  name: string
  location: string
  notice_period_days: number
  join_date: string
  history: Array<CandidateHiringHistory>
  assessment_results: CandidateAssessmentResults
  summary: CandidateSummary
  qualifications: Array<CandidateQualification>
  certifications: Array<CandidateCertification>
  task_expertise: Array<string>
  system_expertise: Array<string>
  industry_expertise: Array<string>
  work_experience: Array<CandidateWorkExperience>
  hiring_process_id?: string
  resume_key: string
  cover_letter_key: string
  profile_url: string
  hiredJob?: any
  status?: string | null
  email?: string
  phone?: string
}

export interface CultureCatchUpType {
  id: string
  text: string
}

export interface CultureCatchUpAnswerType {
  question_id: string
  hiring_process_id: string
  answer_text: string
  user_id: string
}

export enum CandidateStatus {
  Active = 'active',
  Inactive = 'inactive',
}
