import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import RightLongArrowIcon from '../../../icons/rightLongArrow.icon'
import colors from '../../../scss/variables.module.scss'
import CounterOfferIcon from '../../../icons/counterOffer.icon'
import ThumbsUpIcon from '../../../icons/thumsUp.icon'
import CustomInputField from '../customInputField/customInputField'
import { NegotiationResType } from '../../interfaces/hiring.interface'
import { Form, Formik } from 'formik'
import DollarIcon from '../../../icons/dollar.icon'
import * as Yup from 'yup'

type OfferCardProps = {
  topic?: string
  rate?: number
  onSite?: number
  offSite?: number
  cardType?: 'candidate' | 'organisation' | 'negotiated'
  offerValue?: number
  negotiationData?: NegotiationResType
  updateFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void // Updated to void
}

type OfferDataType = {
  offerValue: number
  workType: string
  onsiteDays: number
}

function OfferCard({
  cardType,
  negotiationData,
  updateFieldValue,
}: OfferCardProps) {
  const [hybrid, setHybrid] = useState(false)
  const [offerType, setOfferType] = useState('per hour')
  const [initialVal, setInitialValue] = useState<OfferDataType>({
    offerValue: 0,
    workType: '',
    onsiteDays: 0,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Formik validation schema
   * @type {ObjectSchema<Shape<object | undefined, OfferDataType>>}
   */
  const validationSchema = Yup.object().shape({
    offerValue: Yup.number().required('Offer value is required'),
    workType: Yup.string().required('Work type is required'),
    onsiteDays: Yup.number().when('workType', {
      is: 'Hybrid',
      then: Yup.number().required(
        'Onsite days is required for Hybrid work type'
      ),
    }),
  })

  useEffect(() => {
    setIsLoading(true)
    if (negotiationData) {
      if (cardType === 'candidate') {
        setInitialValue({
          offerValue: negotiationData.candidate_pay_offer,
          workType: negotiationData.candidate_work_type,
          onsiteDays: negotiationData.candidate_onsite_days,
        })
        updateFieldValue(
          'candidateOfferValue',
          negotiationData.candidate_pay_offer
        )
        updateFieldValue(
          'candidateWorkType',
          negotiationData.candidate_work_type
        )
        updateFieldValue(
          'candidateOnsiteDays',
          negotiationData.candidate_onsite_days
        )
        setIsLoading(false)
      } else if (cardType === 'organisation') {
        setInitialValue({
          offerValue: negotiationData.organization_offer,
          workType: negotiationData.organization_offer_work_type,
          onsiteDays: negotiationData.organization_onsite_days,
        })
        updateFieldValue(
          'organisationOfferValue',
          negotiationData.organization_offer
        )
        updateFieldValue(
          'organisationWorkType',
          negotiationData.organization_offer_work_type
        )
        updateFieldValue(
          'organisationOnsiteDays',
          negotiationData.organization_onsite_days
        )
        setIsLoading(false)
      } else if (cardType === 'negotiated') {
        setInitialValue({
          offerValue: negotiationData.negotiation_pay_offer ?? 0,
          workType: negotiationData.negotiation_offer_work_type,
          onsiteDays: negotiationData.negotiation_onsite_days ?? 0,
        })
        updateFieldValue(
          'negotiatedOfferValue',
          negotiationData.negotiation_pay_offer
        )
        updateFieldValue(
          'negotiatedWorkType',
          negotiationData.negotiation_offer_work_type
        )
        updateFieldValue(
          'negotiatedOnsiteDays',
          negotiationData.negotiation_onsite_days
        )
        setIsLoading(false)
      }
      setOfferType(negotiationData.candidate_offer_type)
    }
  }, [cardType, negotiationData, updateFieldValue])

  /**
   * Check if card type is candidate or organisation or negotiated
   */
  useEffect(() => {
    if (
      (cardType === 'candidate' || 'organisation' || 'negotiated') &&
      initialVal.workType === 'Hybrid'
    ) {
      setHybrid(true)
    }
  }, [cardType, initialVal.workType])

  return (
    <Formik
      initialValues={initialVal}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => (
        <Form className={'mt-3 mt-lg-0'}>
          <div
            className={
              cardType === 'organisation'
                ? styles.orangeCard
                : cardType === 'negotiated'
                  ? styles.greenCard
                  : styles.card
            }
          >
            <div className={'d-flex align-items-center'}>
              {cardType === 'organisation' ? (
                <>
                  <CounterOfferIcon />
                  <h6 className={'text-orange ms-3'}>Organisation Offer</h6>
                </>
              ) : cardType === 'negotiated' ? (
                <>
                  <ThumbsUpIcon />
                  <h6 className={'text-success-color ms-3'}>
                    Negotiated Offer
                  </h6>
                </>
              ) : (
                <>
                  <RightLongArrowIcon color={colors.grayColor} />
                  <h6 className={'gray-color-text ms-3'}>Candidate Offer</h6>
                </>
              )}
            </div>
            <div
              className={`mt-2 ${
                cardType === 'negotiated'
                  ? styles.negotiateRate
                  : cardType === 'organisation'
                    ? styles.counterOfferRate
                    : styles.rate
              }`}
            >
              <div
                className={'d-flex justify-content-center align-items-center'}
              >
                <CustomInputField
                  name={'offerValue'}
                  type={'number'}
                  icon={<DollarIcon />}
                  key={`${cardType}-offerValue`}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('offerValue', event.target.value)
                    cardType === 'candidate'
                      ? updateFieldValue(
                          'candidateOfferValue',
                          event.target.value
                        )
                      : cardType === 'organisation'
                        ? updateFieldValue(
                            'organisationOfferValue',
                            event.target.value
                          )
                        : updateFieldValue(
                            'negotiatedOfferValue',
                            event.target.value
                          )
                  }}
                  value={values.offerValue}
                />
                <span className={'w-50 text-normal ms-2 font-size-large'}>
                  {offerType}
                </span>
              </div>
            </div>
            <div
              className={
                'd-flex  align-items-center mt-3 justify-content-between'
              }
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="work_type"
                  id="work_type1"
                  value="Hybrid"
                  checked={values.workType === 'Hybrid'}
                  onChange={(e) => {
                    setFieldValue('workType', e.target.value)
                    setHybrid(true)
                    cardType === 'candidate'
                      ? updateFieldValue('candidateWorkType', e.target.value)
                      : cardType === 'organisation'
                        ? updateFieldValue(
                            'organisationWorkType',
                            e.target.value
                          )
                        : updateFieldValue('negotiatedWorkType', e.target.value)
                  }}
                  onClick={() => setHybrid(true)}
                />
                <label className="form-check-label">
                  <h6 className={'text-normal gray-color-text'}>Hybrid</h6>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="work_type"
                  id="work_type2"
                  value="On-Site"
                  checked={values.workType === 'On-Site'}
                  onChange={(e) => {
                    setFieldValue('workType', e.target.value)
                    setHybrid(false)
                    cardType === 'candidate'
                      ? updateFieldValue('candidateWorkType', e.target.value)
                      : cardType === 'organisation'
                        ? updateFieldValue(
                            'organisationWorkType',
                            e.target.value
                          )
                        : updateFieldValue('negotiatedWorkType', e.target.value)
                  }}
                />
                <label className="form-check-label">
                  <h6 className={'text-normal gray-color-text'}>Onsite</h6>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="work_type"
                  id="work_type3"
                  value="Off-Site"
                  checked={values.workType === 'Off-Site'}
                  onChange={(e) => {
                    setFieldValue('workType', e.target.value)
                    setHybrid(false)
                    cardType === 'candidate'
                      ? updateFieldValue('candidateWorkType', e.target.value)
                      : cardType === 'organisation'
                        ? updateFieldValue(
                            'organisationWorkType',
                            e.target.value
                          )
                        : updateFieldValue('negotiatedWorkType', e.target.value)
                  }}
                />
                <label className="form-check-label">
                  <h6 className={'text-normal gray-color-text'}>Offsite</h6>
                </label>
              </div>
            </div>
            {hybrid && (
              <div className={'d-flex align-items-center mt-2'}>
                <CustomInputField
                  name={'onsiteDays'}
                  type={'number'}
                  key={`${cardType}-onsiteDays`}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('onsiteDays', event.target.value)
                    cardType === 'candidate'
                      ? updateFieldValue(
                          'candidateOnsiteDays',
                          event.target.value
                        )
                      : cardType === 'organisation'
                        ? updateFieldValue(
                            'organisationOnsiteDays',
                            event.target.value
                          )
                        : updateFieldValue(
                            'negotiatedOnsiteDays',
                            event.target.value
                          )
                  }}
                  value={values.onsiteDays}
                />
                <span className={'w-50 text-normal ms-2 font-size-large'}>
                  Days
                </span>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OfferCard
