import React, { useEffect, useMemo, useState } from 'react'

import styles from './styles.module.scss'
import { Form, Formik } from 'formik'
import CustomButton from '../customButton/customButton'
import UploadIcon from '../../../icons/upload.icon'
import colors from '../../../scss/variables.module.scss'
import RightLongArrowIcon from '../../../icons/rightLongArrow.icon'
import CustomModal from '../customModal/customModal'
import ReviewModal from './reviewModal'
import LoadingService from '../../services/loading.service'
import { getRefereeComments } from '../../services/job.service'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import { HiringStage } from '../../enums/hiring.enum'
import { updateHiringProcess } from '../../services/hiring.service'
import { HiringProcess } from '../../interfaces/hiring.interface'
import { getMasterData } from '../../services/masterData.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type Responses = {
  response1: string
  response2: string
  response3: string
  response4: string
  additionalComments: string
}

interface CandidateProps {
  hiringData: HiringProcess
  supplyStatus: boolean
  candidate: any
  handleAddToSupply: () => void
  isClosed?: boolean
  setPage?: (page: HiringStage) => void
  jobId: string
  onStageChanged?: (updated: boolean) => void
  onUpdateHiringData?: (hiringData: HiringProcess) => void
  disableTabsAndButtons: boolean
}

function ReferenceCheck({
  supplyStatus,
  handleAddToSupply,
  candidate,
  isClosed,
  setPage,
  jobId,
  onUpdateHiringData,
  hiringData,
  disableTabsAndButtons,
}: CandidateProps) {
  const [reviewModal, setReviewModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [relationships, setRelations] = useState<any[]>([])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [responses, setResponses] = useState<Responses>({
    response1: '',
    response2: '',
    response3: '',
    response4: '',
    additionalComments: '',
  })
  const [referees, setReferees] = useState<
    {
      id: string
      name: string
      email: string
      phone: string
      relationship: string
      organization: string
      status: string
      response1: string
      response2: string
      response3: string
      response4: string
      additionalComments: string
    }[]
  >([])

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  useEffect(() => {
    if (candidate) {
      loadingService
        .await(
          getMasterData().then((data: any) => {
            setRelations(
              data.filter((d: any) => d.category == 'referee_relationship')
            )
            setIsLoading(false)
          })
        )
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
      loadingService
        .await(getRefereeComments(candidate.id))
        .then((res) => {
          if (res?.length > 0) {
            setReferees(res)
          }
        })
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    } else {
      setIsLoading(false)
      setReferees([])
    }
  }, [loadingService, candidate])

  const initialVal = useMemo(() => {
    return {}
  }, [])
  const handleSubmit = () => {
    loadingService
      .await(
        updateHiringProcess(
          {
            candidate_id: candidate?.id ?? '',
            job_id: jobId,
          },
          { stage: HiringStage.Negotiation, hiringProcessId: hiringData.id }
        )
      )
      .then((res) => {
        if (!res) {
          setErrorModel(true)
          setErrorMessage('Update Error')
        }
        if (onUpdateHiringData) {
          onUpdateHiringData({
            ...hiringData,
            stage: HiringStage.Negotiation,
          })
        }

        if (setPage) {
          setPage(HiringStage.Negotiation)
        }
      })
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Formik initialValues={initialVal} onSubmit={handleSubmit}>
        <Form className={'mt-4 w-100'}>
          <div className={'d-flex w-100 justify-content-between'}>
            <div className={'mt-4 w-100'}>
              {referees?.length > 0 &&
                referees.map((referee, index) => (
                  <>
                    {referee.status === 'posted_comment' ? (
                      <div
                        className={styles.educationCard}
                        key={`referee-comment-item-${index}`}
                      >
                        <div className={'d-flex align-items-center w-100'}>
                          <div className={'ms-4 w-100'}>
                            <h6 className={'text-bold gray-color-text'}>
                              {referee.name}
                            </h6>
                            <h6 className={'gray-color-text'}>
                              {referee.email}
                            </h6>
                            <h6 className={'gray-color-text'}>
                              {referee.phone}
                            </h6>
                            <h6 className={'gray-color-text'}>
                              {referee.organization}
                            </h6>
                            <h6 className={'gray-color-text'}>
                              {
                                relationships.filter(
                                  (r: any) => (r.id = referee.relationship)
                                )[0]?.value
                              }
                            </h6>
                            <div className={'d-flex mt-2 w-100'}>
                              <h6
                                className={
                                  'mt-3 text-normal gray-color-text d-flex'
                                }
                              >
                                <span
                                  className={styles.elipse}
                                  style={{ width: '46%' }}
                                >
                                  {referee.response1}
                                </span>
                                <span
                                  className={
                                    'pointer text-bold gray-color-text ms-4'
                                  }
                                  onClick={() => {
                                    setResponses({
                                      response1: referee.response1,
                                      response2: referee.response2,
                                      response3: referee.response3,
                                      response4: referee.response4,
                                      additionalComments:
                                        referee.additionalComments,
                                    })
                                    setReviewModal(true)
                                  }}
                                >
                                  Read More
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={styles.educationCard}
                        key={`referee-comment-item-${index}`}
                      >
                        <div className={'d-flex align-items-center'}>
                          <div className={'ms-4'}>
                            <h6 className={'text-bold gray-color-text'}>
                              {referee.name}
                            </h6>
                            <div className={'d-flex mt-2'}>
                              <h6
                                className={'mt-3 text-normal gray-color-text'}
                              >
                                Review Pending
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              {referees?.length == 0 && (
                <h5 className={'mt-4 gray-color-text'}>
                  Referees have not been added
                </h5>
              )}
            </div>
          </div>

          <div
            className={
              'd-md-flex justify-content-between align-items-center mt-5'
            }
          >
            <CustomButton
              className={styles.supplyBtn}
              disabled={supplyStatus || isClosed}
              text={'Unsuccessful'}
              onClick={() => handleAddToSupply()}
              icon={<UploadIcon color={colors.blackColor} />}
              iconSide={'left'}
            />
            <CustomButton
              type={'submit'}
              className={`mt-2 mt-md-0 ${styles.widthBtn}`}
              text={'Move to Negotiation'}
              icon={<RightLongArrowIcon />}
              iconSide={'right'}
              disabled={supplyStatus || isClosed || disableTabsAndButtons}
            />
          </div>
        </Form>
      </Formik>
      {reviewModal && (
        <CustomModal
          open={reviewModal}
          onCloseModal={(val: any) => setReviewModal(val)}
        >
          <ReviewModal
            response1={responses.response1}
            response2={responses.response2}
            response3={responses.response3}
            response4={responses.response4}
            additionalComments={responses.additionalComments}
          />
        </CustomModal>
      )}
      <ErrorBannerModal
        onClose={() => setErrorModel(false)}
        open={errorModel}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default ReferenceCheck
