import styles from '../teams/styles.module.scss'
import DownArrowIcon from '../../icons/downArrow.icon'
import { useState } from 'react'
import UpArrowIcon from '../../icons/upArrow.icon'

type viewProps = {
  title?: string
  questionHeading?: string
  description?: string
  data: any
}

function ExpandableView({ title, description, data }: viewProps) {
  const [expand, setExpand] = useState(false)

  return (
    <div>
      <div className={styles.expandable}>
        <div className={styles.header}>
          <h5>{title}</h5>
          <div onClick={() => setExpand(!expand)}>
            {!expand ? <DownArrowIcon /> : <UpArrowIcon />}
          </div>
        </div>
        {expand && (
          <div className={'mt-3'}>
            {data.filter((f: any) => f.type === 'formula-value').length > 0 && (
              <>
                <h5 className={'mt-3'}>Transaction Questions</h5>
                <p className={'text-normal mt-4'}>{description}</p>
                {data
                  .filter((f: any) => f.type === 'formula-value')
                  .map((d: any, index: any) => {
                    return (
                      <div style={{ marginLeft: '6px' }} key={`expc${index}`}>
                        <h6 className={'mt-4'}>{d?.text}</h6>
                        <h6 className={'gray-color-text mt-1 ms-4'}>
                          {d.response
                            ? d.response
                            : d.answer?.text
                              ? d.answer?.text
                              : '-'}
                        </h6>
                      </div>
                    )
                  })}
              </>
            )}
            {data.filter((f: any) => f.type === 'reward-penalty').length >
              0 && (
              <>
                <h5 className={'mt-5'}>Efficiency Questions</h5>
                <p className={'text-normal mt-4'}>{description}</p>
                {data
                  .filter((f: any) => f.type === 'reward-penalty')
                  .map((d: any, index: number) => {
                    return (
                      <div
                        style={{ marginLeft: '6px' }}
                        key={`exp-view-1-${index}`}
                      >
                        <h6 className={'mt-4'}>{d?.text}</h6>
                        <h6 className={'gray-color-text mt-1 ms-4'}>
                          {d.answer ? d.answer.text : '-'}
                        </h6>
                      </div>
                    )
                  })}
              </>
            )}
            {data.filter((f: any) => f.type === 'written-response').length >
              0 && (
              <>
                <h5 className={'mt-5'}>General Questions</h5>
                <p className={'text-normal mt-4'}>{description}</p>
                {data
                  .filter((f: any) => f.type === 'written-response')
                  .map((d: any, index: number) => {
                    return (
                      <div
                        style={{ marginLeft: '6px' }}
                        key={`exp-view-2-${index}`}
                      >
                        <>
                          <h6 className={'mt-4'}>{d?.text}</h6>
                          <h6 className={'gray-color-text mt-1 ms-4'}>
                            {d.response ? d.response : '-'}
                          </h6>
                        </>
                      </div>
                    )
                  })}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpandableView
