import React from 'react'

interface Props {
  size?: number
  color?: string
}

const PerRateIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 20}
      height={size ?? 20}
      fill="none"
    >
      <path
        stroke={color ?? '#374151'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12.8 7A2 2 0 0 0 11 6H9a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4H9a2 2 0 0 1-1.8-1M10 4v2m0 8v2m9-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  )
}

export default PerRateIcon
