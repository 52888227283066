import React from 'react'

import styles from '../filterModal/styles.module.scss'
import face from '../../../images/organization.png'
import WrongIcon from '../../../icons/wrong.icon'
import color from '../../../scss/variables.module.scss'

type tagProps = {
  imageNot?: boolean
  name: string
  id: string
  imageUrl: string
  remove: (id: string) => void
  primaryUser?: boolean
  showUserType?: boolean
}

function Tag({
  imageNot,
  name,
  imageUrl,
  remove,
  id,
  primaryUser,
  showUserType,
}: tagProps) {
  return (
    <div className={styles.tag} key={`${id}`} style={{ marginBottom: '5px' }}>
      {!imageNot && (
        <img
          className={styles.imageCircle}
          src={imageUrl ?? face}
          alt={'test'}
        />
      )}
      <div className={styles.responsive}>
        <h6 className={'ms-3'}>{name}</h6>
        {showUserType ? (
          <>
            <div
              className={styles.payableTag}
              style={
                primaryUser
                  ? {
                      backgroundColor: color.successColor50,
                      color: color.successColor500,
                    }
                  : {
                      backgroundColor: color.dangerColor,
                      color: color.blackColor,
                    }
              }
            >
              <h6 className={'ms-3'}>
                {primaryUser ? 'Primary User' : 'BackUp User'}
              </h6>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={'ms-2'} onClick={() => remove(id)}>
        <WrongIcon size={10} />
      </div>
    </div>
  )
}

export default Tag
