import React, { useEffect, useState } from 'react'
import CustomModal from '../customModal/customModal'
import CustomButton from '../customButton/customButton'
import styles from './styles.module.scss'
import DocViewer from '@cyntler/react-doc-viewer'

type ErrorBannerModalProps = {
  open: boolean
  onClose: (val: boolean) => void
  title?: string
  url: string
}

function DocViewModel({ open, onClose, title, url }: ErrorBannerModalProps) {
  const [openModal, setOpenModal] = useState(open)

  useEffect(() => {
    setOpenModal(open)
  }, [open])

  return (
    <CustomModal
      title={title}
      open={openModal}
      onCloseModal={() => {
        setOpenModal(false)
        onClose(false)
      }}
    >
      <div style={{ height: '500px', width: '625px' }}>
        <div
          className={'d-flex align-items-center'}
          style={{ height: '100%', width: '100%' }}
        >
          <DocViewer
            documents={[
              {
                uri: url,
                fileType: 'docx',
                fileName: '',
              },
            ]}
            style={{ height: '100%', width: '100%' }}
          />
        </div>{' '}
        <div className={'d-flex align-items-center justify-content-end'}>
          <CustomButton
            className={`ms-2 ${styles.deleteButton}`}
            text={'Close'}
            onClick={() => onClose(false)}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default DocViewModel
