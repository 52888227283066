import { useState } from 'react'
import styles from '../teams/styles.module.scss'
import LocationIcon from '../../icons/location.icon'
import FilterIconNew from '../../icons/filterNew.icon'
import { IOrganization } from '../../shared/interfaces/organization.interface'
import SearchDropdown from '../../shared/components/customDropdown/searchDropdown'
import ArrowDownIcon from '../../icons/downArrow.icon'
import color from '../../scss/variables.module.scss'

interface IOrganizationFilterProps {
  disabled: boolean
  organizations: IOrganization[]
  onSelection: (data: IOrganization) => void
  onSearch: (searchValue: string) => void
  loadMore?: () => void
  setLoadMore?: (loadMore: boolean) => void
  resetPage?: () => void
  handleSearchValue?: (searchValue: any) => void
  searchValue?: (searchValue: any) => void
}

const OrganizationFilter = ({
  disabled,
  organizations,
  onSelection,
  onSearch,
  loadMore,
  setLoadMore,
  resetPage,
  handleSearchValue,
  searchValue,
}: IOrganizationFilterProps) => {
  // State variables
  const [selected, setSelected] = useState<number>(-1)
  const [order, setOrder] = useState<number>(0)

  /**
   * Handles the filter click
   * Toggles between ascending and descending order
   * @returns void
   */
  const onFilterClick = (): void => {
    if (disabled) return
    const newOrder = order === 0 ? 1 : 0
    setOrder(newOrder)
  }

  /**
   * Handles the selection of the organization
   * @param index The index of the selected organization
   * @param data The organization data
   * @returns void
   */
  const onDataSelection = (index: number, data: IOrganization): void => {
    if (disabled) return
    setSelected(index)
    onSelection(data)
  }

  /**
   * Handles the clear of the search
   * @returns void
   */
  const handleClear = (): void => {
    if (disabled) return
    if (handleSearchValue) {
      handleSearchValue(null)
    }
    if (setLoadMore) {
      setLoadMore(false)
    }
    if (resetPage) {
      resetPage()
    }
  }

  return (
    <div style={{ opacity: disabled ? 0.5 : 1 }}>
      <div className={`mx-md-5 ${styles.organizationsFilter}`}>
        <div className={`d-flex flex-column ${styles.borderBottom} py-4 mb-4`}>
          <div>
            <h6 className="text-almostBlack">Organisations</h6>
          </div>
          <div className="mt-4 mb-4">
            <SearchDropdown
              dataList={[]}
              placeHolder={'Search Organisations'}
              handleSearch={onSearch}
              onChange={() => searchValue}
              disabled={disabled}
              onClear={handleClear}
            />
          </div>
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            onClick={onFilterClick}
          >
            <h6 className={styles.widthLeft}>All Organisations</h6>
            <span className="d-flex align-items-center">
              <FilterIconNew />
              <div className="ms-3">Sort By</div>
            </span>
          </div>
        </div>
        {organizations
          .sort((a, b) => {
            if (a.name < b.name) {
              return order === 0 ? -1 : 1
            } else if (a.name > b.name) {
              return order === 0 ? 1 : -1
            }
            return 0
          })
          .map((data: IOrganization, index: number) => {
            return (
              <div
                className={`${styles.orgSelection} mt-3 mb-1 ${
                  selected === index ? styles.selected : ''
                }`}
                key={index}
                onClick={() => onDataSelection(index, data)}
              >
                <span>
                  <h6 className="gray-color-text">{data?.name}</h6>
                  <div className="d-flex mt-3">
                    <LocationIcon size={16} />
                    <h6 className="ps-3 gray-color-text">{data?.address}</h6>
                  </div>
                </span>
              </div>
            )
          })}
        <span
          className="d-flex align-items-center mt-3 mb-5"
          onClick={() => {
            if (loadMore) {
              loadMore()
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <h6 className="me-4 mt-2 pointer text-tertiary-color">Load More</h6>
          <ArrowDownIcon color={color.teritaryColor500} />
        </span>
      </div>
    </div>
  )
}

export default OrganizationFilter
