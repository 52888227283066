import React, { useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import EditIcon from '../../../icons/edit.icon'
import CustomButton from '../customButton/customButton'
import StarIcon from '../../../icons/star.icon'
import RecycleBinIcon from '../../../icons/recycleBinIcon'
import colors from '../../../scss/variables.module.scss'
import CustomModal from '../customModal/customModal'
import AddNewKeySkillModal from './keySkillModal'
import LoadingService from '../../services/loading.service'
import { updateCandidateKeySkillsSummary } from '../../services/candidate.service'
import PlusIcon from '../../../icons/plus.icon'
import ConfirmBannerModal from '../errorBannerModal/confirmBannerModal'

export interface KeySkillsProfileProps {
  disabled?: boolean
  candidateId?: string
  keySkillsSummary?: string
  lastUpdatedBy?: string
  lastUpdatedAt?: string
  onUpdateKeySkills?: (keySkills: {
    key_skills_summary: string
    key_skills_summary_last_updated_at: string
    key_skills_summary_last_updated_by: string
  }) => void
  reloadData?: () => void
}

const KeySkill = ({
  disabled,
  candidateId,
  keySkillsSummary,
  lastUpdatedAt,
  reloadData,
}: KeySkillsProfileProps) => {
  const [openSkillModal, setOpenSkillModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(disabled)
  const [keySkills, setKeySkills] = useState<string[]>([])
  const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(
    null
  )
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteId, setDeleteId] = useState<string>('')

  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )
  useEffect(() => {
    setIsDisabled(disabled || isLoading)
  }, [disabled, isLoading])

  useEffect(() => {
    setKeySkills(
      keySkillsSummary
        ? keySkillsSummary
            .split('.')
            .map((s: string) => s.trim())
            .filter((s: string) => s !== '')
        : []
    )
  }, [keySkillsSummary])

  const saveKeySkills = (keySkillsToSave: string[]) => {
    return new Promise((resolve, reject) => {
      if (!candidateId) {
        setIsLoading(false)

        return reject(false)
      }
      loadingService
        .await(
          updateCandidateKeySkillsSummary(
            candidateId,
            keySkillsToSave.join('. ')
          )
        )
        .then((success: boolean) => {
          if (!success) {
            setIsLoading(false)
            return reject(false)
          }
          if (reloadData) {
            reloadData()
          }
          setIsLoading(false)

          resolve(true)
        })
    })
  }
  const deleteSkill = (index: any) => {
    setShowConfirm(false)
    setIsLoading(true)
    if (index > -1) {
      keySkills.splice(index, 1)
      saveKeySkills(keySkills)
    }
    setIsLoading(true)
  }
  const onModalSubmit = (newSkill: string) => {
    if (currentSkillIndex !== null) {
      saveKeySkills(
        keySkills.map((s, i) => (i === currentSkillIndex ? newSkill : s))
      ).then(() => {
        setCurrentSkillIndex(null)
      })
    } else {
      saveKeySkills([...keySkills, newSkill])
    }
  }

  return (
    <div style={{ opacity: isDisabled ? 0.5 : 1 }}>
      <div
        className={`d-flex align-items-center justify-content-between pb-3 ${styles.borderBottom}`}
      >
        <div className={'d-flex align-items-center mb-3'}>
          <StarIcon color={colors.blackColor} size={20} />
          <h4 className={'text-almostBlack ms-3 text-bolder'}>
            Key Skills Summary
          </h4>
        </div>
        <div className={'d-flex justify-content-between'}>
          <div>
            <CustomButton
              disabled={isDisabled}
              text={'Add New Skill'}
              className={`ms-2 ${styles.addSkillBtn}`}
              icon={<PlusIcon />}
              iconSide={'left'}
              onClick={() => {
                setCurrentSkillIndex(null)
                setOpenSkillModal(true)
              }}
            />
          </div>
        </div>
      </div>
      {keySkills.length > 0 ? (
        <div>
          {keySkills.map((value, index) => (
            <div key={`key-skill-${index}`} className={`${styles.skillsCard}`}>
              <div className="d-flex justify-content-between">
                <div className={'d-flex align-items-center'}>
                  <div className={styles.listDot} />
                  <h6 className="mr-3 text-normal ms-3 w-100">{value}</h6>
                </div>
                <div className="d-flex justify-content-end">
                  <CustomButton
                    disabled={isDisabled}
                    className={`${styles.nonOutlinedButton} ${styles.widthButton} ${styles.removePadding}`}
                    variant={'transparent'}
                    icon={<RecycleBinIcon />}
                    onClick={() => {
                      setCurrentSkillIndex(index)
                      setShowConfirm(true)
                    }}
                    iconSide={'right'}
                  />
                  <CustomButton
                    disabled={isDisabled}
                    className={`${styles.nonOutlinedButton} ${styles.widthButton} ${styles.removePadding}`}
                    variant={'transparent'}
                    icon={<EditIcon />}
                    iconSide={'right'}
                    onClick={() => {
                      setCurrentSkillIndex(index)
                      setOpenSkillModal(true)
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className={'text-almostBlack'}>No key skills added.</p>
      )}
      <div>
        {lastUpdatedAt && (
          <p>
            Last updated on{' '}
            {lastUpdatedAt &&
              (() => {
                const dateObj = new Date(lastUpdatedAt)
                const day = dateObj.getDate().toString().padStart(2, '0')
                const month = (dateObj.getMonth() + 1)
                  .toString()
                  .padStart(2, '0') // Month is zero-based
                const year = dateObj.getFullYear()
                return `${day}-${month}-${year}`
              })()}
          </p>
        )}
        <p>Powered by GPT-4.</p>
      </div>
      {openSkillModal && (
        <CustomModal
          open={openSkillModal}
          title={currentSkillIndex !== null ? 'Update Skill' : 'Add New Skill'}
          onCloseModal={(val: any) => {
            setOpenSkillModal(val)
          }}
        >
          <AddNewKeySkillModal
            closeModal={() => {
              setOpenSkillModal(false)
            }}
            onSubmit={onModalSubmit}
            initialVal={
              currentSkillIndex !== null ? keySkills[currentSkillIndex] : ''
            }
          />
        </CustomModal>
      )}
      <ConfirmBannerModal
        onYesClick={() => deleteSkill(currentSkillIndex)}
        open={showConfirm}
        onClose={(close) => {
          setDeleteId('')
          setShowConfirm(close)
        }}
        title={'Delete Confirmation'}
        confirmMessage={'Are you sure you want to delete this skill?'}
      />
    </div>
  )
}

export default KeySkill
