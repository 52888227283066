import React, { useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import AssessmentResult from './assessmentResult'
import Overview from './overview'
import KeySkill from './keySkill'
import Expertise from './expertise'
import Education from './education'
import Certificate from './certificate'
import CustomButton from '../customButton/customButton'
import { CandidateDetails } from '../../interfaces/candidate.interface'
import {
  HiringProcess,
  HiringProcessSearchRequest,
} from '../../interfaces/hiring.interface'
import LoadingService from '../../services/loading.service'
import {
  getCandidateFiles,
  getHiringProcess,
  updateHiringProcess,
} from '../../services/hiring.service'
import { UploadedFile } from '../../types/file.model'
import ResumeIcon from '../../../icons/resume.icon'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import loadingStyles from '../customLoadingSpinner/loadingSpinner.module.scss'
import resumeStyles from '../../../pages/dashboard/Backdrop.module.scss'
import fileStyles from '../../../shared/components/candidateInfo/styles.module.scss'
import WorkExperience from './workExperience'
import Preferences from './preferences'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

interface CandidateProfileProps {
  candidate: CandidateDetails | null
  hiringData: HiringProcess | null
  disabled: boolean
  onUpdateCandidate?: (details: CandidateDetails) => void
  onUpdateHiringData?: (process: HiringProcess) => void
  isClosed?: boolean
}

function CandidateProfile({
  candidate,
  hiringData,
  disabled,
  onUpdateCandidate,
  onUpdateHiringData,
}: CandidateProfileProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [resume, setResume] = useState<UploadedFile | null>(null)
  const [coverLetter, setCoverLetter] = useState<UploadedFile | null>(null)
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )

  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  useEffect(() => {
    setIsDisabled(disabled || isLoading)
  }, [disabled, isLoading])

  const [notes, setNotes] = useState<string>(hiringData?.screening_notes ?? '')

  useEffect(() => {
    const params: HiringProcessSearchRequest = {
      candidate_id: candidate?.id ?? '',
      job_id: hiringData?.job_id ?? '',
    }
    getHiringProcess(params)
      .then((res) => {
        if (!res) return
        setNotes(res.screening_notes ?? '')
      })
      .catch(() => {
        setErrorModal(true)
        setErrorMessage(
          'Something went wrong when retrieving saved notes. Please try again later.'
        )
      })
  }, [candidate?.id, hiringData?.job_id])

  const handleSaveNotes = () => {
    loadingService
      .await(
        updateHiringProcess(
          {
            candidate_id: candidate?.id ?? '',
            job_id: hiringData?.job_id ?? '',
          },
          { screening_notes: notes }
        )
      )
      .then((success: boolean) => {
        if (!success) {
          setErrorModal(true)
          setErrorMessage('Update Error')
          return
        }
        if (onUpdateHiringData && hiringData) {
          onUpdateHiringData({ ...hiringData, screening_notes: notes })
        }
      })
  }

  useEffect(() => {
    setIsLoading(true)
    if (candidate) {
      if (candidate.resume_key) {
        const resume_key = candidate.resume_key?.split('/')
        const resume_url: any = {
          name: resume_key[2],
          contents: '',
          type: 'url',
        }
        setResume(resume_url)
        setIsLoading(false)
      }

      if (candidate.cover_letter_key) {
        const cover_letter_key = candidate.cover_letter_key?.split('/')
        const cover_letter_url: any = {
          name: cover_letter_key[2],
          contents: '',
          type: 'url',
        }
        setCoverLetter(cover_letter_url)
        setIsLoading(false)
      }
    }
  }, [candidate])

  const handleUpdateOverview = (overview: {
    overview: string
    overview_last_updated_at: string
    overview_last_updated_by: string
  }) => {
    if (onUpdateCandidate && candidate) {
      onUpdateCandidate({
        ...candidate,
        summary: { ...candidate.summary, ...overview },
      })
    }
  }

  const handleUpdateKeySkillsSummary = (keySkillsSummary: {
    key_skills_summary: string
    key_skills_summary_last_updated_at: string
    key_skills_summary_last_updated_by: string
  }) => {
    if (onUpdateCandidate && candidate) {
      onUpdateCandidate({
        ...candidate,
        summary: { ...candidate.summary, ...keySkillsSummary },
      })
    }
  }

  const openResumePDFInNewTab = async () => {
    try {
      setIsLoading(true)
      if (candidate) {
        const params = {
          id: candidate.id,
          URL_Type: 'resume',
        }
        const url: any = await getCandidateFiles(params)
        const pdfUrl = url
        setIsLoading(false)
        window.open(pdfUrl, '_blank')
      }
    } catch (error: any) {
      setErrorModal(true)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }

  const openCoverLetterPDFInNewTab = async () => {
    try {
      setIsLoading(true)
      if (candidate) {
        const params = {
          id: candidate.id,
          URL_Type: 'cover_letter',
        }
        const url: any = await getCandidateFiles(params)
        const pdfUrl = url
        setIsLoading(false)
        window.open(pdfUrl, '_blank')
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <div style={{ opacity: isDisabled ? 0.5 : 1 }}>
          <div className={'d-flex w-100 justify-content-between'}>
            <div className={'mt-4 w-100'}>
              <div className={`justify-content-between ${styles.responsive}`}>
                <div className={'mb-3 mb-md-0 me-0 me-md-4'}>
                  <h6>Private Notes</h6>
                  <textarea
                    className={styles.textArea}
                    value={notes ?? ''}
                    disabled={isDisabled}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                  <div
                    className={
                      'd-flex justify-content-end align-items-center mt-3'
                    }
                  >
                    <CustomButton
                      text={'Save Notes'}
                      iconSide={'left'}
                      className={styles.widthBtn}
                      onClick={handleSaveNotes}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <AssessmentResult
                  hardSkillPercentage={Math.floor(
                    (100 * (candidate?.assessment_results.last_score ?? 0)) /
                      (candidate?.assessment_results.max_score ?? 1)
                  )}
                  softSkillPercentage={0}
                  hardSkillCategoryResults={
                    candidate?.assessment_results.category_results.map((r) => ({
                      category: r.category,
                      percentage: Math.floor(
                        (100 * r.last_score) / r.max_score
                      ),
                    })) ?? []
                  }
                  softSkillCategoryResults={[]}
                  candidate={candidate?.id ?? ''}
                />
              </div>
              <div className={`mt-3 ${styles.contentBox}`}>
                <Preferences candidateId={candidate!.id} />
              </div>
              <div className={`mt-3 ${styles.contentBox}`}>
                <Overview
                  disabled={isDisabled}
                  candidateId={candidate?.id ?? ''}
                  onUpdateOverview={handleUpdateOverview}
                  overview={candidate?.summary.overview ?? ''}
                  lastUpdatedAt={
                    candidate?.summary.overview_last_updated_at ?? ''
                  }
                  lastUpdatedBy={
                    candidate?.summary.overview_last_updated_by ?? ''
                  }
                />
              </div>
              <div className={`mt-3 ${styles.contentBox}`}>
                <KeySkill
                  disabled={isLoading}
                  candidateId={candidate?.id ?? ''}
                  onUpdateKeySkills={handleUpdateKeySkillsSummary}
                  keySkillsSummary={candidate?.summary.key_skills_summary ?? ''}
                  lastUpdatedAt={
                    candidate?.summary.key_skills_summary_last_updated_at ?? ''
                  }
                  lastUpdatedBy={
                    candidate?.summary.key_skills_summary_last_updated_by ?? ''
                  }
                />
              </div>
              <div className={`mt-3 ${styles.contentBox}`}>
                <Expertise
                  taskExpertise={candidate?.task_expertise}
                  systemExpertise={candidate?.system_expertise}
                  industryExpertise={candidate?.industry_expertise}
                />
              </div>
              <div className={`mt-3 ${styles.contentBox}`}>
                <div className={`pb-5 ${styles.borderBottom}`}>
                  <Education qualifications={candidate?.qualifications} />
                </div>
                <div className={'mt-5'}>
                  <Certificate certifications={candidate?.certifications} />
                </div>
              </div>
              <div className={`mt-3 ${styles.contentBox}`}>
                {resume && (
                  <div className="mt-5">
                    <div className={'d-flex align-items-center mb-3'}>
                      <ResumeIcon />
                      <h4 className={'text-almostBlack ms-3 text-bolder'}>
                        Resume
                      </h4>
                    </div>
                    <div className={fileStyles.selectedResume}>
                      <h6 className={'ms-3 text-bold gray-color-text'}>
                        Selected File
                      </h6>

                      <div className={`d-flex ${fileStyles.borderTop}`}>
                        <div
                          className={
                            'd-flex justify-content-between align-items-center w-100'
                          }
                        >
                          <h6
                            className={
                              'text-normal gray-color-text d-flex align-items-center'
                            }
                          >
                            <span className={resumeStyles.ellipse}>
                              {resume ? resume.name : ''}
                            </span>{' '}
                            <span
                              hidden={isLoading}
                              className={`${resumeStyles.link} ${styles.w15}`}
                              onClick={openResumePDFInNewTab}
                            >
                              View File
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {coverLetter && (
                  <div className="mt-5">
                    <div className={'d-flex align-items-center mb-3'}>
                      <ResumeIcon />
                      <h4 className={'text-almostBlack ms-3 text-bolder'}>
                        Cover Letter
                      </h4>
                    </div>
                    <div className={fileStyles.selectedResume}>
                      <h6 className={'ms-3 text-bold gray-color-text'}>
                        Selected File
                      </h6>

                      <div className={`d-flex ${fileStyles.borderTop}`}>
                        <div className={'d-flex align-items-center w-100'}>
                          <h6
                            className={
                              'text-normal gray-color-text d-flex align-items-center w-100'
                            }
                          >
                            <span className={resumeStyles.ellipse}>
                              {coverLetter ? coverLetter.name : ''}
                            </span>{' '}
                            <span
                              hidden={isLoading}
                              className={`ms-2 ${resumeStyles.link} ${styles.w15}`}
                              onClick={openCoverLetterPDFInNewTab}
                            >
                              View File
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={`mt-3 ${styles.contentBox}`}>
                <WorkExperience data={candidate?.work_experience} disabled />
              </div>
              <ErrorBannerModal
                open={errorModal}
                onClose={() => setErrorModal(false)}
                errorMessage={errorMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CandidateProfile
