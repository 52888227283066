import { createContext, useContext, useState } from 'react'

interface ContextProps {
  readonly hiringStatus: any | null | undefined
  readonly updateHiringStatus: (masterData: any) => void
}

export const HiringProcessContext = createContext<ContextProps>({
  hiringStatus: null,
  updateHiringStatus: () => null,
})

export function useHiringProcess() {
  return useContext(HiringProcessContext)
}

export function HiringProcessProvider({ children }: any) {
  const [hiringStatus, setHiringStatus] = useState('Screening')
  const updateHiringStatus = (newStatus: any) => {
    setHiringStatus(newStatus)
  }

  return (
    <HiringProcessContext.Provider value={{ hiringStatus, updateHiringStatus }}>
      {children}
    </HiringProcessContext.Provider>
  )
}

export default HiringProcessContext
