import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import colors from '../../../scss/variables.module.scss'
import BagIcon from '../../../icons/bag.icon'
import WalkingManIcon from '../../../icons/walkingMan.icon'
import LocationIcon from '../../../icons/location.icon'
import RightArrowIcon from '../../../icons/rightArrow.icon'
import CustomButton from '../customButton/customButton'
import ApproveIcon from '../../../icons/approve.icon'
import InfoIcon from '../../../icons/info.icon'
import { Form, Formik } from 'formik'
import CustomInputField from '../customInputField/customInputField'
import PerRateIcon from '../../../icons/perRate.icon'
import HireTeamDisplay from '../addTeam/addTeam'
import { JobDetails } from '../../interfaces/job.interface'
import { useNavigate } from 'react-router-dom'
import { HiringStageType } from '../../../pages/dashboard/dashboardHiringProcess'
import { getHiringStageOverviewForJob } from '../../services/hiring.service'
import { HiringPage } from '../../enums/hiring.enum'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import loadingStyles from '../customLoadingSpinner/loadingSpinner.module.scss'
import { getUserRole } from '../../services/auth.service'
import { UserRole } from '../../enums/auth.enum'
import { approveCostPerMonth } from '../../services/job.service'
import PersonalInfoIcon from '../../../icons/personalInfo.icon'
import ConfirmBannerModal from '../errorBannerModal/confirmBannerModal'
import { JobStatus, WorkCommitment } from '../../enums/job.enum'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type JobCardProps = {
  disabled: boolean
  details: JobDetails
  navigateFromUrl?: boolean
}

type ApproveCostFormProps = {
  cost: number
}

function AdminJobCard({ details, disabled, navigateFromUrl }: JobCardProps) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [disableButtons, setDisableButton] = useState<boolean>(disabled)
  const [hiringStageOverview, setHiringStageOverview] = useState<
    HiringStageType[]
  >([])
  const [privilege, setPrivilege] = useState<boolean>(false)
  const [enableButtons, setEnableButtons] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [teamComplete, setTeamComplete] = useState<boolean>(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // Define initial value, based on cost details
  const initialVal = useMemo(
    () => ({
      cost: (
        ((details.approved_cost_per_month ??
          details.total_cost_per_month ??
          0) *
          100) /
        100
      ).toFixed(2),
    }),
    [details]
  )

  /**
   * Handle approve cost form submit
   * @param {ApproveCostFormProps} values the form values
   * @returns {void}
   */
  const handleApproveCost = async ({
    cost,
  }: ApproveCostFormProps): Promise<any> => {
    setShowConfirm(false)
    setIsLoading(true)
    setDisableButton(true)
    await approveCostPerMonth({
      id: details.id,
      approved_cost_per_month: +cost,
    }).then((res) => {
      if (!res) {
        setErrorModel(true)
        setErrorMessage('Error..')
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })
  }

  const handleStageChange = useCallback(async () => {
    setIsLoading(true)
    if (!details) return
    try {
      const res = await getHiringStageOverviewForJob(details.id)
      if (res) {
        setHiringStageOverview(res)
        setIsLoading(false)
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }, [details])

  useEffect(() => {
    handleStageChange()
  }, [handleStageChange])

  const getNotificationCount = (stage: string) => {
    return hiringStageOverview.find((s) => s.stage === stage)?.count ?? 0
  }

  useEffect(() => {
    getUserRole()
      .then((role) => {
        if (role === UserRole.Manager || role === UserRole.GlobalAdmin) {
          setEnableButtons(true)
          setPrivilege(true)
        } else {
          if (details.status === 'pending-approval') {
            setPrivilege(false)
            return
          } else {
            setEnableButtons(true)
            setPrivilege(true)
          }
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [details])

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      {isLoading && <LoadingSpinner />}
      <div className={'mb-4'} style={{ opacity: disabled ? 1 : 1 }}>
        <div
          className={styles.card}
          style={
            navigateFromUrl
              ? {
                  borderColor: colors.successColor500,
                  border: `2px solid ${colors.successColor500}`,
                }
              : details.hiring_team_completed || teamComplete
                ? { borderColor: colors.gray200 }
                : {
                    borderColor: colors.dangerColor,
                    border: `2px solid ${colors.dangerColor}`,
                  }
          }
        >
          <div
            onClick={() => {
              if (privilege) {
                navigate('/candidate', {
                  state: { job: details, disabled: disabled },
                })
              }
            }}
          >
            <div className={'d-flex align-items-center flex-wrap'}>
              <div
                onClick={() => {
                  if (privilege) {
                    navigate('/candidate', {
                      state: { job: details, disabled: disabled },
                    })
                  }
                }}
              >
                <div className="d-flex flex-row justify-content-between">
                  <h3
                    className={'text-bolder'}
                    style={{
                      textDecoration: 'underline',
                      color: 'black',
                      cursor: 'pointer',
                    }}
                  >
                    {details.title}
                  </h3>
                  {details.jobs_status === JobStatus.Inactive && (
                    <h6 className={`${styles.inactiveTag}`}>
                      {details.jobs_status.charAt(0).toUpperCase() +
                        details.jobs_status.slice(1)}
                    </h6>
                  )}
                </div>
              </div>
              <div className={`mb-2 ${styles.payableTag}`}>
                <h6>{details.org_name}</h6>
              </div>
              {(details.skills ?? [])
                .filter((s) => s.type === 'task_expertise')
                .map((s, index) => (
                  <div
                    className={`mb-2 ${styles.payableTag}`}
                    key={`ttsxt${index}`}
                  >
                    <h6>{s.value}</h6>
                  </div>
                ))}
            </div>
          </div>
          <div
            className={` justify-content-between align-items-center mt-3 ${styles.responsive}`}
          >
            <div className={`align-items-center ${styles.responsive}`}>
              <div className={'d-flex me-4 mb-2 mb-md-0'}>
                <BagIcon />
                <h6 className={'ms-3 gray-color-text'}>
                  {details.commitment === WorkCommitment.PermanentFullTime
                    ? 'Full Time'
                    : details.commitment === WorkCommitment.PermanentPartTime
                      ? 'Part Time'
                      : details.commitment === WorkCommitment.Casual
                        ? 'Casual'
                        : 'Unknown Commitment'}
                </h6>
              </div>
              <div className={`mb-2 mb-md-0 ${styles.borderClass}`}>
                <WalkingManIcon />
                <h6 className={'ms-3 gray-color-text'}>{details.work_type}</h6>
              </div>
              <div className={styles.borderClass}>
                <LocationIcon />
                <h6 className={'ms-3 gray-color-text'}>{details.location}</h6>
              </div>
              {details.bbb_team && (
                <div className={'d-flex ms-0 ms-md-4 mt-2 mt-md-0'}>
                  <PersonalInfoIcon color={colors.grayColor} />
                  <h6 className={'ms-3 gray-color-text'}>{details.bbb_team}</h6>
                </div>
              )}
            </div>
            <HireTeamDisplay
              job_id={details.id}
              disabled={disabled}
              teamComplete={(val) => setTeamComplete(val)}
            />
          </div>
          <div
            onClick={() => {
              if (privilege) {
                navigate('/candidate', {
                  state: { job: details, disabled: disabled },
                })
              }
            }}
          >
            <div
              className={
                'd-flex mt-4 justify-content-between align-items-center'
              }
            >
              <div className={styles.stepBox}>
                <h6 className={'gray-color-text'}>Culture Catch-Up</h6>
                <h3>{getNotificationCount(HiringPage.CultureCatchUp)}</h3>
              </div>
              <RightArrowIcon color={colors.grayColor} />
              <div className={styles.stepBox}>
                <h6 className={'gray-color-text'}>Interview</h6>
                <h3>{getNotificationCount(HiringPage.Interview)}</h3>
              </div>
              <RightArrowIcon color={colors.grayColor} />
              <div className={styles.stepBox}>
                <h6 className={'gray-color-text'}>Reference Check</h6>
                <h3>{getNotificationCount(HiringPage.ReferenceCheck)}</h3>
              </div>
              <RightArrowIcon color={colors.grayColor} />
              <div className={styles.stepBox}>
                <h6 className={'gray-color-text'}>Negotiation</h6>
                <h3>{getNotificationCount(HiringPage.Negotiation)}</h3>
              </div>
              <RightArrowIcon color={colors.grayColor} />
              <div className={styles.stepBox}>
                <h6 className={'gray-color-text'}>Documentation</h6>
                <h3>
                  {getNotificationCount(HiringPage.PaperWork) +
                    getNotificationCount('Completed')}
                </h3>
              </div>
            </div>
          </div>
          <div
            className={`align-items-center mt-4 justify-content-between ${styles.responsive}`}
          >
            <div>
              <h6 className={'gray-color-text'}>Role Cost</h6>
              <h6 className={'gray-color-text text-semi-bold'}>
                {(
                  Math.round((details.total_hours_per_month ?? 0) * 100) / 100
                ).toFixed(2)}{' '}
                hrs per month
              </h6>
            </div>
            <div className={`me-2 ${styles.responsive}`}>
              <Formik
                initialValues={initialVal}
                onSubmit={() => setShowConfirm(true)}
                enableReinitialize={true}
              >
                {({ values, setFieldValue }) => (
                  <Form className={`align-items-center ${styles.responsive}`}>
                    <CustomInputField
                      disabled={
                        disableButtons ||
                        !enableButtons ||
                        details.status === 'open'
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('cost', e.target.value)
                      }
                      value={`${values}`}
                      name={'cost'}
                      icon={<PerRateIcon />}
                      className={`me-4 ${details.status === 'open' ? styles.disableFont : ''} ${styles.costInputWidth}`}
                    />
                    <CustomButton
                      disabled={
                        disableButtons ||
                        !enableButtons ||
                        details.status === 'open'
                      }
                      type={'submit'}
                      text={'approve'}
                      icon={
                        <ApproveIcon
                          color={
                            disabled ||
                            !enableButtons ||
                            details.status === 'open'
                              ? colors.grayColor
                              : colors.almostBlack
                          }
                        />
                      }
                      className={`mt-2 mt-md-0 ${disabled || !enableButtons || details.status === 'open' ? styles.approveDisableBtn : styles.approveBtn} ${disabled || !enableButtons || details.status === 'open' ? styles.disableFont : 'text-almostBlack'}`}
                    />
                    <ConfirmBannerModal
                      onYesClick={() =>
                        handleApproveCost({ cost: parseFloat(values.cost) })
                      }
                      open={showConfirm}
                      onClose={(close) => {
                        setShowConfirm(close)
                      }}
                      title={'Cost Approve Confirmation'}
                      confirmMessage={
                        'Are you sure you want to approve the role cost?'
                      }
                    />
                  </Form>
                )}
              </Formik>
            </div>
            <div className={`mt-2 mt-md-0 `}>
              {((details.total_data_sources ?? []).includes('xero') ||
                (details.total_data_sources ?? []).includes('myob') ||
                (details.total_data_sources ?? []).includes(
                  'needs_analysis'
                )) && (
                <div className={`${styles.infoBox}`}>
                  <InfoIcon />
                  <h6 className={'text-info-color ms-3'}>
                    Calculated from{' '}
                    {(details.total_data_sources ?? []).includes('xero')
                      ? 'XERO'
                      : (details.total_data_sources ?? []).includes('myob')
                        ? 'MYOB'
                        : (details.total_data_sources ?? []).includes(
                              'needs_analysis'
                            )
                          ? (details.total_data_sources ?? []).includes('xero')
                            ? 'XERO & Needs Analysis'
                            : (details.total_data_sources ?? []).includes(
                                  'myob'
                                )
                              ? 'MYOB & Needs Analysis'
                              : 'Needs Analysis Only'
                          : null}
                  </h6>
                </div>
              )}
            </div>
          </div>
          <h6 className={'gray-color-text text-normal'}>
            Created on{' '}
            <span className={'text-bold'}>
              {details &&
                details.created_at &&
                (() => {
                  const dateObj = new Date(details.created_at)
                  const day = dateObj.getDate().toString().padStart(2, '0')
                  const month = (dateObj.getMonth() + 1)
                    .toString()
                    .padStart(2, '0') // Month is zero-based
                  const year = dateObj.getFullYear()
                  return `${day}-${month}-${year}`
                })()}
            </span>
          </h6>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default AdminJobCard
