import React from 'react'

export type Responses = {
  response1: string
  response2: string
  response3: string
  response4: string
  additionalComments: string
}

function ReviewModal({
  response1,
  response2,
  response3,
  response4,
  additionalComments,
}: Responses) {
  const questions = [
    {
      text: 'What were the person’s biggest strengths?',
      field: 'response1',
      answer: response1,
    },
    {
      text: 'What were the person’s biggest areas of improvement back then?',
      field: 'response2',
      answer: response2,
    },
    {
      text: 'How would you rate their overall performance in that job on a 1-10 scale?',
      field: 'response3',
      answer: response3,
    },
    {
      text: 'What about their performance causes you to give that rating?',
      field: 'response4',
      answer: response4,
    },
    {
      text: 'Additional Comments',
      field: 'additionalComments',
      answer: additionalComments,
    },
  ]

  return (
    <div>
      <div>
        <div>
          {questions.map((value, index) => (
            <div className={'mt-3'} key={index}>
              <h6>{value.text}</h6>
              <div className={'mt-4'}>
                <textarea
                  className={'form-control'}
                  rows={3}
                  disabled
                  value={value.answer}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ReviewModal
