export enum WorkCommitment {
  PermanentPartTime = 'Permanent Part Time',
  PermanentFullTime = 'Permanent Full Time',
  Casual = 'Casual',
}

export enum WorkType {
  OnSite = 'On-Site',
  OffSite = 'Off-Site',
  Hybrid = 'Hybrid',
}

export enum JobStatus {
  IncompleteDetails = 'incomplete-details',
  NoCostGenerated = 'no-cost-generated',
  PendingApproval = 'pending-approval',
  Open = 'open',
  Closed = 'closed',
  Inactive = 'inactive',
}
