import { useState } from 'react'
import styles from '../teams/styles.module.scss'
import LocationIcon from '../../icons/location.icon'
import BagIcon from '../../icons/bag.icon'
import CustomDropdown from '../../shared/components/customDropdown/customDropdown'
import CustomButton from '../../shared/components/customButton/customButton'
import Overview from '../../shared/components/candidateInfo/overview'
import KeySkill from '../../shared/components/candidateInfo/keySkill'
import Expertise from '../../shared/components/candidateInfo/expertise'
import Education from '../../shared/components/candidateInfo/education'
import Certificate from '../../shared/components/candidateInfo/certificate'
import { Responses } from '../../shared/components/candidateInfo/referenceCheck'
import CustomModal from '../../shared/components/customModal/customModal'
import ReviewModal from '../../shared/components/candidateInfo/reviewModal'
import QuoteIcon from '../../icons/quote.icon'
import JPassInvoice from '../pdf/pdf'
import { BlobProvider } from '@react-pdf/renderer'
import ResumeIcon from '../../icons/resume.icon'
import fileStyles from '../../shared/components/candidateInfo/styles.module.scss'
import resumeStyles from '../dashboard/Backdrop.module.scss'
import { UploadedFile } from '../../shared/types/file.model'
import WorkExperience from '../../shared/components/candidateInfo/workExperience'
import Preferences from '../../shared/components/candidateInfo/preferences'
import Assessment from '../../shared/components/candidateInfo/assesmenmtResult'
import colors from '../../scss/variables.module.scss'
import AvatarImage from '../../images/avatar.png'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import ConfirmBannerModal from '../../shared/components/errorBannerModal/confirmBannerModal'
import SuccessBannerModal from '../../shared/components/errorBannerModal/successBannerModal'
import EmailIcon from '../../icons/email.icon'
import PhoneIcon from '../../icons/phone.icon'
import { UserWithCandidateInfo, type Referees } from './types'

interface Props {
  referees: Referees
  isActive: boolean
  isUpdating: boolean
  showErrorBanner: boolean
  isLoading: boolean
  isAdmin: boolean
  resume: UploadedFile | null
  coverLetter: UploadedFile | null
  selectedCandidate: UserWithCandidateInfo
  responseModalMessage: string
  error: any
  showResponseModal: boolean
  showDeleteConfirmationModal: boolean
  status: string | undefined
  setStatus: (status: string) => void
  setShowResponseModal: (showResponseModal: boolean) => void
  setShowDeleteConfirmationModal: (showDeleteConfirmationModal: boolean) => void
  loadCandidateData(candidateId: string): void
  handleSubmit: () => void
  setShowErrorBanner: (showErrorBanner: boolean) => void
  openCoverLetterPDFInNewTab: () => void
  openResumePDFInNewTab: () => void
  handleDeleteCandidate: (candidateId: string) => void
}

/**
 * 1. Need make search bar load a  list of users after 3 letters using debounce
 * 2. Selected Candidate should change when a candidate is selected in search
 * 3. This should make a call to get the info related to that candidate
 * 4. Make candidate its own component
 * */

export const Candidate: React.FC<Props> = ({
  referees,
  isActive,
  isUpdating,
  showErrorBanner,
  isLoading,
  isAdmin,
  selectedCandidate,
  coverLetter,
  resume,
  responseModalMessage,
  error,
  showResponseModal,
  showDeleteConfirmationModal,
  status,
  setStatus,
  setShowResponseModal,
  setShowDeleteConfirmationModal,
  handleDeleteCandidate,
  setShowErrorBanner,
  loadCandidateData,
  handleSubmit,
  openCoverLetterPDFInNewTab,
  openResumePDFInNewTab,
}) => {
  const [responses, setResponses] = useState<Responses>({
    response1: '',
    response2: '',
    response3: '',
    response4: '',
    additionalComments: '',
  })
  const [reviewModal, setReviewModal] = useState(false)
  const [modal, setModal] = useState(false)

  return (
    <>
      <div className={`mt-2 mt-md-0 ${styles.rightSideCandidates}`}>
        <div className={styles.responsive}>
          <div className={'d-flex'}>
            <img
              className={styles.image}
              src={selectedCandidate?.profile_url ?? AvatarImage}
            />
            <div className={'d-flex flex-column ms-3 '}>
              <h5 className={'gray-color-text'}>{selectedCandidate?.name}</h5>
              <div className={'d-flex align-items-center mt-3'}>
                <div className={'d-flex me-4'}>
                  <LocationIcon />
                  <h6 className={'ms-3 gray-color-text'}>
                    {selectedCandidate?.location}
                  </h6>
                </div>
              </div>
              <div className={'d-flex align-items-center mt-3'}>
                <div className={'d-flex me-4'}>
                  <EmailIcon />
                  <h6 className={'ms-3 gray-color-text'}>
                    {selectedCandidate?.email}
                  </h6>
                </div>
              </div>
              <div className={'d-flex align-items-center mt-3'}>
                <div className={'d-flex me-4'}>
                  <PhoneIcon />
                  <h6 className={'ms-3 gray-color-text'}>
                    {selectedCandidate?.mobile_number}
                  </h6>
                </div>
              </div>
              <div className={'d-flex mt-3'}>
                <BagIcon />
                <h6 className={'ms-3 gray-color-text text-normal'}>
                  Availability -{' '}
                  <span className={'text-bold'}>
                    {selectedCandidate &&
                    selectedCandidate.notice_period_days > 0
                      ? `${Math.ceil(
                          selectedCandidate.notice_period_days / 7
                        )} week${
                          Math.ceil(selectedCandidate.notice_period_days / 7) >
                          1
                            ? 's'
                            : ''
                        }`
                      : 'Immediate'}
                  </span>
                </h6>
              </div>
            </div>
          </div>
          <div>
            {selectedCandidate?.summary?.overview &&
            selectedCandidate.qualifications &&
            selectedCandidate?.summary?.key_skills_summary &&
            selectedCandidate.system_expertise ? (
              <>
                {selectedCandidate?.summary?.overview.length > 1020 ? (
                  <CustomButton
                    onClick={() => {
                      setModal(true)
                    }}
                    className={`mt-2 ${styles.borderBtn} ${styles.linkButton} d-flex justify-content-center w-100 mb-2`}
                    text={'Generate BBB Profile'}
                  />
                ) : (
                  <div className={'d-flex justify-content-center w-100 mb-2 '}>
                    <BlobProvider
                      document={
                        <JPassInvoice
                          name={selectedCandidate.name}
                          overView={selectedCandidate?.summary?.overview}
                          EducationExperience={
                            selectedCandidate.qualifications as []
                          }
                          skillSummary={
                            selectedCandidate?.summary?.key_skills_summary
                          }
                          systemsExperience={
                            selectedCandidate.system_expertise as []
                          }
                          profile_url={selectedCandidate.profile_url}
                        />
                      }
                    >
                      {({ url }) =>
                        url ? (
                          <a
                            download={`${selectedCandidate.name} BBB Profile`}
                            href={url || ''}
                            target="_blank"
                            rel="noreferrer"
                            className={`mt-2 mt-md-0 ${styles.borderBtn} ${styles.linkButton} ${styles.pdfButton}`}
                          >
                            Generate BBB Profile
                          </a>
                        ) : (
                          <CustomButton
                            className={`mt-2 ${styles.borderBtn} ${styles.linkButton} d-flex justify-content-center w-100 mb-2`}
                            disabled={true}
                            text={'Generate BBB Profile'}
                          />
                        )
                      }
                    </BlobProvider>
                  </div>
                )}
              </>
            ) : (
              <CustomButton
                className={`mt-2 ${styles.borderBtn} ${styles.linkButton} d-flex justify-content-center w-100 mb-2`}
                disabled={true}
                text={'Generate BBB Profile'}
              />
            )}
            {isAdmin && (
              <>
                <div className={styles.statusUpdate}>
                  <p className={'gray-color-text'}>Update Status</p>
                  <div className={styles.updateDropdown}>
                    <CustomDropdown
                      dataList={[
                        { text: 'Active', value: 'active' },
                        { text: 'Inactive', value: 'inactive' },
                      ]}
                      selectedValue={{
                        text: status
                          ? status.charAt(0).toUpperCase() + status.slice(1)
                          : '',
                        value: status ?? '',
                      }}
                      getSelectedItem={(item: any) => setStatus(item.value)}
                    />
                  </div>
                  <CustomButton
                    className={`mt-2 ${styles.borderBtn}`}
                    disabled={isUpdating}
                    loading={isUpdating}
                    onClick={handleSubmit}
                    text={'Update'}
                  />
                </div>
                <div>
                  <CustomButton
                    variant="danger"
                    className="mt-4"
                    text={'Delete Candidate'}
                    onClick={() => setShowDeleteConfirmationModal(true)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`mt-3 ${styles.contentBox}`}>
          <Preferences candidateId={selectedCandidate.id} />
        </div>
        <div className={`mt-3 ${styles.contentBox}`}>
          <Overview
            onUpdateOverview={() => loadCandidateData(selectedCandidate?.id)}
            candidateId={selectedCandidate?.id}
            overview={isActive ? selectedCandidate?.summary?.overview : ''}
            lastUpdatedAt={
              isActive
                ? selectedCandidate?.summary?.overview_last_updated_at
                : ''
            }
            lastUpdatedBy={
              isActive
                ? selectedCandidate?.summary?.overview_last_updated_by
                : ''
            }
          />
        </div>
        <div className={`mt-3 ${styles.contentBox}`}>
          <KeySkill
            reloadData={() => loadCandidateData(selectedCandidate?.id)}
            candidateId={selectedCandidate?.id}
            keySkillsSummary={
              isActive ? selectedCandidate?.summary?.key_skills_summary : ''
            }
            lastUpdatedAt={
              isActive
                ? selectedCandidate?.summary?.key_skills_summary_last_updated_at
                : ''
            }
            lastUpdatedBy={
              isActive
                ? selectedCandidate?.summary?.key_skills_summary_last_updated_by
                : ''
            }
          />
        </div>
        <div className={`mt-3 ${styles.contentBox}`}>
          <Expertise
            industryExpertise={selectedCandidate?.industry_expertise}
            systemExpertise={selectedCandidate?.system_expertise}
            taskExpertise={selectedCandidate?.task_expertise}
          />
        </div>
        <div className={`mt-3 ${styles.contentBox}`}>
          <div className={`pb-5 ${styles.borderBottom}`}>
            <Education qualifications={selectedCandidate?.qualifications} />
          </div>
          <div className={'mt-5'}>
            <Certificate certifications={selectedCandidate?.certifications} />
          </div>
        </div>
        <div className={`mt-3 ${styles.contentBox}`}>
          {resume && (
            <div>
              <div
                className={`d-flex align-items-center justify-content-between ${styles.borderBottomTitle}`}
              >
                <div className={'d-flex align-items-center mb-3'}>
                  <ResumeIcon color={colors.blackColor} />
                  <h4 className={'text-almostBlack ms-3 text-bolder'}>
                    Resume
                  </h4>
                </div>
              </div>
              <div className={fileStyles.selectedResume}>
                <h6 className={'ms-3 text-bold gray-color-text'}>
                  Selected File
                </h6>

                <div className={`d-flex ${fileStyles.borderTop}`}>
                  <div
                    className={
                      'd-flex justify-content-between align-items-center w-100'
                    }
                  >
                    <h6
                      className={
                        'text-normal gray-color-text d-flex align-items-center w-100'
                      }
                    >
                      <span className={resumeStyles.ellipseResume}>
                        {resume ? resume.name : ''}
                      </span>{' '}
                      <span
                        hidden={isLoading}
                        className={`${resumeStyles.linkCandidate} ${styles.w15}`}
                        onClick={openResumePDFInNewTab}
                      >
                        View File
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )}
          {coverLetter && (
            <div className={'mt-3'}>
              <div
                className={`d-flex align-items-center justify-content-between ${styles.borderBottomTitle}`}
              >
                <div className={'d-flex align-items-center mb-3'}>
                  <ResumeIcon color={colors.blackColor} />
                  <h4 className={'text-almostBlack ms-3 text-bolder'}>
                    Cover Letter
                  </h4>
                </div>
              </div>
              <div className={fileStyles.selectedResume}>
                <h6 className={'ms-3 text-bold gray-color-text'}>
                  Selected File
                </h6>

                <div className={`d-flex ${fileStyles.borderTop}`}>
                  <div
                    className={
                      'd-flex justify-content-between align-items-center w-100'
                    }
                  >
                    <h6
                      className={
                        'text-normal gray-color-text d-flex align-items-center w-100'
                      }
                    >
                      <span className={resumeStyles.ellipseResume}>
                        {coverLetter ? coverLetter.name : ''}
                      </span>{' '}
                      <span
                        hidden={isLoading}
                        className={`${resumeStyles.linkCandidate}`}
                        onClick={openCoverLetterPDFInNewTab}
                      >
                        View File
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`mt-3 ${styles.contentBox}`}>
          <WorkExperience data={selectedCandidate?.work_experience} disabled />
        </div>
        <div className={`${styles.contentBox}`}>
          <div>
            <div
              className={`d-flex align-items-center justify-content-between ${styles.borderBottomTitle}`}
            >
              <div className={'d-flex align-items-center'}>
                <QuoteIcon color="black" size={25} />
                <h4 className={'text-almostBlack ms-3 text-bolder'}>Reviews</h4>
              </div>
            </div>
            {referees?.length == 0 && (
              <h5 className={'mt-4 gray-color-text'}>
                Referees have not been added
              </h5>
            )}
            {referees.length > 0 &&
              referees.map((referee, index) => (
                <>
                  {referee.status === 'posted_comment' ? (
                    <div
                      className={styles.educationCard}
                      key={`referee-comment-item-${index}`}
                    >
                      <div className={'d-flex align-items-center w-100'}>
                        <div className={'ms-4 w-100'}>
                          <h6 className={'text-bold gray-color-text'}>
                            {referee.name}
                          </h6>
                          <div className={'d-flex mt-2'}>
                            <h6
                              className={
                                'mt-3 text-normal gray-color-text d-flex w-100'
                              }
                            >
                              <span
                                className={styles.elipse}
                                style={{ width: '20%' }}
                              >
                                {referee.response1}
                              </span>
                              <span
                                className={
                                  'pointer text-bold gray-color-text ms-2'
                                }
                                onClick={() => {
                                  setResponses({
                                    response1: referee.response1,
                                    response2: referee.response2,
                                    response3: referee.response3,
                                    response4: referee.response4,
                                    additionalComments:
                                      referee.additionalComments,
                                  })
                                  setReviewModal(true)
                                }}
                              >
                                Read More
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={styles.educationCard}
                      key={`referee-comment-item-${index}`}
                    >
                      <div className={'d-flex align-items-center'}>
                        <div className={'ms-4'}>
                          <h6 className={'text-bold gray-color-text'}>
                            {referee.name}
                          </h6>
                          <div className={'d-flex mt-2'}>
                            <h6 className={'mt-3 text-normal gray-color-text'}>
                              Review Pending
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
        <div className={`mt-3 ${styles.contentBox}`}>
          <Assessment
            hardSkillCategoryResults={selectedCandidate}
            hardSkillPercentage={Math.floor(
              (100 * (selectedCandidate?.assessment_results?.last_score ?? 0)) /
                (selectedCandidate?.assessment_results?.max_score ?? 1)
            )}
            softSkillCategoryResults={[]}
            candidate={selectedCandidate?.id ?? ''}
          />
        </div>
      </div>

      {reviewModal && (
        <CustomModal
          open={reviewModal}
          onCloseModal={(val: any) => setReviewModal(val)}
        >
          <ReviewModal
            response1={responses.response1}
            response2={responses.response2}
            response3={responses.response3}
            response4={responses.response4}
            additionalComments={responses.additionalComments}
          />
        </CustomModal>
      )}

      {modal && (
        <CustomModal
          open={modal}
          onCloseModal={(val: any) => {
            setModal(val)
          }}
          title={'Character limit exceeded'}
        >
          <div className={'d-flex align-items-center'}>
            <h5 className={'text-black ms-3 mb-5'}>
              The overview has exceeded the maximum character limit 1010. Please
              edit it in order to generate the BBB profile
            </h5>
          </div>
          <div className={'d-flex align-items-center justify-content-end'}>
            <CustomButton
              className={`ms-2 ${styles.Okbtn}`}
              text={'OK'}
              onClick={() => setModal(false)}
            />
          </div>
        </CustomModal>
      )}
      <ConfirmBannerModal
        onYesClick={() => handleDeleteCandidate(selectedCandidate?.id)}
        open={showDeleteConfirmationModal}
        onClose={(close) => {
          setShowDeleteConfirmationModal(close)
        }}
        title={'Delete Confirmation'}
        confirmMessage={'Are you sure you want to delete this candidate?'}
      />
      <ErrorBannerModal
        open={showErrorBanner}
        onClose={() => setShowErrorBanner(false)}
        key={`error-modal-candidate-${selectedCandidate?.id}`}
        errorMessage={error}
      />
      <SuccessBannerModal
        onClose={() => setShowResponseModal(false)}
        open={showResponseModal}
        key={`success-modal-candidate-${selectedCandidate?.id}`}
        Message={responseModalMessage}
      />
    </>
  )
}
