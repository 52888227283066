import React, { ReactNode, useEffect, useState } from 'react'

import Modal from 'react-modal'

import styles from './customModal.module.scss'
import CloseIcon from '../../../icons/close.icon'
import StarIcon from '../../../icons/star.icon'

export type CustomModalProps = {
  open: boolean
  onCloseModal: (value: boolean) => void
  children: ReactNode
  shouldCloseOnOverlayClick?: boolean
  shouldCloseOnEsc?: boolean
  hideCloseButton?: boolean
  className?: string
  customWidth?: boolean
  title?: string
  dashboard?: boolean
}

Modal.setAppElement('body')

const CustomModal: React.FC<CustomModalProps> = ({
  open = false,
  children,
  onCloseModal,
  shouldCloseOnOverlayClick = false,
  shouldCloseOnEsc = false,
  hideCloseButton = false,
  className,
  customWidth,
  title = '',
}) => {
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
    if (open && document) {
      document.body.setAttribute('style', 'overflow: hidden')
    }

    return () =>
      document && document.body.setAttribute('style', 'overflow: unset')
  }, [open])

  const handleClose = () => {
    setIsOpen(false)
    onCloseModal(false)
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="customModal"
      className={`${className} ${styles.customModal} ${
        customWidth ? styles.customWidth : null
      }`}
      overlayClassName={styles.modalOverlay}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <div className={styles.modal}>
        <div className={`d-flex justify-content-between align-items-center `}>
          {title === 'Welcome to the Technical Ability Assessment' ? (
            <div className={'d-flex align-items-center'}>
              <StarIcon />
              <div className={'ms-3'}>
                <h4 className={'text-almostBlack text-bolder'}>
                  Welcome to the Technical Ability Assessment
                </h4>
              </div>
            </div>
          ) : (
            <h3 className={'text-almostBlack text-bolder'}>{title}</h3>
          )}

          {!hideCloseButton && (
            <button
              type="button"
              className={styles.modalButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          )}
        </div>
        <div className={'mt-5'}>{children}</div>
      </div>
    </Modal>
  )
}

export default CustomModal
