import React, { useEffect, useState } from 'react'
import styles from '../allJobs/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import colors from '../../scss/variables.module.scss'
import color from '../../scss/variables.module.scss'
import FilterIcon from '../../icons/filter.icon'
import CustomDropdown, {
  DropdownDataList,
} from '../../shared/components/customDropdown/customDropdown'
import JobCard from '../../shared/components/jobCard/jobCard'
import SearchDropdown from '../../shared/components/customDropdown/searchDropdown'
import CustomModal from '../../shared/components/customModal/customModal'
import CustomButton from '../../shared/components/customButton/customButton'
import FilterModal, {
  JobFilters,
} from '../../shared/components/filterModal/filterModal'
import { getOrganizationCount } from '../../shared/services/organization.service'
import { getCandidateCount } from '../../shared/services/candidate.service'
import {
  getCloseJobCount,
  getInactiveJobCount,
  getJobCount,
  getJobs,
} from '../../shared/services/job.service'
import { HiringStage } from '../../shared/enums/hiring.enum'
import { getHiringStageOverview } from '../../shared/services/hiring.service'
import { JobDetails } from '../../shared/interfaces/job.interface'
import ArrowDownIcon from '../../icons/downArrow.icon'
import TabBox, {
  TabBoxButton,
  Tabs,
} from '../../shared/components/tabBox/tabBox'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import { JobStatus } from '../../shared/enums/job.enum'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CloseIcon from '../../icons/close.icon'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import _ from 'lodash'
import { Auth } from 'aws-amplify'

const activityDateFilters: DropdownDataList[] = [
  {
    value:
      new Date(new Date().getFullYear(), 6, 1) > new Date()
        ? new Date(new Date().getFullYear() - 1, 6, 1).toISOString()
        : new Date(new Date().getFullYear(), 6, 1).toISOString(),
    text: 'All',
  },
  {
    value: new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString(),
    text: 'Last 24 hours',
  },
  {
    value: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    text: 'Last 7 days',
  },
  {
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toISOString(),
    text: 'This month',
  },
  {
    value: new Date(
      new Date().getFullYear(),
      Math.floor(new Date().getMonth() / 3) * 3,
      1
    ).toISOString(),
    text: 'This quarter',
  },
  {
    value:
      new Date(new Date().getFullYear(), 6, 1) > new Date()
        ? new Date(new Date().getFullYear() - 1, 6, 1).toISOString()
        : new Date(new Date().getFullYear(), 6, 1).toISOString(),
    text: 'This financial year',
  },
]

function DashBoard() {
  const [searchParams] = useSearchParams()
  const [filterJobID] = useState(searchParams.get('id'))
  const [filter, setFilter] = useState(false)
  const navigate = useNavigate()
  const [selectedActivityDateFilter, setSelectedActivityDateFilter] =
    useState<string>(activityDateFilters[0].value)
  const [selectedActivityTextDateFilter, setSelectedActivityTextDateFilter] =
    useState<string>(activityDateFilters[0].text)
  const [totalJobCount, setTotalJobCount] = useState(0)
  const [totalClosedJobCount, setTotalClosedJobCount] = useState(0)
  const [totalInactiveJobCount, setTotalInactiveJobCount] = useState(0)
  const [filteredJobCount, setFilteredJobCount] = useState(-1)
  const [totalCandidateCount, setTotalCandidateCount] = useState(-1)
  const [filteredCandidateCount, setFilteredCandidateCount] = useState(-1)
  const [totalOrganizationCount, setTotalOrganizationCount] = useState(-1)
  const [filteredOrganizationCount, setFilteredOrganizationCount] = useState(-1)
  const [tab, setTab] = useState<string>('active jobs')

  const [hiringStagesCounts, setHiringStagesCounts] = useState<
    { stage: HiringStage; count: number }[]
  >([])

  const [jobs, setJobs] = useState<JobDetails[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchFilterJobs, setSearchFilterJobs] = useState<JobDetails[]>([])
  const [filterValue, setFilterValue] = useState<JobFilters>({})
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [preSearchData, setPreSearchData] = useState<any>({})
  const [preSearchPage, setPreSearchPage] = useState<number>(0)
  // eslint-disable-next-line
  const [preSearchDate, setPreSearchDate] = useState<string>()
  const [isDisableLoadMore, setIsDisableLoadMore] = useState<boolean>(false)
  const [hyperLinkType, setHyperLinkType] = useState<HiringStage>()
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      navigate('/signIn', { state: { id: filterJobID } })
    })
  }, [filterJobID, navigate])

  const handleTabChange = (tabName: string) => {
    if (tabName === 'complete jobs') {
      setFilterValue({
        status: JobStatus.Closed,
      })
    } else if (tabName === 'inactive jobs') {
      setFilterValue({
        status: JobStatus.Inactive,
      })
    } else {
      setFilterValue({})
    }
    setPage(1)
    setTab(tabName)
  }

  useEffect(() => {
    getJobCount()
      .then(setTotalJobCount)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    getCandidateCount()
      .then(setTotalCandidateCount)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    getOrganizationCount()
      .then(setTotalOrganizationCount)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    getHiringStageOverview()
      .then(setHiringStagesCounts)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [])

  useEffect(() => {
    getJobCount(selectedActivityDateFilter)
      .then(setFilteredJobCount)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    getCandidateCount(selectedActivityDateFilter)
      .then(setFilteredCandidateCount)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    getOrganizationCount(selectedActivityDateFilter)
      .then(setFilteredOrganizationCount)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    getCloseJobCount(selectedActivityDateFilter)
      .then(setTotalClosedJobCount)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    getInactiveJobCount(selectedActivityDateFilter)
      .then(setTotalInactiveJobCount)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [selectedActivityDateFilter, selectedActivityTextDateFilter])

  const fetchItems = async () => {
    try {
      setLoading(true)
      //check object is equal and check page number is qual
      if (
        _.isEqual(preSearchData, {
          ...(searchValue ? { search: searchValue } : {}),
          ...filterValue,
          ...{ date: selectedActivityDateFilter },
          ...(hyperLinkType ? { filterStageType: hyperLinkType } : {}),
        })
      ) {
        if (page !== preSearchPage) {
          setIsDisableLoadMore(false)
          const response = await getJobs({
            ...(searchValue ? { search: searchValue } : {}),
            ...(hyperLinkType ? { filterStageType: hyperLinkType } : {}),
            ...filterValue,
            page: `${page}`,
            fromDate: selectedActivityDateFilter,
          })
          const data = response
          if (data.length < 10) {
            setIsDisableLoadMore(true)
          }
          setSearchFilterJobs((prevItems) =>
            [...prevItems, ...data]
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id)
              )
              .filter((fjd: any) => fjd.id !== filterJobID)
              .sort((a: any, b: any) => b.created_at - a.created_at)
          )
          setPreSearchDate(selectedActivityDateFilter)
        }
      } else {
        setIsDisableLoadMore(false)
        setPage(1)
        setSearchFilterJobs([])
        const response = await getJobs({
          ...(searchValue ? { search: searchValue } : {}),
          ...(hyperLinkType ? { filterStageType: hyperLinkType } : {}),
          ...filterValue,
          page: `${Object.keys(filterValue).length !== 0 ? 1 : page}`,
          fromDate: selectedActivityDateFilter,
        })
        const data = response
        if (data.length < 10) {
          setIsDisableLoadMore(true)
        }
        setSearchFilterJobs(data.filter((fjd: any) => fjd.id !== filterJobID))
      }
      setPreSearchData({
        ...(searchValue ? { search: searchValue } : {}),
        ...(hyperLinkType ? { filterStageType: hyperLinkType } : {}),
        ...filterValue,
        ...{ date: selectedActivityDateFilter },
      })
      setPreSearchPage(page)
      setLoading(false)
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setLoading(false)
    }
  }

  const fetchOneItem = async (jobID: string) => {
    try {
      setLoading(true)
      setSearchFilterJobs([])
      const response = await getJobs({
        ...{ job_id: `${jobID}` },
        page: `${1}`,
      })
      const data = response
      setJobs(data)

      setLoading(false)
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (filterJobID) {
      fetchOneItem(filterJobID).then()
    }
    // eslint-disable-next-line
  }, [filterJobID])

  useEffect(() => {
    fetchItems().then((r) => r)
    // eslint-disable-next-line
  }, [
    page,
    searchValue,
    filterValue,
    selectedActivityDateFilter,
    hyperLinkType,
  ])

  useEffect(() => {
    fetchItems().then((r) => r)
    // eslint-disable-next-line
  }, [tab])

  const handleLoadMore = () => {
    if (!isDisableLoadMore) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  return (
    <div className={'w-100'}>
      <TopNavigation tabValue={'0'} />
      <div className={styles.contentDashboard}>
        <div className={styles.leftSideDashboard}>
          <div className={`mt-5 ${styles.greenCard}`}>
            <div className={styles.innerGreenCard}>
              <div
                className={'d-flex justify-content-between align-items-center'}
              >
                <h3 className={'text-success-color'}>New</h3>
                <div className={styles.filterDropDown}>
                  <CustomDropdown
                    dataList={activityDateFilters}
                    getSelectedItem={(item: any) => {
                      setSelectedActivityDateFilter(item.value)
                      setSelectedActivityTextDateFilter(item.text)
                    }}
                    selectedValue={activityDateFilters.find(
                      (f) => (
                        f.value === selectedActivityDateFilter,
                        f.text === selectedActivityTextDateFilter
                      )
                    )}
                  />
                </div>
              </div>
              <div
                className={
                  'd-flex justify-content-center justify-content-xl-between align-items-center mt-4'
                }
              >
                <div className={'pe-4'}>
                  <h6 className={'text-success-color'}>Roles</h6>
                  <h2
                    className={'text-success-500 d-flex justify-content-center'}
                  >
                    {filteredJobCount >= 0 ? filteredJobCount : '-'}
                  </h2>
                </div>
                <div className={styles.bordergreen}>
                  <h6 className={'text-success-color'}>Candidates</h6>
                  <h2
                    className={'text-success-500 d-flex justify-content-center'}
                  >
                    {filteredCandidateCount >= 0 ? filteredCandidateCount : '-'}
                  </h2>
                </div>
                <div className={'ps-4'}>
                  <h6 className={'text-success-color'}>Organisations</h6>
                  <h2
                    className={'text-success-500 d-flex justify-content-center'}
                  >
                    {filteredOrganizationCount >= 0
                      ? filteredOrganizationCount
                      : '-'}
                  </h2>
                </div>
              </div>
            </div>
            <div className={styles.innerCard}>
              <h3 className={'mt-4'}>Total</h3>
              <div
                className={
                  'd-flex justify-content-center justify-content-xl-between align-items-center mt-4'
                }
              >
                <div className={'pe-4'}>
                  <h6 className={'darkgray-text'}>Roles</h6>
                  <h2
                    className={'text-gray-light d-flex justify-content-center'}
                  >
                    {totalJobCount >= 0 ? totalJobCount : '-'}
                  </h2>
                </div>
                <div className={styles.bordergray}>
                  <h6 className={'darkgray-text'}>Candidates</h6>
                  <h2
                    className={'text-gray-light d-flex justify-content-center'}
                  >
                    {totalCandidateCount >= 0 ? totalCandidateCount : '-'}
                  </h2>
                </div>
                <div className={'ps-4'}>
                  <h6 className={'darkgray-text'}>Organisations</h6>
                  <h2
                    className={'text-gray-light d-flex justify-content-center'}
                  >
                    {totalOrganizationCount >= 0 ? totalOrganizationCount : '-'}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className={`mt-5 ${styles.blueCard}`}>
            <h3>Upcoming</h3>
            <div
              className={`mt-3 d-flex justify-content-between align-items-center ${styles.upcomingInnerBorder}`}
            >
              <h6 className={'primary-color-text'}>Culture Catch-Up</h6>
              <h6
                className={styles.hyperlinkStyle}
                onClick={() => setHyperLinkType(HiringStage.CultureCatchUp)}
              >
                {hiringStagesCounts.find(
                  (entry) => entry.stage === HiringStage.CultureCatchUp
                )?.count ?? '-'}
              </h6>
            </div>
            <div
              className={`mt-3 d-flex justify-content-between align-items-center ${styles.upcomingInnerBorder}`}
            >
              <h6 className={'primary-color-text'}>Interview</h6>
              <h6
                className={styles.hyperlinkStyle}
                onClick={() => setHyperLinkType(HiringStage.Interview)}
              >
                {hiringStagesCounts.find(
                  (entry) => entry.stage === HiringStage.Interview
                )?.count ?? '-'}
              </h6>
            </div>
            <div
              className={`mt-3 d-flex justify-content-between align-items-center ${styles.upcomingInnerBorder}`}
            >
              <h6 className={'primary-color-text'}>Reference Check</h6>
              <h6
                className={styles.hyperlinkStyle}
                onClick={() => setHyperLinkType(HiringStage.ReferenceCheck)}
              >
                {hiringStagesCounts.find(
                  (entry) => entry.stage === HiringStage.ReferenceCheck
                )?.count ?? '-'}
              </h6>
            </div>
            <div
              className={`mt-3 d-flex justify-content-between align-items-center ${styles.upcomingInnerBorder}`}
            >
              <h6 className={'primary-color-text'}>Negotiation</h6>
              <h6
                className={styles.hyperlinkStyle}
                onClick={() => setHyperLinkType(HiringStage.Negotiation)}
              >
                {hiringStagesCounts.find(
                  (entry) => entry.stage === HiringStage.Negotiation
                )?.count ?? '-'}
              </h6>
            </div>
            <div
              className={`mt-3 d-flex justify-content-between align-items-center`}
            >
              <h6 className={'primary-color-text'}>Paper Work</h6>
              <h6
                className={styles.hyperlinkStyle}
                onClick={() => setHyperLinkType(HiringStage.PaperWork)}
              >
                {hiringStagesCounts.find(
                  (entry) =>
                    entry.stage === HiringStage.PaperWork ||
                    entry.stage === HiringStage.Completed
                )?.count ?? '-'}
              </h6>
            </div>
          </div>
        </div>
        <div className={styles.rightContetSide}>
          <div
            className={` justify-content-between align-items-center ${styles.responsive}`}
          >
            <TabBox
              selectedTabName={tab}
              className={`mt-5 ${styles.tabWidth}`}
              onTabChange={handleTabChange}
            >
              <Tabs>
                <TabBoxButton tabName={'active jobs'}>
                  <h6 className={'gray-color-text'}>Active Roles</h6>
                  <div className={styles.jobCount}>
                    <h6 className={'white-text'}>{totalJobCount}</h6>
                  </div>
                </TabBoxButton>
                <TabBoxButton tabName={'complete jobs'}>
                  <h6>Completed Roles</h6>
                  <div className={styles.jobCount}>
                    <h6 className={'white-text'}>{totalClosedJobCount}</h6>
                  </div>
                </TabBoxButton>
                <TabBoxButton tabName={'inactive jobs'}>
                  <h6>Inactive Roles</h6>
                  <div className={styles.jobCount}>
                    <h6 className={'white-text'}>{totalInactiveJobCount}</h6>
                  </div>
                </TabBoxButton>
              </Tabs>
            </TabBox>
            <div
              className={`justify-content-end mt-3 mt-md-0 ${styles.responsive}`}
            >
              <div className={styles.dashboardSearch}>
                <SearchDropdown
                  dataList={[]}
                  placeHolder={'Role Title, Organisation Name'}
                  handleSearch={setSearchValue}
                />
              </div>
              <CustomButton
                onClick={() => setFilter(true)}
                icon={<FilterIcon color={colors.almostBlack} />}
                iconSide={'right'}
                text={'Filter'}
                className={`mt-2 mt-md-0 ${styles.mobileFilter}`}
              />
              <div
                className={`ms-3 ${styles.filterBtn}`}
                onClick={() => setFilter(true)}
              >
                <FilterIcon color={colors.almostBlack} />
              </div>
            </div>
          </div>
          <div className={'mt-5'}>
            {hyperLinkType && (
              <div className="d-flex flex-row">
                <h6>Filtered By: </h6>
                <div className={`d-flex flex-row ${styles.payableTag} mb-4`}>
                  <h6>{hyperLinkType.replace(/_/g, ' ')}</h6>
                  <span
                    className={'ps-2 pointer'}
                    onClick={() => setHyperLinkType(undefined)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              </div>
            )}
            {jobs.length > 0 && tab === 'active jobs' && (
              <JobCard
                navigateFromUrl={true}
                details={jobs[0]}
                disabled={jobs[0]?.status === 'closed'}
                key={`fxzjvcc`}
              />
            )}
            {searchFilterJobs.map((job, index) => (
              <JobCard
                details={job}
                disabled={job.status === 'closed'}
                key={`fxzjv${index}`}
              />
            ))}
            {loading && <LoadingSpinner />}
          </div>
          <span
            className="d-flex align-items-center mt-3 mb-5"
            onClick={() => {
              handleLoadMore()
            }}
            style={{ cursor: 'pointer' }}
          >
            <h6 className="me-4 mt-2 pointer text-tertiary-color">Load More</h6>
            <ArrowDownIcon color={color.teritaryColor500} />
          </span>
        </div>
      </div>
      {filter && (
        <CustomModal
          open={filter}
          onCloseModal={(val: any) => setFilter(val)}
          title={'Filters'}
        >
          <FilterModal
            onSubmit={setFilterValue}
            closeModal={() => setFilter(false)}
            initialVal={filterValue}
          />
        </CustomModal>
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default DashBoard
