import { useEffect, useMemo, useState } from 'react'
import styles from '../teams/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import { CandidateFilter } from './candidateFilter'
import {
  getCandidateDetails,
  getCandidateFiles,
} from '../../shared/services/hiring.service'
import LoadingService from '../../shared/services/loading.service'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import { getRefereeComments } from '../../shared/services/job.service'
import { updateStatus } from '../../shared/services/candidate.service'
import loadingStyles from '../../shared/components/customLoadingSpinner/loadingSpinner.module.scss'
import { UploadedFile } from '../../shared/types/file.model'
import { getMasterData } from '../../shared/services/masterData.service'
import {
  deleteCandidate,
  searchUsers,
} from '../../shared/services/user.service'
import { CandidateStatus } from '../../shared/interfaces/candidate.interface'
import { getUserRole } from '../../shared/services/auth.service'
import { UserRole } from '../../shared/enums/auth.enum'
import { Candidate } from './candidate'
import { Referees, UserWithCandidateInfo } from './types'

function Candidates() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )
  const [isActive, setIsActive] = useState<boolean>(false)
  const [referees, setReferees] = useState<Referees>([])
  const [status, setStatus] = useState<string>()

  const [selectedCandidate, setSelectedCandidate] = useState<
    UserWithCandidateInfo | undefined
  >(undefined)
  const [resume, setResume] = useState<UploadedFile | null>(null)
  const [coverLetter, setCoverLetter] = useState<UploadedFile | null>(null)

  const [reload, setReload] = useState(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false)
  const [showResponseModal, setShowResponseModal] = useState<boolean>(false)
  const [responseModalMessage, setResponseModalMessage] = useState<string>('')
  const [error, setError] = useState('')
  const [showErrorBanner, setShowErrorBanner] = useState(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const getMasterDataRank = async () => {
    try {
      const masterData = await getMasterData()
      return masterData
        .filter((a: any) => a?.category?.toString()?.endsWith('rank'))
        .map((md: any) => {
          return {
            ...md,
            qualification: md.category.split('_')[0],
          }
        })
    } catch (err: any) {
      setShowErrorBanner(true)
      setError(err.message)
    }
  }
  const loadCandidateData = (id: string) => {
    setReferees([])
    loadingService
      .await(getCandidateDetails(id))
      .then(async (details: any | null) => {
        if (details === null) return
        setStatus(details.status)
        loadingService
          .await(getRefereeComments(id))
          .then((res) => {
            if (res?.length > 0) {
              setReferees(res)
            }
          })
          .catch((err) => {
            setShowErrorBanner(true)
            setError(err.message)
          })
        const rank = await getMasterDataRank()
        const md = await getMasterData()
        details.qualifications.map((q: any) => {
          q.sortValue =
            rank?.filter((fd: any) => fd.qualification === q.qualification)[0]
              ?.value ?? 0
        })
        details.qualifications = details.qualifications.sort(
          (a: any, b: any) => b.sortValue - a.sortValue
        )
        details.certifications.map((q: any) => {
          q.certification =
            md
              .filter(
                (fd: any) => fd.category === 'licences_and_certifications'
              )
              .filter((fd: any) => fd.id === q.certification)[0]?.value ??
            q.certification
        })
        setSelectedCandidate(details)
        setIsActive(true)
        setReload(false)
      })
      .catch((err) => {
        setShowErrorBanner(true)
        setError(err.message)
      })
  }

  const handleSelectCandidate = async (candidate: any) => {
    setSelectedCandidate(undefined)
    setIsActive(true)
    setSelectedCandidate(candidate)
  }

  const onSubmit = async () => {
    setIsUpdating(true)
    if (status) {
      const res = await updateStatus(selectedCandidate!.id, status?.toString())
      if (res) {
        loadCandidateData(selectedCandidate!.id)
        setShowResponseModal(true)
        setResponseModalMessage(
          status === CandidateStatus.Active
            ? 'This candidate has been re-activated.'
            : 'This candidate has been made inactive.'
        )
      } else {
        setShowErrorBanner(true)
        setError('Something went wrong! Please try again.')
      }
    }
    setIsUpdating(false)
  }

  useEffect(() => {
    if (selectedCandidate) {
      setIsLoading(true)
      if (selectedCandidate.resume_key) {
        const resumeKey = selectedCandidate.resume_key?.split('/')
        const resumeUrl: any = {
          name: resumeKey[2],
          contents: '',
          type: 'url',
        }
        setResume(resumeUrl)
        setIsLoading(false)
      }

      if (selectedCandidate?.cover_letter_key) {
        const coverLetterKey = selectedCandidate.cover_letter_key?.split('/')
        const coverLetterUrl: any = {
          name: coverLetterKey[2],
          contents: '',
          type: 'url',
        }
        setCoverLetter(coverLetterUrl)
        setIsLoading(false)
      }
      setIsLoading(false)
    }
  }, [selectedCandidate, reload])

  const openResumePDFInNewTab = async () => {
    try {
      setIsLoading(true)
      if (selectedCandidate) {
        const params = {
          id: selectedCandidate.id,
          URL_Type: 'resume',
        }
        const url: any = await getCandidateFiles(params)
        const pdfUrl = url
        setIsLoading(false)
        window.open(pdfUrl, '_blank')
      }
    } catch (err: any) {
      setIsLoading(false)
      setShowErrorBanner(true)
      setError(err.message)
    }
  }

  const openCoverLetterPDFInNewTab = async () => {
    try {
      setIsLoading(true)
      if (selectedCandidate) {
        const params = {
          id: selectedCandidate.id,
          URL_Type: 'cover_letter',
        }
        const url: any = await getCandidateFiles(params)
        const pdfUrl = url
        setIsLoading(false)
        window.open(pdfUrl, '_blank')
      }
    } catch (err: any) {
      setIsLoading(false)
      setShowErrorBanner(true)
      setError(err.message)
    }
  }

  const handleDeleteCandidate = async (id: string) => {
    setIsLoading(true)
    if (id) {
      loadingService
        .await(deleteCandidate(id))
        .then((res) => {
          if (res) {
            setIsLoading(false)
            setShowDeleteConfirmationModal(false)
            window.location.reload()
          }
        })
        .catch(() => {
          setIsLoading(false)
          setShowErrorBanner(true)
          setError('Something went wrong! Please try again.')
        })
    } else {
      setIsLoading(false)
      setShowErrorBanner(true)
      setError('Something went wrong! Please try again.')
    }
  }

  const handleSearchUsers = async (term: string) => {
    const users = await searchUsers(term)
    return users
  }

  /**
   * Used to check if the user is a global admin, and if so, enable status update
   */
  useEffect(() => {
    getUserRole()
      .then((role) => {
        if (role === UserRole.GlobalAdmin) {
          setIsAdmin(true)
        }
      })
      .catch(() => {
        setShowErrorBanner(true)
        setError('Error getting user role')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      {isLoading && <LoadingSpinner />}
      <div className={'w-100'}>
        <TopNavigation tabValue={'2'} />
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <CandidateFilter
              handleSelectCandidate={handleSelectCandidate}
              searchUsers={handleSearchUsers}
            />
          </div>
          {selectedCandidate && (
            <Candidate
              status={status}
              setStatus={setStatus}
              showResponseModal={showResponseModal}
              setShowResponseModal={setShowResponseModal}
              showDeleteConfirmationModal={showDeleteConfirmationModal}
              setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
              showErrorBanner={showErrorBanner}
              setShowErrorBanner={setShowErrorBanner}
              referees={referees}
              isActive={isActive}
              isUpdating={isUpdating}
              isLoading={isLoading}
              isAdmin={isAdmin}
              resume={resume}
              coverLetter={coverLetter}
              selectedCandidate={selectedCandidate}
              responseModalMessage={responseModalMessage}
              error={error}
              loadCandidateData={loadCandidateData}
              handleSubmit={onSubmit}
              openCoverLetterPDFInNewTab={openCoverLetterPDFInNewTab}
              openResumePDFInNewTab={openResumePDFInNewTab}
              handleDeleteCandidate={handleDeleteCandidate}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Candidates
