import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import styles from '../../../shared/components/filterModal/styles.module.scss'
import { Form, Formik, FormikValues } from 'formik'
import CustomDropdown from '../customDropdown/customDropdown'
import CustomButton from '../customButton/customButton'
import RecycleBinIcon from '../../../icons/recycleBinIcon'
import CustomInputField from '../customInputField/customInputField'
import CustomImageUpload from '../customImageUpload/customImageUpload'
import * as Yup from 'yup'
import { Auth } from 'aws-amplify'
import {
  getUserDataById,
  getUserPreSignedUrl,
  getUserRole,
} from '../../services/user.service'
import { bbbTeamList } from '../../../pages/teams/addNewMember'
import { getMasterData } from '../../services/masterData.service'
import LoadingService from '../../services/loading.service'
import FileService from '../../services/file.service'
import { updateTeamMember } from '../../services/teams.service'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type CustomModalProps = {
  onCloseModal: (value: boolean) => void
}

function AccountProfile({ onCloseModal }: CustomModalProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [masterData, setMasterData] = useState<any>([])
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File | null>()
  const [bbbTeamSelected, setBbbTeamSelected] = useState<string>('')

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setLoading)
  }, [])

  const fileService: FileService = useMemo(() => {
    return new FileService()
  }, [])

  const [initialVal, setInitialVal] = useState(() => {
    return {
      id: '',
      name: '',
      phone: '',
      position: '',
      role: '',
      email: '',
      profile_url: '',
      team: '',
    }
  })

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required'),
    position: Yup.string().required('Position is required'),
    role: Yup.string().required('Role is required'),
    team: Yup.string().required('Team is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  })

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await Auth.currentSession()
      const token = res.getAccessToken().getJwtToken()
      const userData = await getUserDataById(token)
      const userRole = await getUserRole(token)

      if (userData) {
        let url = ''
        if (userData?.profile_image_key) {
          url = await getUserPreSignedUrl(userData.profile_image_key)
        }
        setInitialVal({
          id: userData.cognito_id,
          name: userData?.full_name,
          phone: userData.mobile_number,
          position: userData.position,
          role: userRole.role,
          email: userData.email,
          profile_url: url ?? '',
          team: userData?.team,
        })
        setBbbTeamSelected(
          bbbTeamList.find((item) => item.value === userData.team)?.text ?? ''
        )
        setLoading(false)
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    loadingService
      .await(getMasterData())
      .then(setMasterData)
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (value: FormikValues) => {
    setLoading(true)
    const res = await updateTeamMember({
      ...value,
      team: bbbTeamList.find((item) => item.value === value.team)?.value,
    })
    if (res) {
      onCloseModal(res)
      setLoading(false) // Disable loading immediately
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else {
      setErrorModel(true)
      setErrorMessage('Error')
      setLoading(false)
    }
    return
  }
  const roleList = [
    { value: 'admin', text: 'Admin' },
    { value: 'staff', text: 'Staff' },
    { value: 'manager', text: 'Manager' },
  ]
  return (
    <>
      {loading && <LoadingSpinner />}
      <Formik
        initialValues={initialVal}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ setFieldValue, values }) => (
          <Form className={styles.responsive}>
            <div>
              <div className={'mt-3 d-md-flex justify-content-between'}>
                <CustomImageUpload
                  id={'image'}
                  fileSelect={async (e: any) => {
                    setFile(e)
                    setFieldValue('file', await fileService.readFile(e))
                  }}
                  defaultFile={values.profile_url}
                />
                <div className={'ms-md-4 mt-3 mt-md-0'}>
                  <CustomInputField
                    name={'name'}
                    placeholder={'Name *'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('name', event.target.value)
                    }
                  />
                  <div className={`mt-3 d-md-flex`}>
                    <div
                      className={`ms-md-2 ${styles.levelDropDown}`}
                      style={{ zIndex: 3, position: 'relative' }}
                    >
                      <CustomDropdown
                        dataList={masterData
                          .filter((item: any) => item.category === 'positions')
                          .map((item: any) => ({
                            value: item.value,
                            text: item.value,
                          }))}
                        placeHolder={'Position *'}
                        onChange={(event: any) => {
                          setFieldValue('position', event)
                        }}
                        getSelectedItem={(select: any) => {
                          setFieldValue('position', select.value)
                        }}
                        selectedValue={{
                          value: values.position,
                          text: values.position,
                        }}
                        disabled={initialVal.role === 'staff'}
                      />
                    </div>
                    <div
                      className={`ms-md-2 mt-3 mt-md-0 ${styles.levelDropDown}`}
                      style={{ zIndex: 3, position: 'relative' }}
                    >
                      <CustomDropdown
                        dataList={roleList}
                        placeHolder={'Access Level *'}
                        onChange={(event: any) => {
                          setFieldValue('role', event)
                        }}
                        getSelectedItem={(select: any) => {
                          setFieldValue('role', select.value)
                        }}
                        selectedValue={{
                          value: values.role,
                          text: values.role,
                        }}
                        disabled={initialVal.role === 'staff'}
                      />
                      <div></div>
                    </div>
                  </div>

                  <div
                    className={`mt-3 ${styles.dropdownTeam}`}
                    style={{ zIndex: 2, position: 'relative' }}
                  >
                    <CustomDropdown
                      dataList={bbbTeamList}
                      placeHolder={' BBB team'}
                      onChange={(event: any) => {
                        setFieldValue('team', event)
                      }}
                      getSelectedItem={(select: any) => {
                        setFieldValue('team', select.value)
                        setBbbTeamSelected(select.text)
                      }}
                      selectedValue={{
                        value: bbbTeamSelected,
                        text: bbbTeamSelected,
                      }}
                      disabled={initialVal.role === 'staff'}
                    />
                  </div>
                  <div className={'mt-3 d-md-flex mb-4'}>
                    <CustomInputField
                      name={'phone'}
                      type={'number'}
                      placeholder={'Mobile Phone *'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('phone', event.target.value)
                      }
                    />

                    <CustomInputField
                      name={'email'}
                      placeholder={'Email *'}
                      className={'ms-1 mt-3 mt-md-0'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('email', event.target.value)
                      }
                      disabled={
                        initialVal.role === 'staff' ||
                        initialVal.role === 'admin'
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className={`d-flex justify-content-between align-items-center ${styles.borderTop}`}
              >
                <CustomButton
                  text={'Discard'}
                  icon={<RecycleBinIcon />}
                  iconSide={'left'}
                  className={styles.discardBtn}
                  onClick={() => onCloseModal(false)}
                />
                <div
                  className={
                    'd-flex justify-content-between align-items-center'
                  }
                >
                  <CustomButton
                    text={'Cancel'}
                    type={'button'}
                    className={styles.cancelBtn}
                    onClick={() => onCloseModal(false)}
                  />
                  <CustomButton
                    type={'submit'}
                    text={'Update'}
                    className={`ms-3 ${styles.searchBtn}`}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default AccountProfile
