export interface RoleType {
  id: number
  role: string
  created_at: string
}

export interface TeamMember {
  cognito_id: string
  full_name: string
  first_name: string
  last_name: string
  email: string
  position: string
  team: string
  linkedin_profile_image: string | null
  profile_image_key: string | null
  mobile_number: string | null
  status: string | null
  last_page: string | null
  created_at: string
  updated_at: string
  address: string | null
  __role__: RoleType
}

export enum UserTypes {
  Admin = 'admin',
  All = 'all',
  Staff = 'staff',
  Manager = 'manager',
  Other = 'other',
}
