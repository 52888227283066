import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import dayjs from 'dayjs'
import LoadingService from '../../services/loading.service'
import { getAssessmentResults } from '../../services/hiring.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

interface AssessmentCategoryResults {
  category: string
  percentage: number
}

const CATEGORY_ORDER = [
  'Bookkeeping Basics',
  'Debits & Credits',
  'Accounts Receivable',
  'Accounts Payable',
  'Bank Reconciliations',
  'Payroll',
  'Inventory',
  'Accruals & Prepayments',
  'Adjusting Entries',
  'Accounting Transactions & Entries',
  'Balance Sheet & P&L Statements',
  'Financial Statements',
  'Accounting Ratios',
  // These two are the same thing, but the DB value is currently 'Break Even' when it should be 'Break Even Analysis'
  'Break Even Analysis',
  'Break Even',
  'Statements of Cash Flow',
]

interface AssessmentResultProps {
  hardSkillPercentage: number
  hardSkillCategoryResults: AssessmentCategoryResults[]
  softSkillPercentage: number
  softSkillCategoryResults: AssessmentCategoryResults[]
  candidate: string
}

function AssessmentResult({
  hardSkillPercentage,
  hardSkillCategoryResults,
  candidate,
}: AssessmentResultProps) {
  const [assessmentCompletedTime, setAssessmentCompletedTime] = useState('')
  const [assessmentDate, setAssessmentDate] = useState('')
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(() => {}),
    []
  )

  // Function to format the duration
  const formatDuration = (
    hours: number,
    minutes: number,
    seconds: number
  ): string => {
    const parts = []

    if (hours > 0) {
      parts.push(`${hours} hour${hours > 1 ? 's' : ''}`)
    }
    if (minutes > 0 || parts.length > 0) {
      parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
    }
    if (seconds > 0 || parts.length === 0) {
      parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`)
    }

    return parts.join(' ')
  }

  const assessmentTime = useCallback(() => {
    const params = {
      candidateId: candidate,
    }
    loadingService
      .await(getAssessmentResults(params))
      .then((res: any) => {
        if (res !== null && res.status === 'success') {
          if (res.result.duration === null) {
            setAssessmentCompletedTime('')
            setAssessmentDate(dayjs(res.result.start_time).format('DD-MM-YYYY'))
          }
          const durationObject = JSON.parse(res.result.duration)
          if (durationObject && durationObject.duration) {
            const { hours, minutes, seconds } = JSON.parse(
              durationObject.duration
            )

            // Format the duration
            const formattedDuration = formatDuration(hours, minutes, seconds)
            setAssessmentCompletedTime(formattedDuration ?? '')
            setAssessmentDate(dayjs(res.result.start_time).format('DD-MM-YYYY'))
          } else {
            setAssessmentCompletedTime('')
            setAssessmentDate(dayjs(res.result.start_time).format('DD-MM-YYYY'))
          }
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate, loadingService])

  useEffect(() => {
    assessmentTime()
  }, [assessmentTime])

  return (
    <div className={styles.assessmentResult}>
      <div className={styles.greenBorderBottom}>
        <div className={'mt-3'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <h5>Technical Ability Assessment</h5>
          </div>
          <div className={'ps-4 pe-4 pt-2 pb-2'}>
            {hardSkillCategoryResults
              .sort(
                (a, b) =>
                  CATEGORY_ORDER.indexOf(a.category) -
                  CATEGORY_ORDER.indexOf(b.category)
              )
              ?.map((result, index) => (
                <div
                  className={
                    'd-flex justify-content-between align-items-center'
                  }
                  key={index}
                >
                  <p className={'gray-color-text text-normal'}>
                    {result.category}
                  </p>
                  <p className={'text-success'}>{result.percentage}%</p>
                </div>
              ))}
          </div>
          {/*}*/}
        </div>
      </div>
      <div className={`mt-2 ${styles.average}`}>
        <div className={'d-flex justify-content-between align-items-center'}>
          <div>
            <h3>Average</h3>
            <h6 className={'gray-color-text text-normal'}>Score</h6>
          </div>
          <h1 className={'text-success-color'}>{hardSkillPercentage}%</h1>
        </div>
      </div>
      {assessmentCompletedTime && (
        <div className="d-flex justify-content-center mt-3 mb-2">
          <div className={'d-flex flex-column'}>
            <h6>Time taken to complete assessment</h6>
            <h6
              className={`d-flex justify-content-center text-bold text-success`}
            >
              {assessmentCompletedTime}
            </h6>
          </div>
        </div>
      )}
      {assessmentCompletedTime && assessmentDate && (
        <div className="d-flex justify-content-center mt-3 mb-2">
          <div className={'d-flex flex-column'}>
            <h6>Assessment completed date</h6>
            <h6
              className={`d-flex justify-content-center text-bold text-success`}
            >
              {assessmentDate}
            </h6>
          </div>
        </div>
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default AssessmentResult
