import React, { useEffect, useState } from 'react'
import CustomModal from '../customModal/customModal'
import CustomButton from '../customButton/customButton'
import styles from './styles.module.scss'

type ErrorBannerModalProps = {
  open: boolean
  onClose: (val: boolean) => void
  onYesClick?: () => void
  confirmMessage?: string
  title?: string
}

function ConfirmBannerModel({
  open,
  onClose,
  confirmMessage,
  onYesClick,
  title,
}: ErrorBannerModalProps) {
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    setOpenModal(open)
  }, [open])

  return (
    <CustomModal
      open={openModal}
      onCloseModal={() => {
        setOpenModal(false)
        onClose(false)
      }}
      title={title}
    >
      <div>
        <div className={'d-flex align-items-center'}>
          <h5 className={'text-black ms-3 mb-5'}>{confirmMessage}</h5>
        </div>

        <div className={'d-flex align-items-center justify-content-end'}>
          <CustomButton
            text={'No'}
            className={styles.cancelButton}
            onClick={() => onClose(false)}
          />

          <CustomButton
            className={`ms-2 ${styles.addBtn}`}
            text={'Yes'}
            onClick={onYesClick}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default ConfirmBannerModel
