import styles from '../../../pages/dashboard/Backdrop.module.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import LoadingService from '../../services/loading.service'
import { getAssessmentResults } from '../../services/hiring.service'
import dayjs from 'dayjs'
import styles2 from './styles.module.scss'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import AssessmentResultIcon from '../../../icons/assessmentResultIcon'
import colors from '../../../scss/variables.module.scss'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import { CSVLink } from 'react-csv'

interface AssessmentCategoryResults {
  category: string
  percentage: number
}

interface AssessmentResultProps {
  hardSkillPercentage?: number
  hardSkillCategoryResults: any
  softSkillPercentage?: number
  softSkillCategoryResults?: AssessmentCategoryResults[]
  candidate: string
}

const CATEGORY_ORDER = [
  'Bookkeeping Basics',
  'Debits & Credits',
  'Accounts Receivable',
  'Accounts Payable',
  'Bank Reconciliations',
  'Payroll',
  'Inventory',
  'Accruals & Prepayments',
  'Adjusting Entries',
  'Accounting Transactions & Entries',
  'Balance Sheet & P&L Statements',
  'Financial Statements',
  'Accounting Ratios',
  'Break Even Analysis',
  'Break Even',
  'Statements of Cash Flow',
]

const Assessment = ({
  hardSkillPercentage,
  hardSkillCategoryResults,
  candidate,
}: AssessmentResultProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [assessmentCompletedTime, setAssessmentCompletedTime] = useState('')
  const [assessmentDate, setAssessmentDate] = useState('')
  const [data, setData] = useState<any>()
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // Function to format the duration
  const formatDuration = (
    hours: number,
    minutes: number,
    seconds: number
  ): string => {
    const parts = []

    if (hours > 0) {
      parts.push(`${hours} hour${hours > 1 ? 's' : ''}`)
    }
    if (minutes > 0 || parts.length > 0) {
      parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
    }
    if (seconds > 0 || parts.length === 0) {
      parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`)
    }

    return parts.join(' ')
  }

  const assessmentTime = useCallback(() => {
    const params = {
      candidateId: candidate,
    }
    loadingService
      .await(getAssessmentResults(params))
      .then((res: any) => {
        if (res !== null && res?.status === 'success') {
          if (res.result.duration === null) {
            setAssessmentCompletedTime('')
            setAssessmentDate(dayjs(res.result.start_time).format('DD-MM-YYYY'))
          }
          const durationObject = JSON.parse(res.result.duration)
          if (durationObject && durationObject.duration) {
            const { hours, minutes, seconds } = JSON.parse(
              durationObject.duration
            )

            // Format the duration
            const formattedDuration = formatDuration(hours, minutes, seconds)
            setAssessmentCompletedTime(formattedDuration ?? '')
            setAssessmentDate(dayjs(res.result.start_time).format('DD-MM-YYYY'))
            setData(res.result)
          } else {
            setAssessmentCompletedTime('')
            setAssessmentDate(dayjs(res.result.start_time).format('DD-MM-YYYY'))
            setData(res.result)
          }
        } else {
          setData([])
          setAssessmentCompletedTime('')
          setAssessmentDate(dayjs(res?.result?.start_time).format('DD-MM-YYYY'))
        }
      })
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate, loadingService])

  useEffect(() => {
    assessmentTime()
  }, [assessmentTime])
  const csvData = [['Question', 'Answer'], ,]
  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={styles.assesmentResult}>
        <div className={'mt-3'}>
          <div
            className={`d-flex align-items-center justify-content-between pb-3 ${styles.borderBottom}`}
          >
            <div className={'d-flex align-items-center mb-3'}>
              <AssessmentResultIcon color={colors.blackColor} />
              <h4 className={'text-almostBlack ms-3 text-bolder'}>
                Technical Ability Assessment
              </h4>
            </div>

            <div>
              <CSVLink
                filename={`${hardSkillCategoryResults.name} Assessment Results`}
                className={`mt-2 mt-md-0 ${styles.borderBtn} ${styles.linkButton} ${styles.pdfButton}`}
                data={data?.freeform_answers ?? csvData}
              >
                Export assessment results
              </CSVLink>
            </div>
          </div>
        </div>
        <div className={'ps-4 pe-4 pt-2 pb-2'}>
          <div className={styles.responsive}>
            <div className={' w-75'}>
              <div className={'ps-4 pe-4 pt-2 pb-2 d-flex'}>
                <div className={'w-100'}>
                  {data &&
                    data?.category_results
                      ?.sort(
                        (a: any, b: any) =>
                          CATEGORY_ORDER.indexOf(a.category) -
                          CATEGORY_ORDER.indexOf(b.category)
                      )
                      ?.map((result: any, index: any) => (
                        <div className={'w-100 d-flex'} key={index}>
                          <h6 className="mr-3 text-normal ms-3 w-100">
                            {result.category}
                          </h6>
                          <h6 className="mr-3 text-normal ms-3 w-50">
                            {Math.floor(
                              (100 * result.last_score) / result.max_score
                            )}
                            %
                          </h6>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            <div className={styles.subResponsive}>
              <div className={'w-100  justify-content-end'}>
                <div className={`mt-2 ${styles2.averageNew}`}>
                  <div
                    className={
                      'd-flex justify-content-between align-items-center h-100'
                    }
                  >
                    <div>
                      <h3>Average</h3>
                      <h6 className={'gray-color-text text-normal'}>Score</h6>
                    </div>
                    <h1 className={'text-text-normal'}>
                      {hardSkillPercentage}%
                    </h1>
                  </div>
                </div>
                {assessmentCompletedTime && (
                  <div className={`mt-2 ${styles2.averageNew} `}>
                    <div className="d-flex justify-content-center mt-3 mb-2 ">
                      <div className={'d-flex flex-column'}>
                        <h6 className={'text-center'}>
                          Time taken to complete assessment
                        </h6>
                        <h6
                          className={`d-flex justify-content-center text-bold text-normal`}
                        >
                          {assessmentCompletedTime}
                        </h6>
                      </div>
                    </div>
                    {assessmentCompletedTime && assessmentDate && (
                      <div className="d-flex justify-content-center mt-3 mb-2">
                        <div className={'d-flex flex-column'}>
                          <h6 className={'text-center'}>
                            Assessment completed date
                          </h6>
                          <h6
                            className={`d-flex justify-content-center text-bold text-normal`}
                          >
                            {assessmentDate}
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default Assessment
