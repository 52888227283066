import React, { useMemo } from 'react'
import styles from './../../shared/components/filterModal/styles.module.scss'
import { Form, Formik } from 'formik'
import CustomDropdown, {
  DropdownDataList,
} from '../../shared/components/customDropdown/customDropdown'
import CustomButton from '../../shared/components/customButton/customButton'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import CustomInputField from '../../shared/components/customInputField/customInputField'
import { HardSkillsAssessmentQuestion } from '../../shared/interfaces/assessment.interface'
import { HardSkillsAssessmentQuestionType } from '../../shared/enums/assessment.enum'
import * as Yup from 'yup'

interface AddNewQuestionModalProps {
  question: HardSkillsAssessmentQuestion | null
  categories: DropdownDataList[]
  onSubmit: (question: HardSkillsAssessmentQuestion) => void
  closeModal: () => void
}

interface AddNewQuestionFormValues {
  text: string
  category: string
  answers: { text: string; correct: boolean }[]
  newAnswer?: string
}

function AddNewQuestion({
  question,
  categories,
  onSubmit,
  closeModal,
}: AddNewQuestionModalProps) {
  const validationSchema = Yup.object().shape({
    text: Yup.string().required('Question text is required'),
    category: Yup.string().required('Category is required'),
    answers: Yup.array()
      .min(3, 'At least 3 answers are required')
      .test('correctness', 'There must be one correct answer', (value) => {
        return !value || value.filter((v: any) => v.correct).length === 1
      }),
  })

  // Set initial values for the form
  // Note that question = null when adding a new question
  const initialVal: AddNewQuestionFormValues = useMemo(() => {
    return {
      text: question?.text ?? '',
      category: question?.category ?? '',
      answers: question?.answers ?? [],
    }
  }, [question])

  /**
   * Handles the submission of the form
   * @param values The form values
   * @returns void
   */
  const handleSubmit = (values: AddNewQuestionFormValues): void => {
    onSubmit({
      ...values,
      id: question?.id ?? '',
      points: 1,
      type: HardSkillsAssessmentQuestionType.MultipleChoice,
      answers: values.answers.map((a) => ({ ...a, id: '' })),
    })
  }

  /**
   * Handles selecting a new answer as the correct one
   * @param index The index of the answer to be selected
   * @param values The form values
   * @param setFieldValue The formik setFieldValue function
   * @returns void
   */
  const handleSelectAnswer = (
    index: number,
    values: AddNewQuestionFormValues,
    setFieldValue: (field: string, value: any) => void
  ): void => {
    setFieldValue(
      'answers',
      values.answers.map((a, i) => ({ ...a, correct: i === index }))
    )
  }

  /**
   * Adds a new answer to the form
   * @param values The form values
   * @param setFieldValue The formik setFieldValue function
   * @returns void
   */
  const addNewAnswer = (
    values: AddNewQuestionFormValues,
    setFieldValue: (field: string, value: any) => void
  ): void => {
    setFieldValue('answers', [
      ...values.answers,
      { text: values.newAnswer ?? '', correct: false },
    ])
    setFieldValue('newAnswer', '')
  }
  return (
    <Formik
      initialValues={initialVal}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <div>
            <CustomInputField
              name={'text'}
              placeholder={'Question *'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('text', e.target.value)
              }
            />
            <div className={`mt-2 `}>
              <CustomDropdown
                dataList={categories}
                placeHolder={'Category *'}
                selectedValue={categories.find(
                  (c) =>
                    c.value === values.category || c.text === values.category
                )}
                getSelectedItem={(item: any) =>
                  setFieldValue('category', item.value)
                }
              />
              {errors.category && touched.category ? (
                <div className={styles.yupError}>{errors.category}</div>
              ) : null}
            </div>
            <div className={'d-flex mt-3 mb-3'}>
              <h6>Answers</h6>
              <div className={'d-flex justify-content-evenly flex-wrap w-100'}>
                {values.answers.map((val, index) => (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      id="answerInput"
                      value={index}
                      onChange={() =>
                        handleSelectAnswer(index, values, setFieldValue)
                      }
                      checked={val.correct}
                    />
                    <label className="form-check-label" htmlFor="answerInput">
                      <h6 className={'text-normal gray-color-text'}>
                        {val.text}
                      </h6>
                    </label>
                    <div className={'ms-2 pointer'}>
                      <RecycleBinIcon size={15} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {errors.answers && touched.answers ? (
              <div className={styles.yupError}>{errors.answers as string}</div>
            ) : null}
            <div className={'mt-2 ms-5 ps-5 mb-3 d-flex'}>
              {values.answers.length <= 0 ? (
                <CustomInputField
                  name={'newAnswer'}
                  placeholder={'Add Answer'}
                  className={styles.dropdownWidth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('newAnswer', e.target.value)
                  }
                />
              ) : (
                <CustomInputField
                  name={'newAnswer'}
                  placeholder={'Add Another Answer'}
                  className={styles.dropdownWidth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('newAnswer', e.target.value)
                  }
                />
              )}
              <CustomButton
                className={'ms-2'}
                text={'Add Answer'}
                disabled={!values.newAnswer}
                onClick={() => addNewAnswer(values, setFieldValue)}
              />
            </div>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center ${styles.borderTop}`}
          >
            <CustomButton
              text={'Discard'}
              icon={<RecycleBinIcon />}
              iconSide={'left'}
              className={styles.discardBtn}
              onClick={closeModal}
            />
            <div
              className={'d-flex justify-content-between align-items-center'}
            >
              <CustomButton
                text={question === null ? 'Add Question' : 'Update Question'}
                className={`ms-3 ${styles.searchBtn}`}
                type={'submit'}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddNewQuestion
