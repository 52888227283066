import React, { useEffect, useMemo, useState } from 'react'
import { Form, Formik } from 'formik'
import styles from './styles.module.scss'
import CustomButton from '../customButton/customButton'
import UploadIcon from '../../../icons/upload.icon'
import colors from '../../../scss/variables.module.scss'
import RightLongArrowIcon from '../../../icons/rightLongArrow.icon'
import {
  CandidateDetails,
  CultureCatchUpAnswerType,
  CultureCatchUpType,
} from '../../interfaces/candidate.interface'
import LoadingService from '../../services/loading.service'
import {
  answerCatchUpQuestion,
  getAnswersForQuestions,
  getCandidateCultureCatchUpQuestions,
} from '../../services/candidate.service'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import loadingStyles from '../customLoadingSpinner/loadingSpinner.module.scss'
import errorStyle from '../../components/filterModal/styles.module.scss'
import { HiringStage } from '../../enums/hiring.enum'
import * as Yup from 'yup'
import {
  AddToSupplyListType,
  HiringProcess,
} from '../../interfaces/hiring.interface'
import {
  addToSupplyList,
  updateHiringProcess,
  updateHiringStepDate,
} from '../../services/hiring.service'
import SuccessBannerModal from '../errorBannerModal/successBannerModal'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

interface CandidateProps {
  tab: string
  candidate: CandidateDetails | null
  hiringData: HiringProcess | null
  changePage: (page: string) => void
  onStageChanged?: (updated: boolean) => void
  onUpdateHiringData?: (hiringData: HiringProcess) => void
  changedProcessPage?: (page: HiringStage) => void
  setPage?: (page: HiringStage) => void
  supplyStatus: boolean
  isClosed?: boolean
  setSupplyStatus?: (status: boolean) => void
}

type CultureCatchUpQuestionAnswerType = {
  id: string
  user_id: string
  hiring_process_id: string
  question_id: string
  question: string
  answer: string
  created_at: Date
  updated_at: Date
}

interface QuestionSchema {
  [key: string]: Yup.StringSchema
}

function CandidateCatchUp({
  candidate,
  hiringData,
  onUpdateHiringData,
  onStageChanged,
  setPage,
  supplyStatus,
  isClosed,
  setSupplyStatus,
}: CandidateProps) {
  const [questions, setQuestions] = useState<CultureCatchUpType[]>([])
  const [answeredQuestions, setAnsweredQuestions] = useState<
    CultureCatchUpQuestionAnswerType[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [savedDate, setSavedDate] = useState<string>('')
  const [responseModal, setResponseModal] = useState<boolean>(false)
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [responseModalMessage, setResponseModalMessage] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [textareaValues, setTextareaValues] = useState<{
    [key: string]: string
  }>({})

  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )

  useEffect(() => {
    setAnsweredQuestions([])
    setQuestions([])
    loadingService
      .await(getCandidateCultureCatchUpQuestions().then(setQuestions))
      .catch((error: any) => {
        setErrorModal(true)
        setResponseModalMessage(error.message)
      })
    if (hiringData && candidate) {
      const params = {
        hiringProcessId: hiringData.id,
        candidateId: candidate.id,
      }
      loadingService
        .await(getAnswersForQuestions(params).then(setAnsweredQuestions))
        .catch((error: any) => {
          setErrorModal(true)
          setResponseModalMessage(error.message)
        })
      setSavedDate(hiringData.culture_catchup_date ?? '')
    }
  }, [hiringData, loadingService, candidate])

  const initialVal = useMemo(() => {
    // Create initial values object using questions
    const initialValues: { [key: string]: string } = {}
    questions.forEach((question) => {
      const answeredQuestion = answeredQuestions.find(
        (ans) => ans.question_id === question.id
      )
      initialValues[question.id] = answeredQuestion
        ? answeredQuestion.answer
        : ''
    })
    initialValues.savedDate = hiringData?.culture_catchup_date ?? ''
    return initialValues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, answeredQuestions])

  const validationSchema = Yup.object().shape({
    ...questions.reduce((schema: QuestionSchema, question) => {
      schema[question.id] = Yup.string().required('Answer is required')
      return schema
    }, {}),
    savedDate: Yup.date().required('Date & time is required.'),
  })

  const handleSubmit = async (values: { [key: string]: string }) => {
    setIsLoading(true)
    if (!candidate || !hiringData) {
      setIsLoading(false)
      setErrorModal(true)
      setResponseModalMessage('Candidate or hiring data id is missing')
      return
    }
    const data: CultureCatchUpAnswerType[] = questions.map((question) => ({
      answer_text: values[question.id],
      question_id: question.id,
      hiring_process_id: hiringData.id ?? '',
      user_id: candidate.id ?? '',
    }))

    // Perform the API call or any other operation with the data
    if (data.length) {
      const res = await answerCatchUpQuestion(data)
      const nextStage = HiringStage.Interview
      if (res) {
        setIsLoading(false)
        loadingService
          .await(
            updateHiringProcess(
              {
                candidate_id: candidate?.id ?? '',
                job_id: hiringData?.job_id ?? '',
              },
              { stage: nextStage, hiringProcessId: hiringData.id }
            )
          )
          .then((res) => {
            if (res) {
              if (onUpdateHiringData) {
                onUpdateHiringData({
                  ...hiringData,
                  stage: nextStage,
                  culture_catchup_date: savedDate ?? '',
                })
              }
              if (setPage) {
                setPage(nextStage)
              }
              if (onStageChanged) {
                onStageChanged(true)
              }
            } else {
              setErrorModal(true)
              setResponseModalMessage('Update Error')
            }
          })
      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    questionId: string
  ) => {
    const { value } = event.target
    setTextareaValues((prevValues) => ({
      ...prevValues,
      [questionId]: value, // Store the answer text with the question id in the textareaValues state
    }))
  }

  const updateRequestedTime = async () => {
    setIsLoading(true)
    try {
      if (savedDate && hiringData) {
        const params = {
          hiringProcessId: hiringData.id,
          date: savedDate,
        }
        const res = await updateHiringStepDate(params)
        if (res) {
          setIsLoading(false)
          if (onStageChanged) {
            onStageChanged(true)
          }
          if (onUpdateHiringData) {
            onUpdateHiringData({
              ...hiringData,
              culture_catchup_date: savedDate,
            })
          }
        } else {
          setErrorModal(true)
          setIsLoading(false)
          setResponseModalMessage('Something went wrong when updating the date')
        }
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setErrorModal(true)
      setResponseModalMessage('Something went wrong when updating the date')
    }
  }

  const handleAddToSupply = async (
    values: { [key: string]: string },
    setFieldError: any
  ) => {
    if (values) {
      setIsLoading(true)
      if (!candidate || !hiringData) {
        setIsLoading(false)
        return
      }

      const data: any[] = questions
        .map((question) => {
          const answerText = values[question.id]
          if (answerText !== '') {
            return {
              answer_text: answerText,
              question_id: question.id,
              hiring_process_id: hiringData.id ?? '',
              user_id: candidate.id ?? '',
            }
          }
          return null
        })
        .filter((item) => item !== null)

      // Perform the API call or any other operation with the data
      if (data.length) {
        try {
          await answerCatchUpQuestion(data)
          setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
          setErrorModal(true)
          setResponseModalMessage(
            'Something went wrong when answering the questions'
          )
        }
      }
    }
    setIsLoading(true)
    if (!hiringData || !hiringData.id) return
    const data: AddToSupplyListType = {
      hiringProcessId: hiringData.id ?? '',
      stage: hiringData?.stage,
    }
    if (data) {
      const res = await addToSupplyList(data)
      if (res) {
        setIsLoading(false)
        setResponseModal(true)
        setResponseModalMessage(
          'This candidate has been marked as unsuccessful.'
        )
        if (setSupplyStatus) {
          setSupplyStatus(true)
        }
        // Clear the validation error
        questions.forEach((question) => {
          setFieldError(question.id, undefined)
        })
      } else {
        setIsLoading(false)
        setErrorModal(true)
        setResponseModalMessage(
          'Something went wrong when making the candidate unsuccessful.'
        )
      }
    } else {
      setIsLoading(false)
      setErrorModal(true)
      setResponseModalMessage(
        'Something went wrong when making the candidate unsuccessful.'
      )
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik
          initialValues={initialVal}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, errors, handleChange, setFieldError, setFieldValue }) => (
            <Form className={'d-flex flex-column'}>
              <div className={`mt-4 pb-3 ${styles.borderBottom}`}>
                <h6>Date & Time</h6>
                <div className={'d-flex mt-3'}>
                  <input
                    name="savedDate"
                    disabled={isClosed}
                    type={'datetime-local'}
                    className={styles.dateTime}
                    onChange={(e) => {
                      setSavedDate(e.target.value)
                      setFieldValue('savedDate', e.target.value)
                    }}
                    value={values.savedDate || ''}
                  />
                </div>
                {errors.savedDate && (
                  <div className={errorStyle.yupError}>{errors.savedDate}</div>
                )}
                <div className={'mt-3'}>
                  <CustomButton
                    disabled={isClosed}
                    text={'Save'}
                    onClick={() => updateRequestedTime()}
                    className={styles.outlinedBtn}
                  />
                </div>
              </div>
              <div className={'d-flex w-100 justify-content-between'}>
                <div className={'mt-4 w-100'}>
                  <h6>Questions & Feedback</h6>
                  {questions.map((value, index) => (
                    <div className={'mt-5'} key={`questions-main-div-${index}`}>
                      <h6 className={'gray-color-text mb-2'}>
                        <span>{`${index + 1}.`} </span>
                        {value.text}
                      </h6>
                      <textarea
                        disabled={isClosed || supplyStatus}
                        className={styles.textAreaCatchUp}
                        name={value.id}
                        value={values[value.id] || ''} // Use Formik's values instead of textareaValues
                        onChange={(e) => {
                          handleTextareaChange(e, value.id)
                          handleChange(e) // Update Formik's state
                        }}
                      />
                      <p className={errorStyle.yupError}>{errors[value.id]}</p>
                    </div>
                  ))}
                  <div
                    className={`justify-content-between align-items-center mt-5 ${styles.responsive}`}
                  >
                    <CustomButton
                      className={styles.supplyBtn}
                      text={'Unsuccessful'}
                      icon={<UploadIcon color={colors.blackColor} />}
                      iconSide={'left'}
                      onClick={() => {
                        handleAddToSupply(values, setFieldError)
                      }}
                      disabled={supplyStatus || isClosed}
                    />
                    <CustomButton
                      type={'submit'}
                      className={`mt-2 mt-md-0 ${styles.widthBtn}`}
                      text={'Move to Interview'}
                      icon={<RightLongArrowIcon />}
                      iconSide={'right'}
                      disabled={supplyStatus || isClosed}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <SuccessBannerModal
          onClose={() => setResponseModal(false)}
          open={responseModal}
          Message={responseModalMessage}
        />
        <ErrorBannerModal
          onClose={() => setErrorModal(false)}
          open={errorModal}
          errorMessage={responseModalMessage}
        />
      </div>
    </>
  )
}

export default CandidateCatchUp
