import React, { useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import DegreeCapIcon from '../../../icons/degreeCap.icon'
import DegreeIcon from '../../../icons/degree.icon'
import CalenderIcon from '../../../icons/calender.icon'
import colors from '../../../scss/variables.module.scss'
import dayjs from 'dayjs'
import LoadingService from '../../services/loading.service'
import { getMasterData } from '../../services/masterData.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

interface EducationProps {
  qualifications?: {
    qualification: string
    institution: string
    field_of_study: string
    start_date: string
    end_date: string
  }[]
}

function Education({ qualifications }: EducationProps) {
  const [qualificationLevels, setQualificationLevels] = useState<any[]>([])
  const [masterData, setMasterData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  useEffect(() => {
    loadingService
      .await(getMasterData())
      .then((res) => {
        setMasterData(res)
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [loadingService])

  useEffect(() => {
    if (masterData) {
      setQualificationLevels(
        masterData.filter((fd: any) => fd.category === 'qualification')
      )
    }
  }, [masterData])

  return (
    <div style={{ opacity: isLoading ? 0.5 : 1 }}>
      <div className={`d-flex align-items-center justify-content-between`}>
        <div className={'d-flex align-items-center mb-3'}>
          <DegreeCapIcon color={colors.blackColor} />
          <h4 className={'text-almostBlack ms-3 text-bolder'}>
            Australian Education
          </h4>
        </div>
      </div>
      {(qualifications ?? []).map((qualification, index) => (
        <div className={styles.educationCard} key={index}>
          <div className={'d-flex'}>
            <div className={'d-flex align-items-center'}>
              <DegreeIcon />
            </div>
            <div className={'ms-4'}>
              <h6 className={'text-bold gray-color-text'}>
                {qualificationLevels?.find(
                  (fd: any) => fd.id === qualification.qualification
                )?.value ?? qualification.qualification}
              </h6>
              <h6
                className={
                  'text-normal text font-size-semi gray-color-text mt-2'
                }
              >
                {qualification.field_of_study}
              </h6>
              <h6
                className={
                  'text-normal text font-size-semi gray-color-text mt-2'
                }
              >
                {qualification.institution}
              </h6>
              <div className={'d-flex mt-2'}>
                {new Date(qualification.start_date).getFullYear() !== 1970 ||
                new Date(qualification.end_date).getFullYear() !== 1970 ? (
                  <>
                    <CalenderIcon />
                    <h6 className={'ms-2 gray-color-text'}>
                      {new Date(qualification.start_date).getFullYear() === 1970
                        ? dayjs(qualification.end_date).format('MMM YYYY')
                        : new Date(qualification.end_date).getFullYear() ===
                            1970
                          ? dayjs(qualification.start_date).format('MMM YYYY')
                          : `${dayjs(qualification.start_date).format('MMM YYYY')} - ${dayjs(qualification.end_date).format('MMM YYYY')}`}{' '}
                    </h6>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Education
