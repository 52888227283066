import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from '../../shared/components/candidateInfo/styles.module.scss'
import CandidateCard from '../../shared/components/candidateCard/candidateCard'
import { CandidateOverview } from '../../shared/interfaces/candidate.interface'
import TabBox, {
  TabBoxButton,
  Tabs,
} from '../../shared/components/tabBox/tabBox'
import { HiringPage, HiringStage } from '../../shared/enums/hiring.enum'
import {
  convertHiringPageToStage,
  getAllCandidates,
  getMatchedCandidates,
} from '../../shared/services/hiring.service'
import LoadingService from '../../shared/services/loading.service'
import { HiringProcess } from '../../shared/interfaces/hiring.interface'
import SearchDropdown from '../../shared/components/customDropdown/searchDropdown'
import FilterIconNew from '../../icons/filterNew.icon'
import ArrowDownIcon from '../../icons/downArrow.icon'
import color from '../../scss/variables.module.scss'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import { HiringCandidateContext } from '../../context/hiring.candidate.context'

export interface CandidateListProps {
  page: string
  jobID: string
  disabled: boolean
  onCandidateSelect?: (candidate: any, page: any) => void
  setPage?: (page: HiringStage) => void
  selectedCandidateId?: string
  hiringData: HiringProcess[]
  jobStatus: string
  addToList: (candidate: any) => void
}

export default function CandidateList({
  page,
  jobID,
  disabled,
  onCandidateSelect,
  hiringData,
  selectedCandidateId,
  jobStatus,
  addToList,
}: CandidateListProps) {
  const [tab, setTab] = useState<string>('matched')
  const { candidateDataList } = useContext(HiringCandidateContext)
  const [allCandidates, setAllCandidates] = useState<CandidateOverview[]>([])
  const [matchedCandidates, setMatchedCandidates] = useState<
    CandidateOverview[]
  >([])
  const [candidates, setCandidates] = useState<any>([])
  const [userSet, setUser] = useState<any>(false)
  const [searchedValue, setSearchedValue] = useState<string>('')
  const [sortValue, setSortValue] = useState<number>(1)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(0)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )

  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  useEffect(() => {
    setIsDisabled(disabled || isLoading)
  }, [disabled, isLoading])

  /**
   * Handles the "Load More" button click event.
   * If loadMore is false, it increments the pageNumber state by 1.
   */
  const handleLoadMore = () => {
    if (!loadMore) {
      setPageNumber(pageNumber + 1)
    }
  }

  useEffect(() => {
    if (page === HiringPage.Candidates && tab === 'matched') {
      const sortCandidateList = matchedCandidates?.sort(
        (a, b) => b.match_percentage - a.match_percentage
      )
      setCandidates(sortCandidateList)
    } else if (page === HiringPage.Candidates && tab === 'all') {
      const sortCandidateList = allCandidates.sort(
        (a, b) => b.match_percentage - a.match_percentage
      )
      setCandidates(sortCandidateList)
    } else {
      if (page === HiringPage.Screening || page === 'pending') {
        if (tab === 'matched') {
          const sortCandidateList = matchedCandidates?.sort(
            (a, b) => b.match_percentage - a.match_percentage
          )
          setCandidates(sortCandidateList)
        } else {
          const sortCandidateList = allCandidates.sort(
            (a, b) => b.match_percentage - a.match_percentage
          )
          setCandidates(sortCandidateList)
        }
      } else {
        const hiringStageCandidateIds = hiringData
          .filter((h) => h.stage === convertHiringPageToStage(page))
          .map((h) => h.candidate_id)
        let userList: any = []
        if (candidateDataList) {
          userList = candidateDataList
            ?.filter((c: any) => hiringStageCandidateIds.includes(c.id))
            .sort((a: any, b: any) => b.match_percentage - a.match_percentage)
        }
        if (onCandidateSelect) {
          if (jobStatus === 'closed' && page === HiringPage.PaperWork) {
            const hiringCompleteCandidateId = hiringData.filter(
              (h) => h.stage === 'Completed'
            )[0]?.candidate_id
            hiringStageCandidateIds.push(hiringCompleteCandidateId)
            const abc: any = candidateDataList
              ?.filter((c: any) => hiringStageCandidateIds.includes(c.id))
              .sort((a: any, b: any) => b.match_percentage - a.match_percentage)
            setCandidates(abc)
            // @ts-ignore
            onCandidateSelect(
              abc.find((f: any) => f.id === hiringCompleteCandidateId)?.id,
              HiringStage.PaperWork
            )
            setUser(hiringCompleteCandidateId)
          } else {
            setCandidates(userList)
            onCandidateSelect(
              hiringStageCandidateIds.includes(selectedCandidateId ?? '')
                ? selectedCandidateId
                : userList[0]?.id,
              hiringData.find((h) => h.candidate_id === userList[0]?.id)
                ?.stage ?? HiringStage.Candidates
            )
            setUser(
              hiringStageCandidateIds.includes(selectedCandidateId ?? '')
                ? selectedCandidateId
                : userList[0]?.id
            )
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [
    tab,
    page,
    matchedCandidates,
    allCandidates,
    hiringData,
    selectedCandidateId,
  ])

  /**
   * Constructs an object with parameters for fetching a list of candidates.
   * @param jobID - The ID of the job to fetch candidates for.
   * @param searchedValue - The value to search for in the candidate list. If undefined, the search parameter is omitted.
   * @param pageNumber - The page number of the candidate list to fetch.
   * @returns An object with parameters for fetching a list of candidates.
   */
  useEffect(() => {
    if (jobID !== '') {
      const params = {
        jobID: jobID,
        search: searchedValue ?? undefined,
        page: pageNumber,
        sortBy: false,
      }
      if (searchedValue !== '') {
        loadingService
          .await(
            getAllCandidates(params).then((res) => {
              if (res.length < 10) {
                setLoadMore(true)
              }
              setAllCandidates((prev) =>
                [...prev, ...res].filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t.id === item.id)
                )
              )
            })
          )
          .catch((error) => {
            setErrorModel(true)
            setErrorMessage(error.message)
          })
      } else {
        loadingService
          .await(
            getAllCandidates(params).then((res) => {
              if (res.length < 10) {
                setLoadMore(true)
              }
              setAllCandidates((prev) =>
                [...prev, ...res].filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t.id === item.id)
                )
              )
            })
          )
          .catch((error) => {
            setErrorModel(true)
            setErrorMessage(error.message)
          })
        loadingService
          .await(getMatchedCandidates(jobID).then(setMatchedCandidates))
          .catch((error) => {
            setErrorModel(true)
            setErrorMessage(error.message)
          })
      }
    }
    // eslint-disable-next-line
  }, [jobID, loadingService, pageNumber, searchedValue])

  useEffect(() => {
    if (
      !userSet &&
      candidates.length > 0 &&
      page === HiringPage.Candidates &&
      tab === 'matched' &&
      !userSet
    ) {
      if (jobStatus === 'closed') {
        const hiringCompleteCandidateId = hiringData.filter(
          (h) => h.stage === 'Completed'
        )[0]?.candidate_id
        if (onCandidateSelect) {
          if (
            candidates.find((f: any) => f.id === hiringCompleteCandidateId)?.id
          ) {
            onCandidateSelect(
              candidates.find((f: any) => f.id === hiringCompleteCandidateId)
                ?.id,
              HiringStage.PaperWork
            )
            setUser(hiringCompleteCandidateId)
          } else {
            onCandidateSelect(
              allCandidates.find((f: any) => f.id === hiringCompleteCandidateId)
                ?.id,
              HiringStage.PaperWork
            )
            setUser(hiringCompleteCandidateId)
          }
          // @ts-ignore
        }
      } else {
        if (onCandidateSelect) {
          onCandidateSelect(
            selectedCandidateId ? selectedCandidateId : candidates[0].id,
            hiringData.find((h) =>
              h.candidate_id === selectedCandidateId
                ? selectedCandidateId
                : candidates[0].id
            )?.stage ?? HiringStage.Candidates
          )
          setUser(candidates[0].id)
        }
      }
    }
    // eslint-disable-next-line
  }, [candidates, selectedCandidateId, userSet, jobStatus])

  const handleSelectCandidate = (candidate: CandidateOverview) => {
    if (onCandidateSelect) {
      onCandidateSelect(
        candidate.id,
        hiringData.find((h) => h.candidate_id === candidate?.id)?.stage ??
          HiringStage.Candidates
      )
    }
    addToList(candidate)
    setUser(candidate.id)
  }

  /**
   * Handles the clear of the search
   * @returns void
   */
  const handleClear = (): void => {
    if (disabled) return
    setLoadMore(false)
    setPageNumber(0)
  }

  return (
    <div style={{ opacity: isLoading ? 0.5 : 1 }}>
      <div className={styles.candidatesListCard}>
        {page === HiringPage.Candidates ? (
          <h6>Candidates</h6>
        ) : (
          <h6>Selected Candidates</h6>
        )}
        <div
          className={`justify-content-end ${styles.responsive} ${styles.widthSearch}`}
        >
          <SearchDropdown
            dataList={[]}
            placeHolder={'Candidate Name'}
            handleSearch={setSearchedValue}
            disabled={isDisabled}
            onClear={handleClear}
          />
        </div>
        <div
          className="d-flex mt-3 flex-row align-items-center justify-content-between"
          onClick={() => setSortValue(sortValue * -1)}
        >
          <h6 className={`gray-color-text ${styles.widthLeft}`}>
            Matching Criteria
          </h6>
          <span className="d-flex align-items-center">
            <FilterIconNew />
            <h6 className="ms-3 text-normal text-tertiary-color">Sort By</h6>
          </span>
        </div>
        {page === HiringPage.Candidates && (
          <TabBox
            selectedTabName={tab}
            className={`mt-5 mb-5 ${styles.tabWidth}`}
            onTabChange={(newTab: string) => setTab(newTab)}
          >
            <Tabs>
              <TabBoxButton tabName={'matched'}>
                <h6>Matched</h6>
              </TabBoxButton>
              <TabBoxButton tabName={'all'}>
                <h6>All</h6>
              </TabBoxButton>
            </Tabs>
          </TabBox>
        )}
        {candidates
          .filter((candidate: CandidateOverview) => {
            const searchWords = searchedValue.split(' ')
            return searchWords.every((word) =>
              candidate.name.toLowerCase().includes(word.toLowerCase())
            )
          })
          .sort(
            (a: any, b: any) =>
              sortValue * (b.match_percentage - a.match_percentage)
          )
          .map((candidate: any, index: any) => (
            <div key={candidate.id}>
              <CandidateCard
                key={`cc${index}`}
                fromJob={true}
                candidate={candidate}
                selected={candidate.id === userSet}
                onClick={() => handleSelectCandidate(candidate)}
              />
            </div>
          ))}
        {candidates.filter((candidate: CandidateOverview) =>
          candidate.name.toLowerCase().includes(searchedValue)
        ).length === 0 && (
          <h6 className={'text-center mt-4'}>No candidates found</h6>
        )}
        {tab === 'all' && (
          <span
            className="d-flex align-items-center mt-3 mb-5"
            onClick={handleLoadMore}
            style={!loadMore ? { cursor: 'pointer' } : {}}
          >
            <h6 className="me-4 mt-2 pointer text-tertiary-color">Load More</h6>
            <ArrowDownIcon color={color.teritaryColor500} />
          </span>
        )}
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}
