import { RequestStatus } from '../enums/commonApplication.enum'
import { HiringPage, HiringStage } from '../enums/hiring.enum'
import {
  CandidateDetails,
  CandidateOverview,
} from '../interfaces/candidate.interface'
import { BaseResponse } from '../interfaces/commonApplication.interface'
import {
  AddToSupplyListType,
  HiringProcess,
  HiringProcessSearchRequest,
  HiringProcessUpdateRequest,
  NegotiationResType,
} from '../interfaces/hiring.interface'
import { axiosInstance } from './base.service'

export const convertHiringStageToPage = (
  stage: HiringStage | string
): HiringPage => {
  switch (stage) {
    case HiringStage.CultureCatchUp:
      return HiringPage.CultureCatchUp
    case HiringStage.Interview:
      return HiringPage.Interview
    case HiringStage.ReferenceCheck:
      return HiringPage.ReferenceCheck
    case HiringStage.Negotiation:
      return HiringPage.Negotiation
    case HiringStage.PaperWork:
      return HiringPage.PaperWork
    default:
      return HiringPage.Candidates
  }
}

export const convertHiringPageToStage = (
  page: HiringPage | string
): HiringStage => {
  switch (page) {
    case HiringPage.Candidates:
      return HiringStage.Screening
    case HiringPage.CultureCatchUp:
      return HiringStage.CultureCatchUp
    case HiringPage.Interview:
      return HiringStage.Interview
    case HiringPage.ReferenceCheck:
      return HiringStage.ReferenceCheck
    case HiringPage.Negotiation:
      return HiringStage.Negotiation
    case HiringPage.PaperWork:
      return HiringStage.PaperWork
    default:
      return HiringStage.Pending
  }
}

export const convertHiringStageToNumber = (
  stage: HiringStage | string
): number => {
  switch (stage) {
    case HiringStage.Screening:
      return 1
    case HiringStage.CultureCatchUp:
      return 2
    case HiringStage.Interview:
      return 3
    case HiringStage.ReferenceCheck:
      return 4
    case HiringStage.Negotiation:
      return 5
    case HiringStage.PaperWork:
      return 6
    case HiringStage.Completed:
      return 7
    default:
      return 0
  }
}

export type MatchingRequestType = {
  jobID: string
  search: string
  page: number
  sortBy: boolean
  userId?: any[]
}

export const getHiringStageOverview = async (): Promise<
  { stage: HiringStage; count: number }[]
> => {
  const url: string = `/hiring/get-count`
  try {
    const res =
      await axiosInstance.get<
        BaseResponse<{ stage: HiringStage; count: number }[]>
      >(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

// Get stages count for a specific job
export const getHiringStageOverviewForJob = async (
  jobID: string
): Promise<{ stage: HiringStage; count: number }[]> => {
  const url: string = `/hiring/get-count?${new URLSearchParams({ id: jobID })}`
  try {
    const res =
      await axiosInstance.get<
        BaseResponse<{ stage: HiringStage; count: number }[]>
      >(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getAllCandidates = async (
  params: MatchingRequestType
): Promise<CandidateOverview[]> => {
  const url: string = `/matches/candidates/all?${new URLSearchParams({
    id: params.jobID,
    search: params.search ?? undefined,
    userId: JSON.stringify(params.userId) ?? '[]',
    page: params.page.toString(), // convert number to string
    sortBy: params.sortBy.toString(), // convert boolean to string
  })}`
  try {
    const res = await axiosInstance.get<BaseResponse<CandidateOverview[]>>(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getMatchedCandidates = async (
  jobID: string
): Promise<CandidateOverview[]> => {
  const url: string = `/matches/candidates/all-matches?${new URLSearchParams({
    id: jobID,
  })}`
  try {
    const res = await axiosInstance.get<BaseResponse<CandidateOverview[]>>(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getAllCandidatesDropdownDataList = async (): Promise<any[]> => {
  const url: string = `/matches/candidates/allList`
  try {
    const res = await axiosInstance.get<BaseResponse<CandidateOverview[]>>(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getAllCandidatesList = async (params: {
  search: string
  sortBy: boolean
  page: number
}): Promise<any[]> => {
  const url: string = `/matches/candidates/allList/filter?${new URLSearchParams(
    {
      search: params.search ?? null,
      sortBy: params.sortBy.toString(), // convert boolean to string
      page: params.page.toString(), // convert number to string
    }
  )}`
  try {
    const res = await axiosInstance.get<BaseResponse<CandidateOverview[]>>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getHiringProcesses = async (
  jobID: string
): Promise<HiringProcess[]> => {
  const url: string = `/hiring/get-job-processes?${new URLSearchParams({
    id: jobID,
  })}`
  try {
    const res = await axiosInstance.get<BaseResponse<HiringProcess[]>>(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getHiringProcess = async (
  search: HiringProcessSearchRequest
): Promise<HiringProcess | null> => {
  const url: string = `/hiring/get-process?${new URLSearchParams(search)}`
  try {
    const res = await axiosInstance.get<BaseResponse<HiringProcess>>(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getCandidateDetails = async (
  candidateID: string
): Promise<CandidateDetails | null> => {
  const url: string = `/hiring/get-candidate-details?${new URLSearchParams({
    id: candidateID,
  })}`
  try {
    const res = await axiosInstance.get<BaseResponse<CandidateDetails>>(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const updateHiringProcess = async (
  search: HiringProcessSearchRequest,
  details: HiringProcessUpdateRequest
): Promise<boolean> => {
  const url: string = `/hiring/update-process?${new URLSearchParams(search)}`
  try {
    const res = await axiosInstance.post<BaseResponse<boolean>>(url, details)
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

export const getSupplyListProcess = async (
  hiring_process_id: string
): Promise<boolean> => {
  const url: string = `/hiring/get-supply-list?${new URLSearchParams({
    hiringProcessId: hiring_process_id,
  })}`
  try {
    const res = await axiosInstance.get<BaseResponse<boolean>>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const addToSupplyList = async (
  data: AddToSupplyListType
): Promise<boolean> => {
  const url: string = `/hiring/add-to-supply-list`
  try {
    const res = await axiosInstance.post<{
      status: string
      result: AddToSupplyListType
    }>(url, data)
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

// Get negotiation data for a hiring process
export const getNegotiationData = async (
  hiringProcessId: string
): Promise<NegotiationResType> => {
  const url: string = `/hiring/get-negotiation-details?${new URLSearchParams({
    hiring_process_id: hiringProcessId,
  })}`
  try {
    const res = await axiosInstance.get<BaseResponse<NegotiationResType>>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

// Add negotiation data for a hiring process for logged in admin user
export const addNegotiationData = async (
  negotiationData: NegotiationResType
): Promise<{ res: RequestStatus; message: string }> => {
  const url: string = `/hiring/add-negotiation-details`
  try {
    const res = await axiosInstance.post<BaseResponse<boolean>>(
      url,
      negotiationData
    )
    return {
      res: res.data.status,
      message: res.data.result
        ? 'Negotiation data added successfully'
        : 'Negotiation data not added',
    }
  } catch (err) {
    return {
      res: RequestStatus.NoData,
      message: 'Negotiation data not added',
    }
  }
}

// Get candidate jobs based on the hiring process id
export const getCandidateJobs = async (params: {
  hiring_process_id: string
}): Promise<any[]> => {
  const url: string = `/hiring/get-candidate-jobs?${new URLSearchParams(
    params
  )}`
  try {
    const res = await axiosInstance.get<BaseResponse<any[]>>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

// update or add date and time in hiring step culture catch up & interview
export const updateHiringStepDate = async (params: {
  hiringProcessId: string
  date: string
}): Promise<boolean> => {
  const url: string = `/hiring/update-hiring-process-date`
  try {
    const res = await axiosInstance.post<BaseResponse<boolean>>(url, params)
    return res.data.status === RequestStatus.Success
  } catch (err) {
    return false
  }
}

export const getCandidateFiles = async (params: {
  id: string
  URL_Type: string
}): Promise<any> => {
  const url: string = `/hiring/get_pre_sign_url?${new URLSearchParams(params)}`
  try {
    const res = await axiosInstance.get<BaseResponse<any>>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const updateNegotiationNotes = async (params: {
  hiring_process_id: string
  note: string
}): Promise<any> => {
  const url: string = `/hiring/update-negotiation-notes`
  try {
    const res = await axiosInstance.post<BaseResponse<any>>(url, params)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getHiringProcessCandidateData = async (params: {
  candidateId: string
}): Promise<any> => {
  const url: string = `/hiring/get-candidate?${new URLSearchParams(params)}`
  try {
    if (params.candidateId) {
      const res = await axiosInstance.get<BaseResponse<any>>(url)
      return res.data
    }
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getAssessmentResults = async (params: {
  candidateId: string
}): Promise<any> => {
  const url: string = `/hiring/get-assessment-results?${new URLSearchParams(params)}`
  try {
    if (params.candidateId) {
      const res = await axiosInstance.get<BaseResponse<any>>(url)
      return res.data
    }
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

/**
 * Get history for negotiation
 * @param {hiring_process_id} string
 * @returns {Promise<any>}
 */
export const getNegotiationHistory = async (params: {
  hiring_process_id: string
}): Promise<any> => {
  const url: string = `/hiring/history/negotiation?${new URLSearchParams(params)}`
  try {
    const res = await axiosInstance.get<BaseResponse<any>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

/**
 * Save notes for hiring process
 * @param {hiringProcessId: string, note: string} params
 * @returns {Promise<any>}
 */
export const saveHiringProcessNotes = async (params: {
  hiringProcessId: string
  note: string
}): Promise<any> => {
  const url: string = `/hiring/add-note`
  try {
    const res = await axiosInstance.post<BaseResponse<any>>(url, params)
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

/**
 * Get notes for hiring process
 * @param {hiringProcessId: string} hiringProcessId
 * @returns {Promise<any>}
 */
export const getHiringProcessNotes = async (
  hiringProcessId: string
): Promise<any> => {
  const url: string = `/hiring/get-notes?${new URLSearchParams({ hiring_process_id: hiringProcessId })}`
  try {
    const res = await axiosInstance.get<BaseResponse<any>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

/**
 * Update job status for an hiring process
 * @param {job_id: string, status: string} params
 */
export const updateJobStatus = async (params: {
  job_id: string
  job_status: string
}): Promise<any> => {
  const url: string = `/hiring/update-job-status`
  try {
    const res = await axiosInstance.post<BaseResponse<any>>(url, params)
    return res
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getHiringProcessData = async (job_id: string): Promise<any> => {
  const url: string = `/hiring/get-job-processes?${new URLSearchParams({ id: job_id })}`
  try {
    const res = await axiosInstance.get<BaseResponse<any>>(url)
    return res
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}
