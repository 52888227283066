import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { IApplicationLevelProps } from './shared/interfaces/applicationLevelPorps.interface'
import './app.scss'
import { Amplify } from 'aws-amplify'
import awsConfig from './aws-exports'

import DashBoard from './pages/dashboard/dashboard'
import AllJobs from './pages/allJobs/allJobs'

import DashboardCandidate from './pages/dashboard/dashboardHiringProcess'
import Teams from './pages/teams/teams'
import Organizations from './pages/organization/organization'
import CostCalculations from './pages/settings/costCalculation'
import InterviewAssessment from './pages/settings/interviewAssesment'
import MasterData from './pages/settings/masterdata'
import Candidates from './pages/candidates/candidates'
import HardSkillAssessment from './pages/settings/hardSkillAssessment'
import CultureCatchUpAssessment from './pages/settings/cultureCatchUpAssessment'
import NeedAnalysis from './pages/needAnalysis/needAnalysis'
import { useEffect, useState } from 'react'
import { getUserRole } from './shared/services/auth.service'
import { UserRole } from './shared/enums/auth.enum'
import SignIn from './pages/signInForms/signIn'
import { HiringProcessProvider } from './context/hiring.process.context'
import CandidateListDataProvider from './context/hiring.candidate.context'

Amplify.configure(awsConfig)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const App = (props: IApplicationLevelProps): JSX.Element => {
  const [role, setRole] = useState('')
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUserRole()
        setRole(response)
      } catch (error) {
        console.log('Error fetching data:', error)
      }
    }

    fetchData().then()
  }, [])

  const ProtectedRouteMasterData = () => {
    return role === UserRole.GlobalAdmin ? <MasterData /> : <MasterData />
  }
  const ProtectedRouteTeams = () => {
    return role === UserRole.GlobalAdmin || role === UserRole.Manager ? (
      <Teams />
    ) : (
      <Teams />
    )
  }
  return (
    <>
      <BrowserRouter>
        <HiringProcessProvider>
          <CandidateListDataProvider>
            <Routes>
              <Route path={'*'} element={<SignIn />} />
              <Route
                path={'/masterData'}
                element={<ProtectedRouteMasterData />}
              />
              <Route path={'/dashboard'} element={<DashBoard />} />
              <Route path={'/allJobs'} element={<AllJobs />} />
              <Route path={'/candidate'} element={<DashboardCandidate />} />
              <Route path={'/teams'} element={<ProtectedRouteTeams />} />
              <Route path={'/organization'} element={<Organizations />} />
              <Route path={'/costCalculation'} element={<CostCalculations />} />
              <Route path={'/Interview'} element={<InterviewAssessment />} />
              <Route path={'/candidates'} element={<Candidates />} />
              <Route
                path={'/hardSkillAssessment'}
                element={<HardSkillAssessment />}
              />
              <Route
                path={'/cultureCatchUpAssessment'}
                element={<CultureCatchUpAssessment />}
              />
              <Route path={'/needAnalysis'} element={<NeedAnalysis />} />
            </Routes>
          </CandidateListDataProvider>
        </HiringProcessProvider>
      </BrowserRouter>
    </>
  )
}

export default App
