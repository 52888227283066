import React, { useEffect, useMemo, useState } from 'react'
import styles from '../teams/styles.module.scss'
import TopNavigation from '../../shared/components/topNavigation/topNavigation'
import Settings from './settings'
import CustomButton from '../../shared/components/customButton/customButton'
import RightLongArrowIcon from '../../icons/rightLongArrow.icon'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import EditIcon from '../../icons/edit.icon'
import {
  addCultureCatchUpAssessmentQuestions,
  getCultureCatchUpAssessmentQuestions,
  removeCultureCatchUpAssessmentQuestions,
} from '../../shared/services/assessment.service'
import { CultureCatchUpQuestion } from '../../shared/interfaces/assessment.interface'
import LoadingService from '../../shared/services/loading.service'
import PlusIcon from '../../icons/plus.icon'
import CustomModal from '../../shared/components/customModal/customModal'
import AddNewQuestionCultureCatchUp from './addNewQuestionCultureCatchUp'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'

function CultureCatchUpAssessment() {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [questions, setQuestions] = useState<CultureCatchUpQuestion[]>([])
  const [questionsToRemove, setQuestionsToRemove] = useState<string[]>([])
  const [selectedQuestion, setSelectedQuestion] =
    useState<CultureCatchUpQuestion | null>(null)

  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )

  // Get all questions and categories on page load
  useEffect(() => {
    loadingService
      .await(getCultureCatchUpAssessmentQuestions())
      .then(setQuestions)
      .catch((error: any) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [loadingService])

  /**
   * Handles the submission of the modal form
   * @param question The question to be added or edited
   * @returns void
   */
  const handleModalSubmit = (question: CultureCatchUpQuestion): void => {
    if (selectedQuestion) {
      const index = questions.findIndex((q) => q.id === selectedQuestion.id)
      // When editing a question, we need to remove the old question and add the new one
      setQuestionsToRemove([...questionsToRemove, question.id])
      question.id = ''
      setQuestions([
        ...questions.slice(0, index),
        question,
        ...questions.slice(index + 1),
      ])
    } else {
      setQuestions([...questions, question])
    }
    setModalOpen(false)
  }

  /**
   * Handles the press of the edit icon
   * @param question The question to be edited
   * @returns void
   */
  const handleEditQuestion = (question: CultureCatchUpQuestion): void => {
    setSelectedQuestion(question)
    setModalOpen(true)
  }

  /**
   * Handles the press of the delete icon
   * @param question The question to be deleted
   * @returns void
   */
  const handleDeleteQuestion = (question: CultureCatchUpQuestion): void => {
    const index = questions.findIndex((q) => q.id === question.id)
    setQuestions([...questions.slice(0, index), ...questions.slice(index + 1)])
    setQuestionsToRemove([...questionsToRemove, question.id])
  }

  /**
   * Handles the press of the add new question button
   * @returns void
   */
  const handleAddQuestion = (): void => {
    setSelectedQuestion(null)
    setModalOpen(true)
  }

  /**
   * Handles the press of the save questions button
   * Sends add and remove requests to the backend
   * @returns void
   */
  const handleSaveQuestions = (): void => {
    loadingService
      .await(
        addCultureCatchUpAssessmentQuestions(
          questions.filter((q) => q.id === '')
        ).then((res) => {
          if (!res) {
            setErrorModel(true)
            setErrorMessage('Error...')
          }
        })
      )
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    loadingService
      .await(
        removeCultureCatchUpAssessmentQuestions(questionsToRemove).then(
          (res) => {
            if (!res) {
              setErrorModel(true)
              setErrorMessage('Error...')
              setQuestionsToRemove([])
            } else {
              setQuestionsToRemove([])
            }
          }
        )
      )
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }

  return (
    <div>
      <TopNavigation tabValue={'0'} />
      <div
        className={`d-md-flex justify-content-between mx-1 mx-md-5 my-1 my-md-5 `}
      >
        <Settings cultureCatchUpSelected={true} />
        <div
          className={`mx-md-5 mx-1 col-sm-12 col-lg-8`}
          style={{ opacity: isLoading ? 0.5 : 1 }}
        >
          <div>
            <div>
              <div className={styles.contentQuestion}>
                <div className={`w-100 ${styles.widthLeft}`}>
                  <div
                    className={
                      'd-md-flex w-100 justify-content-between align-items-center'
                    }
                  >
                    <h2 className={`text-bolder text-almostBlack`}>
                      Culture Catch-Up Questions
                    </h2>
                    <CustomButton
                      text={'Add New Question'}
                      icon={<PlusIcon />}
                      iconSide={'left'}
                      className={`mt-2 mt-md-0 ${styles.addQuestionBtn}`}
                      onClick={handleAddQuestion}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ps-4">
              <div>
                {questions.map((question, index) => (
                  <div>
                    <div className={styles.contentQuestion}>
                      <div
                        className={
                          'd-flex justify-content-between align-items-center w-100'
                        }
                      >
                        <div className={'d-flex align-items-center mt-4 w-75'}>
                          <h6 className={'text-normal ms-4'}>
                            <span className={'text-bolder me-4'}>
                              {index + 1}
                            </span>
                            {question.text}
                          </h6>
                        </div>
                        <div className={'w-25 d-flex justify-content-around'}>
                          <div
                            className={'pointer'}
                            onClick={() => handleDeleteQuestion(question)}
                          >
                            <RecycleBinIcon />
                          </div>
                          <div
                            className={'pointer'}
                            onClick={() => handleEditQuestion(question)}
                          >
                            <EditIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={
                  'd-flex justify-content-end align-items-center mt-5 mb-5'
                }
              >
                <div className={'d-flex align-items-center'}>
                  <CustomButton
                    loading={isLoading}
                    type={'submit'}
                    text={'Publish Changes'}
                    icon={<RightLongArrowIcon size={14} />}
                    iconSide={'right'}
                    onClick={handleSaveQuestions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          open={isModalOpen}
          onCloseModal={() => setModalOpen(false)}
          title={
            selectedQuestion === null ? 'Add New Question' : 'Update Question'
          }
        >
          <AddNewQuestionCultureCatchUp
            question={selectedQuestion}
            onSubmit={handleModalSubmit}
            closeModal={() => setModalOpen(false)}
          />
        </CustomModal>
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default CultureCatchUpAssessment
