import React, { useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import CustomButton from '../customButton/customButton'
import FileUpload from '../fileUpload/fileUpload'
import RecycleBinIcon from '../../../icons/recycleBinIcon'
import ResumeIcon from '../../../icons/resume.icon'
import RightLongArrowIcon from '../../../icons/rightLongArrow.icon'
import { HiringProcess } from '../../interfaces/hiring.interface'
import FileService from '../../services/file.service'
import { Form, Formik } from 'formik'
import {
  deletePaperWork,
  getPaperWorks,
} from '../../services/paperWork.service'
import LoadingService from '../../services/loading.service'
import LoadingSpinner from '../customLoadingSpinner/loadingSpinner'
import ConfirmBannerModal from '../errorBannerModal/confirmBannerModal'
import DocViewModel from '../errorBannerModal/docViewModel'
import { RequestStatus } from '../../enums/commonApplication.enum'
import { HiringStage } from '../../enums/hiring.enum'
import { CandidateDetails } from '../../interfaces/candidate.interface'
import { updateHiringProcess } from '../../services/hiring.service'
import CustomModal from '../customModal/customModal'
import { useNavigate } from 'react-router-dom'
import { getUserRole } from '../../services/auth.service'
import { UserRole } from '../../enums/auth.enum'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

interface CandidateProps {
  supplyStatus: boolean
  hiringData: HiringProcess
  handleAddToSupply: () => void
  setPage?: (page: HiringStage) => void
  candidate: CandidateDetails
  onUpdateHiringData?: (hiringData: HiringProcess) => void
  disableTabsAndButtons: boolean
}

const PaperWork = ({
  supplyStatus,
  hiringData,
  setPage,
  candidate,
  onUpdateHiringData,
  disableTabsAndButtons,
}: CandidateProps) => {
  const [showTab, setShowTab] = useState<boolean>(true)
  const [isChange, setChange] = useState<boolean>()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [viewURL, setViewURL] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')
  const [isDoc, setIsDoc] = useState<boolean>(false)
  const [, setDisableButton] = useState<boolean>(false)
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()
  const fileService: FileService = useMemo(() => {
    return new FileService()
  }, [])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )
  const [paperWorks, setAllPaperWorks] = useState<any[]>([])

  const viewFile = (work: any) => {
    if (work.file_name.toString().endsWith('.pdf')) {
      fileService.openFile({ contents: work.file_url })
    } else {
      setFileName(work.file_name)
      setIsDoc(true)
      setViewURL(work.file_url)
    }
  }

  const getData = () => {
    if (hiringData) {
      loadingService
        .await(getPaperWorks(hiringData?.id).then(setAllPaperWorks))
        .catch((error) => {
          setErrorModel(true)
          setErrorMessage(error.message)
        })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    getData()
    // eslint-disable-next-line
  }, [hiringData, isChange])
  const handleSubmit = async (): Promise<void> => {
    setDisableButton(true)
    setIsLoading(true)
    if (setPage) {
      loadingService
        .await(
          updateHiringProcess(
            {
              candidate_id: candidate?.id ?? '',
              job_id: hiringData?.job_id ?? '',
            },
            { stage: HiringStage.Completed, hiringProcessId: hiringData.id }
          )
        )
        .then((res) => {
          if (!res) {
            setErrorModel(true)
            setErrorMessage('Update Error')
          }
          if (onUpdateHiringData) {
            onUpdateHiringData({
              ...hiringData,
              stage: HiringStage.Completed,
            })
          }
          if (setPage) {
            setPage(HiringStage.PaperWork)
          }
          setModal(true)
        })
    }
    setIsLoading(false)
  }
  const handleOkClick = () => {
    setModal(false)
    navigate('/dashboard')
  }
  const deleteWork = async (value: string): Promise<void> => {
    setIsLoading(true)
    setShowConfirm(false)
    await deletePaperWork(value)
      .then(() => getData())
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
    setDeleteId('')
    setChange(!isChange)
  }

  const showDeleteConfirmBox = async (value: string): Promise<void> => {
    setShowConfirm(true)
    setDeleteId(value)
  }

  useEffect(() => {
    getUserRole()
      .then((role) => {
        if (role !== UserRole.StaffAdmin) {
          setShowTab(true)
        } else {
          setShowTab(false)
        }
      })
      .catch((error) => {
        setErrorModel(true)
        setErrorMessage(error.message)
      })
  }, [])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!showTab ? (
        <></>
      ) : (
        <Formik
          initialValues={paperWorks}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange
        >
          {({}) => (
            <Form>
              <div className={'mt-5 w-100'}>
                <div className={`mt-5 mb-5 ${styles.resumeUploadBox}`}>
                  <div className={'d-flex mb-3'}>
                    <ResumeIcon />
                    <h6 className={'ms-3 text-bold gray-color-text'}>
                      Contractual Document
                    </h6>
                  </div>
                  <div></div>
                  <div className={`pb-4 ${styles.borderBottom}`}>
                    <FileUpload
                      id={'uploadFile'}
                      filedText={'Pick a file *'}
                      fileSelect={async (file: File | null) => {
                        if (file !== null) {
                          setIsLoading(true)
                          const res = await fileService.readFileAndUpload(
                            file,
                            'Contractual',
                            hiringData
                          )
                          if (res.status === RequestStatus.Success) {
                            setIsLoading(false)
                            setChange(!isChange)
                          }
                          setIsLoading(false)
                        }
                      }}
                    />
                    <h6 className={`gray-color-text mt-3`}>
                      Supported file type: .pdf, .doc
                    </h6>
                  </div>
                  {paperWorks &&
                    paperWorks
                      .filter((h: any) => h.file_type === 'Contractual')
                      .map((work: any, index) => {
                        return (
                          <div className={`mt-5 `} key={index}>
                            <div className={styles.selectedResume}>
                              <h6 className={'ms-3 text-bold gray-color-text'}>
                                Selected File
                              </h6>

                              <div className={`d-flex ${styles.borderTop}`}>
                                <div
                                  className={
                                    'd-flex justify-content-between align-items-center w-100'
                                  }
                                >
                                  <h6 className={'text-normal gray-color-text'}>
                                    {work.file_name}{' '}
                                    <span
                                      className={'text-primary pointer'}
                                      onClick={() => viewFile(work)}
                                    >
                                      View File
                                    </span>
                                  </h6>
                                </div>
                                <div
                                  onClick={() => showDeleteConfirmBox(work.id)}
                                  className={'pointer'}
                                >
                                  <RecycleBinIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                </div>
              </div>
              <div className={'mt-5 w-100'}>
                <div className={`mt-5 mb-5 ${styles.resumeUploadBox}`}>
                  <div className={'d-flex mb-3'}>
                    <ResumeIcon />
                    <h6 className={'ms-3 text-bold gray-color-text'}>
                      Invoicing Documents
                    </h6>
                  </div>
                  <div className={`pb-4 ${styles.borderBottom}`}>
                    <FileUpload
                      id={'uploadFile2'}
                      filedText={'Pick a file *'}
                      fileSelect={async (file: File | null) => {
                        if (file !== null) {
                          setIsLoading(true)
                          const res = await fileService.readFileAndUpload(
                            file,
                            'Invoicing',
                            hiringData
                          )
                          if (res.status === RequestStatus.Success) {
                            setIsLoading(false)
                            setChange(!isChange)
                          }
                        }
                      }}
                    />
                    <h6 className={`gray-color-text mt-3`}>
                      Supported file type: .pdf, .doc
                    </h6>
                  </div>

                  {paperWorks &&
                    paperWorks
                      .filter((h: any) => h.file_type === 'Invoicing')
                      .map((work: any, index) => {
                        return (
                          <div className={`mt-5 `} key={index}>
                            <div className={styles.selectedResume}>
                              <h6 className={'ms-3 text-bold gray-color-text'}>
                                Selected File
                              </h6>

                              <div className={`d-flex ${styles.borderTop}`}>
                                <div
                                  className={
                                    'd-flex justify-content-between align-items-center w-100'
                                  }
                                >
                                  <h6 className={'text-normal gray-color-text'}>
                                    {work.file_name}{' '}
                                    <span
                                      className={'text-primary pointer'}
                                      onClick={() => viewFile(work)}
                                    >
                                      View File
                                    </span>
                                  </h6>
                                </div>
                                <div
                                  onClick={() => showDeleteConfirmBox(work.id)}
                                  className={'pointer'}
                                >
                                  <RecycleBinIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                </div>
              </div>
              <div className={'mt-5 w-100'}>
                <div className={`mt-5 mb-5 ${styles.resumeUploadBox}`}>
                  <div className={'d-flex mb-3'}>
                    <ResumeIcon />
                    <h6 className={'ms-3 text-bold gray-color-text'}>
                      Additional Documents
                    </h6>
                  </div>

                  <div className={`pb-4 ${styles.borderBottom}`}>
                    <FileUpload
                      id={'uploadFile3'}
                      filedText={'Pick a file *'}
                      fileSelect={async (file: File | null) => {
                        if (file !== null) {
                          setIsLoading(true)
                          const res = await fileService.readFileAndUpload(
                            file,
                            'Other',
                            hiringData
                          )
                          if (res.status === RequestStatus.Success) {
                            setIsLoading(false)
                            setChange(!isChange)
                          }
                        }
                      }}
                    />
                    <h6 className={`gray-color-text mt-3`}>
                      Supported file type: .pdf, .doc
                    </h6>
                  </div>

                  {paperWorks &&
                    paperWorks
                      .filter((h: any) => h.file_type === 'Other')
                      .map((work: any, index) => {
                        return (
                          <div className={`mt-5 `} key={index}>
                            <div className={styles.selectedResume}>
                              <h6 className={'ms-3 text-bold gray-color-text'}>
                                Selected File
                              </h6>

                              <div className={`d-flex ${styles.borderTop}`}>
                                <div
                                  className={
                                    'd-flex justify-content-between align-items-center w-100'
                                  }
                                >
                                  <h6 className={'text-normal gray-color-text'}>
                                    {work.file_name}{' '}
                                    <span
                                      className={'text-primary pointer'}
                                      onClick={() => viewFile(work)}
                                    >
                                      View File
                                    </span>
                                  </h6>
                                </div>
                                <div
                                  onClick={() => showDeleteConfirmBox(work.id)}
                                  className={'pointer'}
                                >
                                  <RecycleBinIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                </div>

                <div
                  className={
                    'd-md-flex justify-content-end align-items-center mt-5'
                  }
                >
                  <CustomButton
                    type={'submit'}
                    onClick={() => {}}
                    className={`mt-2 mt-md-0 ${styles.widthBtn}`}
                    text={'Hired'}
                    icon={<RightLongArrowIcon />}
                    disabled={supplyStatus || disableTabsAndButtons}
                    iconSide={'right'}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}

      <ConfirmBannerModal
        onYesClick={() => deleteWork(deleteId)}
        open={showConfirm}
        onClose={(close) => {
          setDeleteId('')
          setShowConfirm(close)
        }}
        title={'Delete Confirmation'}
        confirmMessage={'Are you sure you want to delete the uploaded file?'}
      />

      <DocViewModel
        open={isDoc}
        url={viewURL}
        onClose={(close) => {
          setViewURL('')
          setIsDoc(close)
        }}
        title={fileName}
      />
      {modal && (
        <CustomModal
          open={modal}
          onCloseModal={(val: any) => {
            setModal(val)
            navigate('/dashboard')
          }}
          title={'Completed'}
        >
          <div>
            <h3>Hiring process completed</h3>
          </div>
          <div style={{ marginTop: 10 }}>
            <CustomButton
              onClick={handleOkClick}
              className={styles.widthBtn}
              text={'OK'}
              iconSide={'right'}
            />
          </div>
        </CustomModal>
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default PaperWork
