/**
 * A service that keeps track of the loading state of the application.
 * Will keep track of multiple reasons to load, and will only set the
 * loading state to false when all reasons are false.
 */
export default class LoadingService {
  private reasonsToLoad: string[]

  /**
   * Creates a new loading service instance
   * @param setLoading a function that sets the loading state
   */
  constructor(private setLoading: (value: boolean) => void) {
    this.reasonsToLoad = []
  }

  /**
   * Sets the value of the loading state for a given reason
   * @param reason a unique reason to load, ie. getUserData
   * @param value the boolean value to set the loading state to
   * @returns void
   */
  public setReason(reason: string, value: boolean): void {
    if (value && !this.reasonsToLoad.includes(reason)) {
      this.reasonsToLoad.push(reason)
    }
    if (!value && this.reasonsToLoad.includes(reason)) {
      this.reasonsToLoad.splice(this.reasonsToLoad.indexOf(reason), 1)
    }
    this.setLoading(this.reasonsToLoad.length !== 0)
  }

  /**
   * Creates a unique reason to load
   * @returns a random, unique reason to load
   */
  private getUniqueReason(): string {
    let reason
    while (!reason || this.reasonsToLoad.includes(reason)) {
      reason = 'loadService_internal_' + Math.floor(Math.random() * 1000)
    }
    return reason
  }

  /**
   * Creates a unique reason to load that will be true until the given promise resolves
   * @param promise the promise to await
   * @param cause an optional reason to load
   * @returns the result of the given promise
   */
  public async await<Type>(
    promise: Promise<Type>,
    cause?: string
  ): Promise<Type> {
    return new Promise((resolve, reject) => {
      const reason = cause || this.getUniqueReason()
      this.setReason(reason, true)
      promise
        .then((value: Type) => {
          resolve(value)
          this.setReason(reason, false)
        })
        .catch((err) => {
          reject(err)
          this.setReason(reason, false)
        })
    })
  }

  /**
   * Checks if there are any reasons to load
   * @returns true if there are any reasons to load, false otherwise
   */
  public isLoading(): boolean {
    return this.reasonsToLoad.length !== 0
  }
}
