import { UploadedFile, UploadedFileType } from '../types/file.model'
import { RequestStatus } from '../enums/commonApplication.enum'
import { uploadPaperWork } from './paperWork.service'
import { HiringProcess } from '../interfaces/hiring.interface'
import { Auth } from 'aws-amplify'

export default class FileService {
  public async readFile(file: File): Promise<UploadedFile> {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader()
      reader.onload = function (data) {
        let contents = null
        if (data.target && data.target.readyState === 2) {
          contents = data.target.result as string
        } else {
          reject(new Error('Could not read file'))
        }
        resolve({
          name: file.name,
          contents: contents || '',
          original: file,
          type: UploadedFileType.File,
        })
      }
      reader.readAsDataURL(file)
    })
  }

  public async readFileAndUpload(
    file: File,
    fileType: string,
    hiringData: HiringProcess | null
  ): Promise<any> {
    return new Promise(async function (resolve, reject) {
      const session = await Auth.currentSession()
      const reader = new FileReader()
      reader.onload = async function (data) {
        let contents = null
        if (data.target && data.target.readyState === 2) {
          contents = data.target.result as string
        } else {
          reject(new Error('Could not read file'))
        }
        const res = await uploadPaperWork({
          data: {
            uId: session.getAccessToken().payload.username,
            hId: hiringData?.id,
            fileType: fileType,
          },
          file: {
            name: file.name,
            contents: contents || '',
            original: file,
            type: UploadedFileType.File,
          },
        })

        if (res) {
          resolve({
            name: file.name,
            contents: contents || '',
            original: file,
            type: UploadedFileType.File,
            status: RequestStatus.Success,
          })
        } else {
          reject(new Error('Could not read file'))
        }
      }
      reader.readAsDataURL(file)
    })
  }

  public openFile(file: { contents: any }) {
    if (!file) {
      return
    }
    const win = window.open()
    win?.document.write(
      '<iframe src="' +
        file.contents +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    )
  }

  public attachToForm(file: File, form?: FormData): FormData {
    const res = form || new FormData()
    res.append('file', file, file.name)
    return res
  }

  public async readBackendFile(
    data: { data: any; key: string } | null
  ): Promise<UploadedFile | null> {
    if (data === null) {
      return null
    }
    const file = new File(
      [new Int8Array(data.data.Body.data).buffer],
      data.key.split('/')[data.key.split('/').length - 1],
      { type: data.data.ContentType }
    )
    return this.readFile(file)
  }

  public async readBackendImage(
    image: { type: string; data: any } | null
  ): Promise<UploadedFile | null> {
    if (image === null) {
      return null
    }
    if (image.type === 'url') {
      return {
        type: UploadedFileType.URL,
        contents: image.data,
      }
    } else {
      return this.readBackendFile(image.data)
    }
  }
}
