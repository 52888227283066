import React, { useMemo, useState } from 'react'
import styles from '../teams/styles.module.scss'
import { useNavigate } from 'react-router-dom'

interface SettingsProps {
  hardSkillAssessmentSelected?: boolean
  cultureCatchUpSelected?: boolean
  interviewQuestionsSelected?: boolean
  costCalculationSelected?: boolean
  masterDataSelected?: boolean
}

function Settings({
  hardSkillAssessmentSelected,
  cultureCatchUpSelected,
  interviewQuestionsSelected,
  costCalculationSelected,
  masterDataSelected,
}: SettingsProps) {
  const [selected, setSelected] = useState(
    hardSkillAssessmentSelected
      ? 0
      : cultureCatchUpSelected
        ? 1
        : interviewQuestionsSelected
          ? 2
          : costCalculationSelected
            ? 3
            : masterDataSelected
              ? 4
              : -1
  )
  const navigate = useNavigate()
  const organizationsList = useMemo(
    () => [
      {
        value: '/hardSkillAssessment',
        organization: 'Technical Ability Assessment Questions',
      },
      {
        value: '/cultureCatchUpAssessment',
        organization: 'Culture Catch-Up Questions',
      },
      {
        value: '/Interview',
        organization: 'Interview Questions',
      },
      {
        value: '/costCalculation',
        organization: 'Cost Calculation Variables',
      },
      {
        value: '/masterData',
        organization: 'Master Data',
      },
    ],
    []
  )
  const handleSelect = (value: string, index: number) => {
    setSelected(index)
    navigate(value)
  }
  return (
    <div className={`mx-md-5 ${styles.organizationsFilter} col-sm-12 col-lg-3`}>
      <div className={`d-flex flex-column py-4 mb-4`}>
        <div>
          <h4 className="text-almostBlack text-bolder d-flex justify-content-center align-items-center pb-3">
            Settings
          </h4>
        </div>
      </div>
      {organizationsList.map(
        (value: { value: string; organization: string }, index: number) => {
          return (
            <div
              className={`${styles.settingsSelection} mb-1 ${
                selected === index ? styles.settingsSelected : ''
              }`}
              key={index}
              onClick={() => handleSelect(value.value, index)}
            >
              <h6
                className={`${styles.settingsTitle} ${
                  selected === index ? styles.settingsTitleSelected : ''
                }`}
              >
                {value.organization}
              </h6>
            </div>
          )
        }
      )}
    </div>
  )
}

export default Settings
