import styles from './Backdrop.module.scss'
import CustomButton from '../../shared/components/customButton/customButton'
import React, { useEffect, useMemo, useState } from 'react'
import { CandidateDetails } from '../../shared/interfaces/candidate.interface'
import {
  HiringNotesData,
  HiringProcess,
} from '../../shared/interfaces/hiring.interface'
import {
  getHiringProcessNotes,
  saveHiringProcessNotes,
} from '../../shared/services/hiring.service'
import LoadingService from '../../shared/services/loading.service'
import LoadingSpinner from '../../shared/components/customLoadingSpinner/loadingSpinner'
import SuccessBannerModal from '../../shared/components/errorBannerModal/successBannerModal'
import ErrorBannerModal from '../../shared/components/errorBannerModal/errorBannerModal'
import dayjs from 'dayjs'

interface NoteModalProps {
  candidate: CandidateDetails | null
  hiringData: HiringProcess | null
  onUpdateHiringData?: (process: HiringProcess) => void
  onCloseModal: (value: boolean) => void
}

function NoteModal({ hiringData }: NoteModalProps) {
  const [notes, setNotes] = useState<string | undefined>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [closeModalSuccess, setOnCloseModalSuccess] = useState(false)
  const [notesHistory, setNotesHistory] = useState<HiringNotesData[]>([])
  const loadingService: LoadingService = useMemo(
    () => new LoadingService(setIsLoading),
    []
  )
  useEffect(() => {
    if (hiringData && hiringData.id) {
      const data: any = []
      loadingService
        .await(getHiringProcessNotes(hiringData.id))
        .then((res) => {
          data.push(...res.map((item: any) => item.result))
          setNotesHistory(data)
          setReload(false)
          setSuccessMessage(false)
        })
        .catch((error) => {
          setErrorMessage(error.message)
          setIsError(true)
        })
    }
  }, [loadingService, hiringData, reload])

  const handleSaveNotes = () => {
    setIsLoading(true)
    try {
      if (hiringData && hiringData.id && notes) {
        let params = {
          hiringProcessId: hiringData.id,
          note: notes,
        }
        loadingService.await(saveHiringProcessNotes(params)).then((res) => {
          if (res.status === 'success') {
            setIsLoading(false)
            setSuccessMessage(true)
            setOnCloseModalSuccess(false)
            setNotes('')
            setReload(true)
          } else {
            setIsLoading(false)
            setIsError(true)
            setOnCloseModalSuccess(false)
            setReload(true)
          }
        })
      }
    } catch (error: any) {
      setErrorMessage(error.message)
      setIsError(true)
      setIsLoading(false)
    }
  }
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`mt-3`}>
        <textarea
          disabled={isLoading}
          className={styles.textNote}
          value={notes ?? ''}
          onChange={(e) => {
            setNotes(e.target.value)
          }}
        />
      </div>
      <div className={'d-flex w-100 justify-content-end'}>
        <CustomButton
          text={'Save'}
          disabled={isLoading}
          onClick={handleSaveNotes}
          className={styles.saveBtn}
        />
      </div>
      <div className={'mt-5'}>
        <div className={`d-flex mb-2 flex-column`}>
          <div className={'d-flex'}>
            <h6 className={`black-text mb-2 ${styles.w75}`}>Note</h6>
            <h6 className={`black-text mb-2 ${styles.w33}`}>Name</h6>
            <h6 className={`black-text mb-2 ${styles.w33}`}>Timestamp</h6>
          </div>
          <div className={styles.borderBottom}>
            {notesHistory.map((value, index) => (
              <div
                className={`d-flex mb-2 w-100`}
                key={`notes-history-inside-div-${index}`}
              >
                <p className={`black-text mb-2 pe-4 ${styles.w75}`}>
                  {value.note}
                </p>
                <p className={`black-text mb-2 pe-4 ${styles.w33}`}>
                  {value.userName}
                </p>
                <p className={styles.w33}>
                  {dayjs(value.createdAt).format('DD-MM-YYYY hh.mm A')}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <SuccessBannerModal
        title={'Success'}
        open={successMessage}
        onClose={() => setOnCloseModalSuccess(true)}
        Message={'Note Saved'}
      />
      <ErrorBannerModal
        open={isError}
        errorMessage={errorMessage}
        onClose={() => setOnCloseModalSuccess(true)}
      />
    </div>
  )
}

export default NoteModal
