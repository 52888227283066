import styles from './SearchIcon.module.scss'

interface Props {
  isLoading: boolean
  searchTerm: string
  handleClear: () => void
}

export const SearchIcon: React.FC<Props> = ({
  isLoading,
  searchTerm,
  handleClear,
}) => {
  if (isLoading) {
    return <div className={styles.spinner}></div>
  }

  if (searchTerm) {
    return (
      <button onClick={handleClear} className={styles.clearButton}>
        ×
      </button>
    )
  }

  return (
    <svg
      className={styles.searchIcon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
  )
}
