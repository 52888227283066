export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum RequestStatus {
  Success = 'success',
  NoData = 'no_data',
  Error = 'error',
}
