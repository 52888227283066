import { UserRole } from '../enums/auth.enum'
import {
  IAuthFormPayload,
  IAuthRegistrationPayload,
} from '../interfaces/authForm.interface'
import { axiosInstance } from './base.service'
import { Auth } from 'aws-amplify'

export const authenticateUser = (
  loginPayload: IAuthFormPayload
): Promise<any> => {
  const url: string = `/user/authentication`
  return axiosInstance.post(url, loginPayload)
}

export const authUserRegistration = (
  registrationPayload: IAuthRegistrationPayload
): Promise<any> => {
  const url: string = `/user/create-cognito-account`
  return axiosInstance.post(url, registrationPayload)
}

/**
 * Gets the user's role
 * @returns the user's role
 */
export const getUserRole = async (): Promise<UserRole> => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const userAttributes = user.attributes
    if (userAttributes['custom:role'] === 'admin') {
      return UserRole.GlobalAdmin
    } else if (userAttributes['custom:role'] === 'manager') {
      return UserRole.Manager
    } else if (userAttributes['custom:role'] === 'staff') {
      return UserRole.StaffAdmin
    } else {
      return UserRole.StaffAdmin
    }
  } catch (e) {
    return UserRole.StaffAdmin
  }
}
