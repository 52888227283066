import { BaseResponse } from '../interfaces/commonApplication.interface'
import { IOrganization } from '../interfaces/organization.interface'
import { axiosInstance } from './base.service'

/**
 * Get all organizations, or search for organizations by name
 * @param searchQuery The search query to search for organizations by name
 * @returns A list of organizations
 */
export const getAllOrganizations = async (
  searchQuery: string = ''
): Promise<IOrganization[]> => {
  try {
    const res = await axiosInstance.get<BaseResponse<IOrganization[]>>(
      searchQuery === ''
        ? '/organization/search'
        : `/organization/search?search=${searchQuery}`
    )
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

// search/all
/**
 * Get all organizations, or search for organizations by name
 * Duplicated service from getAllOrganizations because of the different endpoint and different response types
 * @param searchQuery The search query to search for organizations by name
 * @returns A list of organizations
 */
export const getOrganizations = async (
  searchQuery: string = ''
): Promise<IOrganization[]> => {
  try {
    const res = await axiosInstance.get<BaseResponse<IOrganization[]>>(
      searchQuery === ''
        ? '/organization/search/all'
        : `/organization/search/all?search=${searchQuery}`
    )
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

// search/all/filter
/**
 * Get all organizations, or search for organizations by name
 * Duplicated service from getAllOrganizations because of the different endpoint and different response types
 * @returns A list of organizations
 */
export const getOrganizationsFilter = async (params: {
  search: string
  page: number
}): Promise<IOrganization[]> => {
  try {
    const url: string = `/organization/all/filter?${new URLSearchParams({
      search: params.search ?? null,
      page: params.page.toString(), // convert number to string
    })}`

    const res = await axiosInstance.get<BaseResponse<IOrganization[]>>(url)
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getOrganizationCount = async (since?: string): Promise<number> => {
  if (!since) {
    since = new Date(0).toISOString()
  }
  const url: string = `/organization/total?start_date=${since}&end_date=${new Date().toISOString()}`
  try {
    const res = await axiosInstance.get<{ status: string; result: number }>(url)

    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

/**
 * Activate or deactivate an organization
 * Inactive organizations cannot post jobs
 * sends an email to the managing user of the organization about the status
 * @param params Contains the organization id and the active status
 * @returns The updated organization
 */
export const updateOrganizationStatus = async (params: {
  id: string
  active: boolean
}): Promise<boolean> => {
  try {
    const res = await axiosInstance.get<BaseResponse<boolean>>(
      `/organization/activate?id=${params.id}&active=${params.active}`
    )
    return res.data.result
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}
