import { axiosInstance } from './base.service'
import { BaseResponse } from '../interfaces/commonApplication.interface'
import axios from 'axios'

export const getUserData = async (cognitoId: string): Promise<any> => {
  const url: string = `/user/get_files?hiring_process_id=${cognitoId}`

  try {
    const res = await axiosInstance.get<BaseResponse<any[]>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getUserDataById = async (token: string): Promise<any> => {
  const url: string = `/user/get?${new URLSearchParams({ token })}`

  try {
    const res = await axiosInstance.get<BaseResponse<any[]>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getUserRole = async (token: string): Promise<any> => {
  const url: string = `/user/role?${new URLSearchParams({ token })}`

  try {
    const res = await axiosInstance.get<BaseResponse<any[]>>(url)

    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const getUserPreSignedUrl = async (token: string): Promise<any> => {
  const url: string = `/user/get-presigned-url?${new URLSearchParams({ token })}`

  try {
    const res = await axiosInstance.get<BaseResponse<any[]>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const changePassword = async (payload: any): Promise<any> => {
  const url: string = process.env.REACT_APP_SERVER_URL + `/user/change-password`
  try {
    const res = await axios.post<BaseResponse<any[]>>(url, {
      email: payload.email,
      confirmationCode: payload.confirmationCode,
      newPassword: payload.newPassword,
      confirmPassword: payload.confirmPassword,
    })

    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const checkEmailExists = async (email: string): Promise<any> => {
  const url: string =
    process.env.REACT_APP_SERVER_URL +
    `/user/check-email?${new URLSearchParams({ email })}`
  try {
    const res = await axios.get<BaseResponse<any[]>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const changePasswordRequest = async (email: string): Promise<any> => {
  const url: string =
    process.env.REACT_APP_SERVER_URL + `/user/change-password-request`
  try {
    const res = await axios.post<BaseResponse<any[]>>(url, { email: email })
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const validateEmailAddress = async (email: string): Promise<any> => {
  const url: string =
    process.env.REACT_APP_SERVER_URL +
    `/user/validate?${new URLSearchParams({ email })}`
  try {
    const res = await axios.get<BaseResponse<any[]>>(url)

    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const deleteCandidate = async (id: string): Promise<any> => {
  const url: string =
    process.env.REACT_APP_SERVER_URL +
    `/user/admin/delete?${new URLSearchParams({ id })}`
  try {
    const res = await axiosInstance.post<BaseResponse<any[]>>(url)

    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}

export const searchUsers = async (searchString: string): Promise<any> => {
  const url = `/user/search?searchString=${searchString}`

  try {
    const res = await axiosInstance.get<BaseResponse<any[]>>(url)
    return res.data
  } catch (err: any) {
    throw new Error(err.response.data.message)
  }
}
